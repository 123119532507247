import set from 'lodash/set';
import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/immer';

import { ColumnValue, ColumnState } from './types';

export const setColumnsOpenReducer = <T extends string>(
  state: WritableDraft<ColumnState<T>>,
  action: PayloadAction<boolean>
) => {
  state.popover.open = action.payload;
};

export const setSearchInputReducer = <T extends string>(
  state: WritableDraft<ColumnState<T>>,
  action: PayloadAction<string>
) => {
  state.popover.searchInput = action.payload;
};

export const setColumnsChangedReducer = <T extends string>(
  state: WritableDraft<ColumnState<T>>,
  action: PayloadAction<boolean>
) => {
  state.popover.changed = action.payload;
};

export const setColumnsReducer = <T extends string>(
  state: WritableDraft<ColumnState<T>>,
  action: PayloadAction<Omit<ColumnValue<T>, 'key' | 'displayName'>>
) => {
  const column = action.payload;
  state.columns.forEach((c) => {
    if (c.id === column.id) {
      c.active = column.active;
    }
  });
};

export const setInitializedReducer = <T extends string>(
  state: WritableDraft<ColumnState<T>>,
  action: PayloadAction<boolean>
) => {
  const initialized = action.payload;
  state.initialized = initialized;
};

export const setAllColumnsReducer = <T extends string>(
  state: WritableDraft<ColumnState<T>>,
  action: PayloadAction<ColumnValue<T>[]>
) => {
  const columns = action.payload;
  columns.forEach((column, index: number) => {
    state.columns[index] = column as WritableDraft<ColumnValue<T>>;
  });
};

export const swapColumnsReducer = <T extends string>(
  state: WritableDraft<ColumnState<T>>,
  action: PayloadAction<{ sourceIndex: number; destinationIndex: number }>
) => {
  const { sourceIndex, destinationIndex } = action.payload;
  const temp = state.columns[sourceIndex];
  state.columns.splice(sourceIndex, 1);
  state.columns.splice(destinationIndex, 0, temp);
};

export const setAllColumnsActiveReducer = <T extends string>(
  state: WritableDraft<ColumnState<T>>,
  action: PayloadAction<{ excludedColumns: ColumnValue<T>[]; active: boolean }>
) => {
  const { excludedColumns, active } = action.payload;
  state.columns
    .filter(
      (c) =>
        !excludedColumns
          .map((ec) => (ec as WritableDraft<ColumnValue<T>>).key)
          .includes(c.key)
    )
    .forEach((c) => {
      c.active = active;
    });
};

export const resetStateReducer = <T extends string>(
  state: WritableDraft<ColumnState<T>>,
  action: PayloadAction<{
    initialState: () => ColumnState<T>;
    overrides: Record<string, any>;
  }>
) => {
  const { initialState, overrides } = action.payload;
  const s = initialState();

  Object.entries(overrides).forEach(([key, value]) => {
    set(s, key, value);
  });
  return s;
};
