import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Stack, SxProps } from '@mui/material';
import { DetailViewContentContainerSx } from 'pages/OrderManagement/components/DetailedOrderView/styles';
import { executeNowThenWait } from 'services/misc';
import { OrderDetailsProps } from './types';
import OrderDetailsNotes from './components/OrderDetailsNotes';
import ExternalLinks from './components/ExternalLinks';
import { OrderDetailsSx } from './styles';
import OrderItemCard from './components/OrderItemCard';
import DeleteOrderItemModal from './components/DeleteOrderItemModal';
import { INSURANCE_OPTIONS } from 'pages/OrderManagement/components/DetailedOrderView/components/OrderDetails/components/OrderItemCard/constants';
import { Order } from 'types';
import { showProcurementPreferencesSection } from 'shared/utils';
import ProcurementPreferenceAlert from './components/ProcurementPreferenceAlert';
import {
  PROCUREMENT_PREFERENCE_COLOR_SWAP_APPROVED_TEXT,
  PROCUREMENT_PREFERENCE_COLOR_SWAP_NOT_APPROVED_TEXT,
  PROCUREMENT_PREFERENCE_FLEXIBLE_LEAD_TIME_APPROVED_TEXT,
  PROCUREMENT_PREFERENCE_FLEXIBLE_LEAD_TIME_NOT_APPROVED_TEXT,
} from './constants';
import { ORDER_NOTES_WIDTH } from './components/OrderDetailsNotes/constants';

const OrderDetails = (props: OrderDetailsProps) => {
  if (!props.order) return null;

  const {
    orderItemData: { orderItemObjects, loading },
    fetchOrderItems,
  } = props;

  const { deviceColorSwapApproved = null, flexibleLeadTimeApproved = null } =
    props.order;

  const [orderItemToRemove, setOrderItemToRemove] = React.useState<
    Record<string, any> | undefined
  >();

  React.useEffect(() => {
    if (props.order?.id) fetchOrderItems(props.order.id);
  }, []);

  const OrderDetailsContainerSx = {
    ...DetailViewContentContainerSx,
    ...OrderDetailsSx,
  } as SxProps;

  const orderDetailsNotesProps = {
    orderId: props.order.id,
    ...(props.order.internalNotes && {
      internalNotes: props.order.internalNotes,
    }),
  };

  const externalLinksProps = {
    orderId: props.order.id,
    ...(props.order.externalLinks && {
      externalLinks: props.order.externalLinks,
    }),
  };

  const clearOrderItemRemovalQueue = () => {
    setOrderItemToRemove(undefined);
  };

  const queueOrderItemRemovalHoF = (orderItem: any) => () => {
    const orderItemId = orderItem?.id ?? '';
    const orderItemNumber = orderItem?.orderItemNumber ?? '';
    executeNowThenWait(
      clearOrderItemRemovalQueue,
      () => {
        setOrderItemToRemove(() => ({
          ...orderItem,
          orderItemId,
          orderItemNumber,
        }));
      },
      100
    );
  };

  const noAvailableOrderItems =
    (orderItemObjects?.length ?? 0) === 0 && !loading;

  const getDeleteOrderItemModalProps = () => ({
    ...(orderItemToRemove && {
      orderItemId: orderItemToRemove.orderItemId,
      orderItemNumber: orderItemToRemove.orderItemNumber,
      insuranceRequested:
        orderItemToRemove.insuranceRequested ||
        INSURANCE_OPTIONS[0].value.insuranceRequested,
    }),
    closeModal: clearOrderItemRemovalQueue,
  });

  return (
    <>
      <Container sx={OrderDetailsContainerSx}>
        <div style={{ maxHeight: '100%', overflowY: 'scroll', width: '820px' }}>
          {noAvailableOrderItems ? (
            <Typography>No Order Items available</Typography>
          ) : (
            orderItemObjects?.map((orderItem: any) => {
              return (
                <OrderItemCard
                  key={orderItem.orderItemId}
                  order={props.order as Order}
                  orderItem={orderItem}
                  queueOrderItemRemoval={queueOrderItemRemovalHoF(orderItem)}
                />
              );
            })
          )}
        </div>
        <Stack
          direction="column"
          gap="12px"
          sx={{ width: ORDER_NOTES_WIDTH, height: '100%' }}
        >
          {showProcurementPreferencesSection(
            deviceColorSwapApproved,
            flexibleLeadTimeApproved
          ) && (
            <>
              <ProcurementPreferenceAlert
                approved={deviceColorSwapApproved ?? false}
                approvedText={PROCUREMENT_PREFERENCE_COLOR_SWAP_APPROVED_TEXT}
                notApprovedText={
                  PROCUREMENT_PREFERENCE_COLOR_SWAP_NOT_APPROVED_TEXT
                }
              />
              <ProcurementPreferenceAlert
                approved={flexibleLeadTimeApproved ?? false}
                approvedText={
                  PROCUREMENT_PREFERENCE_FLEXIBLE_LEAD_TIME_APPROVED_TEXT
                }
                notApprovedText={
                  PROCUREMENT_PREFERENCE_FLEXIBLE_LEAD_TIME_NOT_APPROVED_TEXT
                }
              />
            </>
          )}
          <OrderDetailsNotes {...orderDetailsNotesProps} />
          <ExternalLinks {...externalLinksProps} />
        </Stack>
      </Container>
      <DeleteOrderItemModal {...getDeleteOrderItemModalProps()} />
    </>
  );
};

export default OrderDetails;
