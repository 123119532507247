import { RootState } from 'store';
import { OrderView } from 'pages/OrderManagement/enum';

export const selectOMTabView = (state: RootState): OrderView =>
  state.orderManagement.tab.view;
export const selectOMTabDisableStatusFilter = (state: RootState): boolean =>
  state.orderManagement.tab.disableStatusFilter;
export const selectOMTabDisableOrderTypeFilter = (state: RootState): boolean =>
  state.orderManagement.tab.disableOrderTypeFilter;
export const selectOMTabSort = (state: RootState): Record<string, any> =>
  state.orderManagement.tab.sort;
