export const RETAIL_FALLBACK_PRICE = 'N/A';

export const ZERO_ESTIMATED_TAX = 0.0;

export const RETRIEVAL = 'retrieval';

export const EARLIEST_DELIVERY_DATE = 'The earliest delivery date:';

export const PROCUREMENT_RECIPIENTS_SECTION_INFORMATION =
  'If you send a request, delivery will take longer, to get it faster, select an address from saved addresses.';

export const ORDER_ITEMS_LABEL = 'Order Items';

export const RECIPIENTS_LABEL = 'Recipient(s)';

export const SHIPPING_LABEL = 'Shipping & Processing';

export const PREFERENCES_HEADER = 'Preferences';

export const PREFERENCES_COLOR_SWAP_PROMPT =
  'I approve a color change for an equal specification and price if selected color is unavailable.';

export const PREFERENCES_LEAD_TIME_PROMPT =
  'I approve a lead time of up to 6 weeks for custom or backordered items.';

export const PREFERENCES_LEAD_TIME_FALSE_SELECTION_LABEL =
  'No, Please reach out to me with alternatives';
