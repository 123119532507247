import React from 'react';
import Alert from '@mui/material/Alert';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';

import IntegrationConfigurationLayout from 'pages/integrations/IntegrationConfigurationLayout';
import { AlertContainerSx } from 'pages/integrations/Integrations/styles';

import MergeCredentialsForm from '../forms/MergeCredentialsForm';
import { MergeCredentialsFormFields } from '../forms/MergeCredentialsForm/types';
import ConfirmationModal from 'components/modals/ConfirmationModal';

import { useConfigureMergeSettingsPage } from './hooks';
import { ContainerSx } from './styles';

const MergeSettings = () => {
  const form = useForm<MergeCredentialsFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    onExit,
    onValidationError,
    onSuccess,
    showAlertBanner,
    deleteIntegrationModalOpen,
    linkToken,
    handleDeleteConnection,
    handleOnSecondaryButtonClick,
    handleCloseDeleteIntegrationModal,
    connection,
    organization,
    user,
  } = useConfigureMergeSettingsPage(form);

  const config = React.useMemo(
    () => ({
      onSuccess,
      onExit,
      onValidationError,
      linkToken,
    }),
    [linkToken]
  );

  const { open, isReady } = useMergeLink(config);

  return (
    <Stack sx={ContainerSx}>
      <IntegrationConfigurationLayout
        title="HR, Payroll and SCIM Directory Settings"
        type="settings"
        handleOnPrimaryButtonClick={open}
        primaryButtonCopy="Configure"
        primaryButtonDisabled={
          !connection.endUserEmailAddress ||
          !organization ||
          !user ||
          showAlertBanner ||
          !isReady
        }
        secondaryButtonCopy="Delete integration"
        handleOnSecondaryButtonClick={handleOnSecondaryButtonClick}
        secondaryButtonDisabled={!connection.endUserEmailAddress}
      >
        {showAlertBanner ? (
          <Stack sx={AlertContainerSx}>
            <Alert severity="error">
              You do not have permission to configure integrations
            </Alert>
          </Stack>
        ) : null}
        <MergeCredentialsForm form={form} type="settings" />
        <ConfirmationModal
          open={deleteIntegrationModalOpen}
          handleClose={handleCloseDeleteIntegrationModal}
          handleSubmit={handleDeleteConnection}
          title="Delete integration"
          subtitle="Are you sure you want to delete this integration?"
          submitButtonCopy="Delete"
          submitButtonDisabled={
            connection.deleteConnectionLoading === 'pending'
          }
          submitButtonLoading={connection.deleteConnectionLoading === 'pending'}
          cancelButtonCopy="Cancel"
          showCloseButtonTopRight={false}
        />
      </IntegrationConfigurationLayout>
    </Stack>
  );
};

export default MergeSettings;
