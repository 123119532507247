export const ORDER_ITEM_QUERY_LIMIT = 100;
export const ORDER_NOTES_WIDTH = '410px';
export const SEARCH_ORDER_ITEMS_ORDER_ASC_DEFAULT = 'createdAt';
export const PROCUREMENT_PREFERENCE_COLOR_SWAP_APPROVED_TEXT =
  'Color swap approved';
export const PROCUREMENT_PREFERENCE_COLOR_SWAP_NOT_APPROVED_TEXT =
  "Color swap isn't approved";
export const PROCUREMENT_PREFERENCE_FLEXIBLE_LEAD_TIME_APPROVED_TEXT =
  'Lead time of up to 6 weeks approved';
export const PROCUREMENT_PREFERENCE_FLEXIBLE_LEAD_TIME_NOT_APPROVED_TEXT =
  'Lead time of up to 6 weeks is not approved';
