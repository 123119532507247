import { SxProps } from '@mui/material';
import theme from 'themes/theme';
// colors.gray60

export const OrderOptionsGridSx: SxProps = {
  height: '40px',
  pb: '40px',
};

export const OrderOptionButtonSx: SxProps = {
  border: '1px solid',
  borderRadius: '8px',
  pb: 0,
  pt: 0,
  mr: '16px',
  width: '131px',
};

export const CopyButtonSx = {
  ...OrderOptionButtonSx,
  '& svg': {
    width: '16px',
    height: '24px',
  },
};

export const DetailOrderShippingSelectSx: SxProps = {
  fontFamily: 'Inter',
  fontSize: '16px',
  minWidth: '140px',
  ml: '16px',
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
    border: 'none',
    borderBottom: `1px dotted ${theme.palette.colors.gray60}`,
    borderRadius: 0,
    paddingLeft: '2px',
    py: '4px',
  },
  '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
    borderBottom: `1px dotted ${theme.palette.colors.gray60}`,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};
