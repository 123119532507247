export const ContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
};

export const IntegrationContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  mt: '50px',
};

export const IntegrationItemContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export const InformationContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  fontSize: '20px',
  fontWeight: 'bold',
  mb: '20px',
};

export const AlertContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  width: '75%',
  mb: '20px',
};
