import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearOMSelfAssignSwitch,
  resetOMComboSearch,
} from 'store/slices/order_management/search';
import {
  OMDashFilterKeys,
  V2_FIND_AND_COUNT_ORDERS_OUTPUT,
} from 'pages/OrderManagement/filters/shared/constants';
import { FILTER_STATE_KEYS } from 'store/constants';
import {
  selectAllChosenEntries,
  selectChosenEntries,
  selectIsFilterDisabled,
} from 'store/shared/selectors';
import { orderManagementFilterActions } from 'store/slices/order_management/filter';
import { GridSortModel, GridApi } from '@mui/x-data-grid-pro';
import { OM_SORT_MODELS } from 'pages/OrderManagement/constants';
import { getV2FindAndCountOrders } from 'api/apollo/queries/v2';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import { Order } from 'types';
import {
  toggleOMDetailsModal,
  setOMSelectedOrder,
} from 'store/slices/order_management/details';
import { selectOMTabSort } from 'store/slices/order_management/tabs/selectors';
import { selectColumns } from 'store/slices/order_management/columns/selectors';
import { COLUMNS_DEFAULT_VISIBILITY } from './constants';
import {
  setColumnsChanged,
  swapColumns,
} from 'store/slices/order_management/columns';

export const getSortModel = (
  key: string,
  direction: 'asc' | 'desc' = 'desc',
  nulls: 'first' | 'last' = 'last'
) => {
  const sort = OM_SORT_MODELS[key];

  if (!sort) {
    return null;
  }

  const { fields, nullable } = sort;

  const count = fields.length - 1;

  const model: Record<string, any> = {};

  // eslint-disable-next-line sonarjs/no-ignored-return
  fields.reduce((acc, key, index) => {
    if (index === count) {
      if (nullable) {
        acc[key] = {
          sort: direction,
          nulls,
        };
      } else {
        acc[key] = direction;
      }
    } else {
      acc[key] = {};
    }
    return acc[key];
  }, model);

  return model;
};

export const useClearSelfAssignSwitch = () => {
  const dispatch = useDispatch();
  const selectedFilterEntries =
    useSelector(selectAllChosenEntries(FILTER_STATE_KEYS.ORDER_MANAGEMENT)) ||
    [];

  const hasOMSelection = selectedFilterEntries.some(
    (entry) => entry.key === OMDashFilterKeys.ORDER_MANAGER
  );

  return React.useEffect(() => {
    if (!hasOMSelection) return;
    dispatch(clearOMSelfAssignSwitch());
  }, [hasOMSelection]);
};

export const useClearOMOrderStatusEffect = (clearOrDisableStatus: boolean) => {
  const dispatch = useDispatch();

  const selectedOrderStatuses = useSelector(
    selectChosenEntries(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.STATUS
    )
  );

  return React.useEffect(() => {
    if (selectedOrderStatuses.length && clearOrDisableStatus) {
      dispatch(
        orderManagementFilterActions.clearFilterEntriesByKeyReducer({
          key: OMDashFilterKeys.STATUS,
        })
      );
    }
  }, [clearOrDisableStatus, selectedOrderStatuses.length]);
};

export const useClearOMOrderTypeEffect = (clearOrDisableOrderType: boolean) => {
  const dispatch = useDispatch();

  const selectedOrderTypes = useSelector(
    selectChosenEntries(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.ORDER_TYPE
    )
  );

  return React.useEffect(() => {
    if (selectedOrderTypes.length && clearOrDisableOrderType) {
      dispatch(
        orderManagementFilterActions.clearFilterEntriesByKeyReducer({
          key: OMDashFilterKeys.ORDER_TYPE,
        })
      );
    }
  }, [clearOrDisableOrderType, selectedOrderTypes.length]);
};

export const useDisableOrderStatus = (tabDisablesStatus: boolean) => {
  const dispatch = useDispatch();

  const orderStatusAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.STATUS
    )
  );

  return React.useEffect(() => {
    if (tabDisablesStatus && !orderStatusAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.disableFilterReducer(
          OMDashFilterKeys.STATUS
        )
      );
    }
  }, [tabDisablesStatus, orderStatusAlreadyDisabled]);
};

export const useEnableOrderStatus = (tabDisablesStatus: boolean) => {
  const dispatch = useDispatch();

  const orderStatusAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.STATUS
    )
  );

  return React.useEffect(() => {
    if (!tabDisablesStatus && orderStatusAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.enableFilterReducer(
          OMDashFilterKeys.STATUS
        )
      );
    }
  }, [tabDisablesStatus, orderStatusAlreadyDisabled]);
};

export const useEnableOrderTypeFilter = (
  tabShouldDisableOrderType: boolean
) => {
  const dispatch = useDispatch();

  const orderTypeAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.ORDER_TYPE
    )
  );

  return React.useEffect(() => {
    if (!tabShouldDisableOrderType && orderTypeAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.enableFilterReducer(
          OMDashFilterKeys.ORDER_TYPE
        )
      );
    }
  }, [tabShouldDisableOrderType, orderTypeAlreadyDisabled]);
};

export const useDisableOrderTypeFilter = (
  tabShouldDisableOrderType: boolean
) => {
  const dispatch = useDispatch();

  const orderTypeAlreadyDisabled = useSelector(
    selectIsFilterDisabled(
      FILTER_STATE_KEYS.ORDER_MANAGEMENT,
      OMDashFilterKeys.ORDER_TYPE
    )
  );

  return React.useEffect(() => {
    if (tabShouldDisableOrderType && !orderTypeAlreadyDisabled) {
      dispatch(
        orderManagementFilterActions.disableFilterReducer(
          OMDashFilterKeys.ORDER_TYPE
        )
      );
    }
  }, [tabShouldDisableOrderType, orderTypeAlreadyDisabled]);
};

export const useResetComboSearch = () => {
  const dispatch = useDispatch();

  const selectedFilterEntries =
    useSelector(selectAllChosenEntries(FILTER_STATE_KEYS.ORDER_MANAGEMENT)) ||
    [];

  return React.useEffect(() => {
    if (!selectedFilterEntries.length) return;
    dispatch(resetOMComboSearch());
  }, [selectedFilterEntries.length]);
};

export const useGetOMOrderTableSort = () => {
  const defaultSort = useSelector(selectOMTabSort);
  const [userSelectedSortModel, setUserSelectedSortModel] =
    React.useState<Record<string, any> | null>(null);

  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel): void => {
      if (!sortModel.length) {
        setUserSelectedSortModel(null);
      } else {
        const { field, sort: sortDirection } = sortModel[0];

        const userSelectedSortModel = getSortModel(
          field,
          sortDirection === 'asc' ? 'asc' : 'desc'
        );

        setUserSelectedSortModel(userSelectedSortModel);
      }
    },
    [defaultSort]
  );

  const orderSortModel = userSelectedSortModel
    ? [userSelectedSortModel, defaultSort]
    : defaultSort;

  return { orderSortModel, handleSortModelChange };
};

export const useGetTimelySelectedOrderInfo = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [getSelectedOrdersLazyQuery] = useSafeLazyQuery(
    getV2FindAndCountOrders(V2_FIND_AND_COUNT_ORDERS_OUTPUT)
  );

  const getSelectedOrders = React.useCallback(
    (selectOrderId: string) => {
      getSelectedOrdersLazyQuery({
        variables: {
          where: {
            AND: [{ createdAt: { gt: 1 } }, { id: { in: [selectOrderId] } }],
          },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: (data) => {
          const getPathOrders =
            data?.v2FindAndCountOrders?.orders ?? ([] as Order[]);

          if (getPathOrders.length) {
            dispatch(setOMSelectedOrder(getPathOrders[0]));
            dispatch(toggleOMDetailsModal(true));
          }
        },
      });
    },
    [dispatch, getSelectedOrdersLazyQuery]
  );

  return { getSelectedOrders };
};

export const useColumnVisibilityModel = () => {
  const columns = useSelector(selectColumns);

  return React.useCallback(() => {
    return columns.reduce((accumulator, column) => {
      accumulator[column.key] = column.active;
      return accumulator;
    }, COLUMNS_DEFAULT_VISIBILITY);
  }, [columns]);
};

export const useHandleColumnHeaderDragAndDrop = (
  apiRef: React.MutableRefObject<GridApi>
) => {
  const dispatch = useDispatch();
  const [initial, setInitial] = React.useState('');
  const [destination, setDestination] = React.useState('');
  const columns = useSelector(selectColumns);
  React.useEffect(() => {
    const removeColumnHeaderDragStart = apiRef.current.subscribeEvent(
      'columnHeaderDragStart',
      (params, dragEvent, y) => {
        setInitial(params.colDef?.field || '');
      }
    );
    return () => {
      removeColumnHeaderDragStart();
    };
  }, [apiRef]);

  React.useEffect(() => {
    const removeColumnHeaderDragOver = apiRef.current.subscribeEvent(
      'columnHeaderDragOver',
      (params, dragEvent, y) => {
        if (initial !== params?.field) {
          setDestination(params.colDef?.field || '');
        }
      }
    );
    return () => {
      removeColumnHeaderDragOver();
    };
  }, [apiRef, initial]);

  return React.useEffect(() => {
    const removeColumnHeaderDragEnd = apiRef.current.subscribeEvent(
      'columnHeaderDragEnd',
      (params, dragEvent, y) => {
        const source = params?.field;
        const destinationIndex = columns.findIndex((column) => {
          if (column.key === destination) return column;
          return null;
        });
        const sourceIndex = columns.findIndex((column) => {
          if (column.key === source) return column;
          return null;
        });
        if (sourceIndex >= 0 && destinationIndex >= 0) {
          dispatch(swapColumns({ sourceIndex, destinationIndex }));
          dispatch(setColumnsChanged(true));
        }
      }
    );
    return () => {
      removeColumnHeaderDragEnd();
    };
  }, [apiRef, columns, destination, initial]);
};
