import { ProcurementPreferenceAlertProps } from './types';
import { Alert } from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';

const ProcurementPreferenceAlert = (props: ProcurementPreferenceAlertProps) => {
  const { approved, approvedText, notApprovedText } = props;

  const text = approved ? approvedText : notApprovedText;

  return (
    <Alert
      icon={approved ? <CheckCircleOutline /> : <ErrorOutline />}
      severity={approved ? 'success' : 'warning'}
    >
      {text}
    </Alert>
  );
};

export default ProcurementPreferenceAlert;
