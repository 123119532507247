import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, Chip, TextField, Typography, Box } from '@mui/material';
import { helperText } from '../../utils';

interface AutoCompleteInputProps {
  name: string;
  label: string;
  control: any;
  disabled?: boolean;
  maxItems?: number;
}

const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
  name,
  label,
  control,
  disabled = false,
  maxItems = 3,
}) => {
  const [isFocused, setIsFocused] = useState<string>('');
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            disabled={disabled}
            value={field.value || []}
            onChange={(_, newValue) =>
              field.onChange(newValue.slice(0, maxItems))
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip label={option} {...getTagProps({ index })} key={index} />
              ))
            }
            renderInput={(params) => (
              <div
                className={`process-many ${
                  field.value?.length > 0 ? 'has-some' : ''
                }`}
              >
                <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  {...helperText(name, field.value, isFocused, setIsFocused)}
                />
                {field.value?.length > 0 && (
                  <Typography variant="body1" className="process-count">
                    {field.value.length}/{maxItems}
                  </Typography>
                )}
              </div>
            )}
          />
        </Box>
      )}
    />
  );
};

export default AutoCompleteInput;
