import { gql } from '@apollo/client';

export const UPDATE_ORDER_EXTERNAL_LINKS = gql`
  mutation updateOrder($id: ID!, $externalLinks: [String!]) {
    updateOrder(orderUpdateInput: { id: $id, externalLinks: $externalLinks }) {
      id
      externalLinks
    }
  }
`;
