import { OrderManagementColumnKey } from './types';
import { ColumnState } from 'store/slices/shared/columns/types';

export const OM_COLUMNS_KEYS: Record<string, OrderManagementColumnKey> = {
  ORDER_NUMBER: 'orderNumber',
  ORDER_TYPE: 'orderType',
  DUE_DATE: 'dueDate',
  STATUS: 'status',
  DEPOT: 'depot',
  SHIPPING_TYPE: 'shippingType',
  PURCHASER: 'purchaser',
  RECIPIENT_NAME: 'recipientName',
  RECIPIENT_EMAIL: 'recipientEmail',
  ACCOUNT: 'account',
  OTHER: 'OTHER',
  ORDER_MANAGEMENT: 'ORDER_MANAGEMENT',
  PROCUREMENT: 'PROCUREMENT',
  ASSET_MANAGEMENT: 'ASSET_MANAGEMENT',
};

export const INITIAL_STATE: () => ColumnState<OrderManagementColumnKey> =
  () => {
    const columns = [
      {
        id: '',
        displayName: 'Order Manager',
        key: OM_COLUMNS_KEYS.ORDER_MANAGEMENT,
        active: true,
      },
      {
        id: '',
        displayName: 'Procurement Manager',
        key: OM_COLUMNS_KEYS.PROCUREMENT,
        active: true,
      },
      {
        id: '',
        displayName: 'Asset Manager',
        key: OM_COLUMNS_KEYS.ASSET_MANAGEMENT,
        active: true,
      },
      {
        id: '',
        displayName: 'Other Manager',
        key: OM_COLUMNS_KEYS.OTHER,
        active: false,
      },
      {
        id: '',
        displayName: 'Order #',
        key: OM_COLUMNS_KEYS.ORDER_NUMBER,
        active: true,
      },
      {
        id: '',
        displayName: 'Type',
        key: OM_COLUMNS_KEYS.ORDER_TYPE,
        active: true,
      },
      {
        id: '',
        displayName: 'Status',
        key: OM_COLUMNS_KEYS.STATUS,
        active: true,
      },
      {
        id: '',
        displayName: 'Shipping',
        key: OM_COLUMNS_KEYS.SHIPPING_TYPE,
        active: true,
      },
      {
        id: '',
        displayName: 'Due Date',
        key: OM_COLUMNS_KEYS.DUE_DATE,
        active: true,
      },
      {
        id: '',
        displayName: 'Recipient',
        key: OM_COLUMNS_KEYS.RECIPIENT_NAME,
        active: true,
      },
      {
        id: '',
        displayName: 'Recipient Email',
        key: OM_COLUMNS_KEYS.RECIPIENT_EMAIL,
        active: false,
      },
      {
        id: '',
        displayName: 'Account',
        key: OM_COLUMNS_KEYS.ACCOUNT,
        active: true,
      },
      {
        id: '',
        displayName: 'Purchaser',
        key: OM_COLUMNS_KEYS.PURCHASER,
        active: true,
      },
      {
        id: '',
        displayName: 'Depot',
        key: OM_COLUMNS_KEYS.DEPOT,
        active: true,
      },
    ];

    columns.forEach((column, index) => {
      column.id = (index + 1).toString();
    });

    return {
      popover: {
        open: false,
        changed: false,
        searchInput: '',
      },
      initialized: false,
      columns,
    };
  };
