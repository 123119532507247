import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/immer';

import { JamfSettingsState } from './types';

export const setViewReducer = (
  state: WritableDraft<JamfSettingsState>,
  action: PayloadAction<'read' | 'write'>
) => {
  state.credentials.view = action.payload;
};
