import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';

import {
  postMergeCreateLinkToken,
  postMergeSwapAccountToken,
} from 'store/slices/integrations/merge/connect/actions';
import { resetState } from 'store/slices/integrations/merge/connect';
import {
  selectGlobalGodModeOrganization,
  selectGlobalGodModeUser,
} from 'store/slices/global/selectors';
import { MergeCredentialsFormFields } from '../forms/MergeCredentialsForm/types';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import {
  selectMergeConnectionPostSwapAccountTokenLoading,
  selectMergeLinkToken,
} from 'store/slices/integrations/merge/connect/selectors';
import { useCanUserConfigureIntegrations } from 'pages/integrations/Integrations/hooks';
import { useNavigate } from 'react-router-dom';
import { notificationSlice } from 'store/slices/notificationSlice';
import { getGodModeOrganization } from 'store/slices/global/actions';
import { ACCOUNT_LINKED_SUCCESS_MESSAGE } from './constants';

export const useGetCreateLinkToken = (
  form: UseFormReturn<MergeCredentialsFormFields>
) => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useSelector(selectGlobalGodModeOrganization);
  const user = useSelector(selectGlobalGodModeUser);

  return React.useCallback(() => {
    const email = form.watch('endUserEmailAddress');
    if (organization?.id && user?.id && email) {
      dispatch(
        postMergeCreateLinkToken({
          orgId: organization.id,
          data: {
            email,
          },
        })
      );
    }
  }, [organization?.id, user?.id, form]);
};

export const useResetMergeConnect = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useSelector(selectGlobalGodModeOrganization);
  const user = useSelector(selectGlobalGodModeUser);
  React.useEffect(() => {
    if (!organization?.id || !user?.id) {
      dispatch(resetState());
    }
  }, [organization?.id, user?.id]);
};

export const useConfigureMergeSettingsPage = (
  form: UseFormReturn<MergeCredentialsFormFields>
) => {
  const dispatch = useDispatch<AppDispatch>();
  useResetMergeConnect();
  const postSwapAccountTokenLoading = useSelector(
    selectMergeConnectionPostSwapAccountTokenLoading
  );

  const navigate = useNavigate();
  const isFormValid = form.formState.isValid;
  const organization = useGetUserOrganization();
  const user = useGetGlobalPurchaser();
  const userCanConfigureIntegrations = useCanUserConfigureIntegrations();
  const showAlertBanner = !userCanConfigureIntegrations;
  const linkToken = useSelector(selectMergeLinkToken);

  const onSuccess = React.useCallback(
    async (publicToken: string) => {
      if (organization?.id) {
        await dispatch(
          postMergeSwapAccountToken({
            orgId: organization?.id,
            data: {
              publicToken,
            },
          })
        );
      }
    },
    [organization?.id, user?.id]
  );

  const onExit = React.useCallback(async () => {
    const email = form.watch('endUserEmailAddress');
    if (!organization?.id || !email) {
      return;
    }
    dispatch(
      postMergeCreateLinkToken({
        orgId: organization?.id,
        data: {
          email,
        },
      })
    );

    if (postSwapAccountTokenLoading === 'fulfilled') {
      await dispatch(
        getGodModeOrganization({ organizationId: organization?.id })
      );
      await navigate('/integrations');
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: ACCOUNT_LINKED_SUCCESS_MESSAGE,
        })
      );
    }
  }, [organization?.id, user?.id, postSwapAccountTokenLoading]);

  const onValidationError = React.useCallback(() => {
    const email = form.watch('endUserEmailAddress');
    if (!organization?.id || !email) {
      return;
    }
    dispatch(
      postMergeCreateLinkToken({
        orgId: organization?.id,
        data: {
          email,
        },
      })
    );
  }, [organization?.id, user?.id]);
  return {
    onSuccess,
    onExit,
    onValidationError,
    showAlertBanner,
    linkToken,
    organization,
    user,
    postSwapAccountTokenLoading,
    isFormValid,
  };
};

export const useHandleOnConnectButtonClick = (
  form: UseFormReturn<MergeCredentialsFormFields>,
  isReady: boolean,
  open: () => void
) => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();
  const user = useGetGlobalPurchaser();
  return React.useCallback(
    form.handleSubmit(async (data) => {
      const email = data.endUserEmailAddress;
      if (organization?.id && user?.id && email) {
        const email = form.watch('endUserEmailAddress');
        dispatch(
          postMergeCreateLinkToken({
            orgId: organization.id,
            data: {
              email,
            },
          })
        );
        if (isReady) {
          open();
        }
      }
    }),
    [form, dispatch, organization, user, open, isReady]
  );
};
