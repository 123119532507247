/* eslint-disable no-underscore-dangle */
/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import * as AssetDetailStyles from 'pages/AssetDetails/styles';
import { ASSET_DETAILS_SUBHEADER } from 'pages/AssetDetails/constants';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AssetDetailsProps } from './types';

const AssetDetails = (props: AssetDetailsProps) => {
  const { device } = props;
  const styles = AssetDetailStyles;

  return (
    <>
      <Stack sx={styles.AssetInfoStackSx}>
        <Grid container sx={styles.AssetInfoGridSx}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              {ASSET_DETAILS_SUBHEADER.SERIAL_NUMBER}
            </Typography>
            <Typography variant="body1">{device?.serialNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              {ASSET_DETAILS_SUBHEADER.MAKE}
            </Typography>
            <Typography variant="body1">{device?.make}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="overline" color="colors.gray60">
              {ASSET_DETAILS_SUBHEADER.MODEL}
            </Typography>
            <Typography variant="body1">{device?.model}</Typography>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default AssetDetails;
