import { SxProps } from '@mui/material';

export const ExternalLinksListSx: SxProps = {
  marginBottom: '16px',
  maxHeight: '200px',
  overflowY: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  padding: 0,
};

export const ExternalLinkItemSx: (finalItem: boolean) => SxProps = (
  finalItem = false
) => ({
  marginBottom: '8px',
  marginRight: '8px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#f5f5f5',
  borderRadius: '10px',
  padding: '4px 6px 4px 12px',
  maxWidth: 'fit-content',
  ...(finalItem && {
    paddingLeft: '6px',
  }),
});

export const ExternalLinkItemIconButtonSx: SxProps = {
  padding: '4px',
};

export const ExternalLinkTextSx: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '300px',
  marginRight: '8px',
};
