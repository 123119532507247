import React from 'react';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { ExternalLinksStatus } from './constants';
import { notificationSlice } from 'store/slices/notificationSlice';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { updateSelectedOrder } from 'store/slices/order_management/details';
import { UPDATE_ORDER_EXTERNAL_LINKS } from './mutations';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { formatUrl, isValidUrl } from './utils';

export const useGetExternalLinksConfig = (
  existingExternalLinks: string[],
  orderId: string
) => {
  const [newLink, setNewLink] = React.useState<string>('');
  const [showTextField, setShowTextField] = React.useState<boolean>(false);
  const [externalLinks, setExternalLinks] = React.useState<string[]>(
    existingExternalLinks || []
  );
  const [externalLinksStatus, setExternalLinksStatus] =
    React.useState<ExternalLinksStatus>(ExternalLinksStatus.SUBMIT_DISABLED);
  const [mutate, { loading }] = useSafeMutation(UPDATE_ORDER_EXTERNAL_LINKS);

  const handleSubmitSuccess = useGetHandleSubmitSuccess(
    setExternalLinksStatus,
    setNewLink,
    setShowTextField
  );

  const handleAddLink = useGetHandleAddLink(
    orderId,
    mutate,
    handleSubmitSuccess,
    setExternalLinksStatus,
    setExternalLinks,
    newLink,
    externalLinks
  );

  const handleDeleteLink = useGetHandleDeleteLink(
    orderId,
    mutate,
    handleSubmitSuccess,
    setExternalLinksStatus,
    setExternalLinks,
    newLink,
    externalLinks
  );

  React.useEffect(() => {
    if (!existingExternalLinks) return;
    setExternalLinks(existingExternalLinks);

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      setExternalLinks([]);
      setNewLink('');
      setExternalLinksStatus(ExternalLinksStatus.SUBMIT_DISABLED);
      setShowTextField(false);
    };
  }, [existingExternalLinks]);

  React.useEffect(() => {
    if (!loading) return;
    setExternalLinksStatus(ExternalLinksStatus.LOADING);
  }, [loading]);

  return {
    newLink,
    setNewLink,
    showTextField,
    setShowTextField,
    externalLinks,
    setExternalLinks,
    externalLinksStatus,
    setExternalLinksStatus,
    handleAddLink,
    handleDeleteLink,
  };
};

const useGetHandleSubmitSuccess = (
  setExternalLinksStatus: (status: ExternalLinksStatus) => void,
  setNewLink: (link: string) => void,
  setShowTextField: (show: boolean) => void
) => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const refetchV2Orders = async () => {
    await client.refetchQueries({
      include: ['getPathOrders'],
    });
  };

  return React.useCallback(
    (updatedLinks: string[]) => {
      refetchV2Orders().then(() => {
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
          })
        );
        dispatch(updateSelectedOrder({ externalLinks: updatedLinks }));
        setExternalLinksStatus(ExternalLinksStatus.SUBMIT_DISABLED);
        setNewLink('');
        setShowTextField(false);
      });
    },
    [refetchV2Orders]
  );
};

const useGetHandleAddLink = (
  orderId: string,
  mutate: (variables: any) => Promise<any>,
  handleSubmitSuccess: (updatedLinks: string[]) => void,
  setExternalLinksStatus: (status: ExternalLinksStatus) => void,
  setExternalLinks: (links: string[]) => void,
  newLink: string,
  externalLinks: string[]
) =>
  React.useCallback(() => {
    const formattedUrl = formatUrl(newLink);
    if (!isValidUrl(formattedUrl)) return;

    setExternalLinksStatus(ExternalLinksStatus.LOADING);
    const updatedLinks = [...externalLinks, formattedUrl];

    mutate({
      variables: {
        id: orderId,
        externalLinks: updatedLinks,
      },
      onCompleted: ({
        updateOrder: { externalLinks: updatedLinks },
      }: {
        updateOrder: { externalLinks: string[] };
      }) => {
        handleSubmitSuccess(updatedLinks);
        setExternalLinks(updatedLinks);
      },
    });
  }, [orderId, handleSubmitSuccess, newLink, externalLinks]);

const useGetHandleDeleteLink = (
  orderId: string,
  mutate: (variables: any) => Promise<any>,
  handleSubmitSuccess: (updatedLinks: string[]) => void,
  setExternalLinksStatus: (status: ExternalLinksStatus) => void,
  setExternalLinks: (links: string[]) => void,
  newLink: string,
  externalLinks: string[]
) =>
  React.useCallback(
    (linkToDelete: string) => {
      setExternalLinksStatus(ExternalLinksStatus.LOADING);
      const updatedLinks = externalLinks.filter(
        (link) => link !== linkToDelete
      );

      mutate({
        variables: {
          id: orderId,
          externalLinks: updatedLinks,
        },
        onCompleted: ({
          updateOrder: { externalLinks: updatedLinks },
        }: {
          updateOrder: { externalLinks: string[] };
        }) => {
          handleSubmitSuccess(updatedLinks);
          setExternalLinks(updatedLinks);
        },
      });
    },
    [orderId, handleSubmitSuccess]
  );
