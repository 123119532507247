export const PRIMARY_ADMIN_PERMISSIONS = [
  'asset.inventory.rework.view',
  'billing.view',
  'buy_and_hold.view',
  'expired.orders.q1.2024.view',
  'release.premium.shipping.q1.2024',
  'user.read',
  'user.read',
];

export const GOD_MODE_PERMISSIONS = ['organization.toggle'];
