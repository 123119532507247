import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { ContainerSx, FormInputFieldContainerSx } from './styles';

import { Props } from './types';

const FormInputs: React.FC<Props> = (props) => {
  const { form, 'data-testid': dataTestId } = props;
  const { register } = form;
  return (
    <Stack spacing={2} sx={ContainerSx} data-testid={dataTestId}>
      <TextField
        sx={FormInputFieldContainerSx}
        {...register('clientId', {
          required: true,
        })}
        id="clientId"
        label="Jamf Client ID*"
        fullWidth
        data-testid={`${dataTestId}-client-id`}
      />
      <TextField
        sx={FormInputFieldContainerSx}
        {...register('clientSecret', {
          required: true,
        })}
        id="clientSecret"
        label="Jamf Client Secret*"
        fullWidth
        data-testid={`${dataTestId}-client-secret`}
      />
      <TextField
        sx={FormInputFieldContainerSx}
        {...register('subdomain', {
          required: true,
        })}
        id="subdomain"
        label="Jamf Subdomain"
        fullWidth
        data-testid={`${dataTestId}-subdomain`}
      />
    </Stack>
  );
};

export default FormInputs;
