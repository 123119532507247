import { createSlice } from '@reduxjs/toolkit';

import {
  getMergeConnectionPendingReducer,
  getMergeConnectionFulfilledReducer,
  getMergeConnectionRejectedReducer,
  postMergeCreateLinkTokenPendingReducer,
  postMergeCreateLinkTokenFulfilledReducer,
  postMergeCreateLinkTokenRejectedReducer,
  postMergeSwapAccountTokenPendingReducer,
  postMergeSwapAccountTokenFulfilledReducer,
  postMergeSwapAccountTokenRejectedReducer,
  deleteMergeConnectionPendingReducer,
  deleteMergeConnectionFulfilledReducer,
  deleteMergeConnectionRejectedReducer,
} from './reducers';
import { INITIAL_STATE } from './constants';

import {
  getMergeConnection,
  postMergeCreateLinkToken,
  postMergeSwapAccountToken,
  deleteMergeConnection,
} from './actions';

export const mergeConnectionSlice = createSlice({
  name: 'mergeConnectionSlice',
  initialState: INITIAL_STATE(),
  reducers: {
    resetState: () => INITIAL_STATE(),
  },
  extraReducers: (builder) => {
    builder.addCase(
      getMergeConnection.pending,
      getMergeConnectionPendingReducer
    );
    builder.addCase(
      getMergeConnection.fulfilled,
      getMergeConnectionFulfilledReducer
    );
    builder.addCase(
      getMergeConnection.rejected,
      getMergeConnectionRejectedReducer
    );
    builder.addCase(
      postMergeCreateLinkToken.pending,
      postMergeCreateLinkTokenPendingReducer
    );
    builder.addCase(
      postMergeCreateLinkToken.fulfilled,
      postMergeCreateLinkTokenFulfilledReducer
    );
    builder.addCase(
      postMergeCreateLinkToken.rejected,
      postMergeCreateLinkTokenRejectedReducer
    );
    builder.addCase(
      postMergeSwapAccountToken.pending,
      postMergeSwapAccountTokenPendingReducer
    );
    builder.addCase(
      postMergeSwapAccountToken.fulfilled,
      postMergeSwapAccountTokenFulfilledReducer
    );
    builder.addCase(
      postMergeSwapAccountToken.rejected,
      postMergeSwapAccountTokenRejectedReducer
    );
    builder.addCase(
      deleteMergeConnection.pending,
      deleteMergeConnectionPendingReducer
    );
    builder.addCase(
      deleteMergeConnection.fulfilled,
      deleteMergeConnectionFulfilledReducer
    );
    builder.addCase(
      deleteMergeConnection.rejected,
      deleteMergeConnectionRejectedReducer
    );
  },
});
const { resetState } = mergeConnectionSlice.actions;

export { resetState };
