/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { useDispatch } from 'react-redux';
import CheckShieldIcon from '@mui/icons-material/GppGood';
import XShieldIcon from '@mui/icons-material/GppBad';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { useSafeMutation } from 'hooks/useSafeMutation';
import TextIconButton from 'components/TextIconButton';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { NO_PROTECTION_PLAN } from 'pages/Procurement/components/ProductCatalog/components/ProductDetailsModal/components/LaptopProtectionPlan/constants';
import { INSURANCE_OPTIONS, ORDER_ITEM_TITLE_MAX_LENGTH } from './constants';
import { UPDATE_ORDER_ITEM } from './mutations';
import * as OrderItemStyles from './styles';
import { OrderItemCardProps, UpdateOrderItemPayload } from './types';
import { getOrderItemTitle } from './utils';
import { getOrderItemCopyValue } from 'pages/OrderManagement/components/DetailedOrderView/utils';
import { OrderType } from 'global-constants';
import PinsAndKeys from './components/PinsAndKeys';
import CopyButton from 'components/copy_to_clipboard/CopyButton';

const OrderItemCard = (props: OrderItemCardProps) => {
  if (!props?.orderItem?.id) return null;

  const dispatch = useDispatch();
  const styles = OrderItemStyles;

  const [insuranceRequested, setInsuranceRequested] = React.useState<
    string | undefined
  >();

  const [serialNumber, setSerialNumber] = React.useState<string | undefined>(
    props?.orderItem?.serialNumber
  );

  const [itemQuantity, setItemQuantity] = React.useState<number>(
    props?.orderItem?.quantity ?? 1
  );
  const debouncedItemQuantity = useDebouncedValue(itemQuantity, 600);

  const [
    mutateOrderItem,
    { data: updateOrderItemData, loading: loadingOrderItemUpdate },
  ] = useSafeMutation(UPDATE_ORDER_ITEM, {
    update: (cache, { data: { updateOrderItem } }) => {
      if (!updateOrderItem) return;
      cache.modify({
        optimistic: true,
        fields: {
          getAllOrderItems(data) {
            const orderItems = data || [];
            return orderItems.map((orderItem: any) => {
              return orderItem.id === updateOrderItem?.id
                ? { ...orderItem, ...updateOrderItem }
                : orderItem;
            });
          },
        },
      });
    },
    onCompleted: () => {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
        })
      );
    },
  });

  const getDefaultInsuranceRequested = () =>
    props?.orderItem?.insuranceRequested ??
    INSURANCE_OPTIONS[0].value.insuranceRequested;

  const getDefaultSerialNumber = () => props?.orderItem?.serialNumber ?? '';

  const getHandleInsuranceChange =
    (orderItemId: string) => (event: SelectChangeEvent<string | null>) => {
      if (!event.target.value) return;

      setInsuranceRequested(event.target.value);

      updateOrderItem(orderItemId, {
        insuranceRequested: event.target.value,
      });
    };

  const getHandleSerialNumberClick = (orderItemId: string) => () => {
    if (!serialNumber) return;

    updateOrderItem(orderItemId, {
      serialNumber,
    });
  };

  const updateOrderItem = (
    orderItemId: string,
    payload: UpdateOrderItemPayload
  ) => {
    mutateOrderItem({
      variables: {
        id: orderItemId,
        ...payload,
      },
    });
  };

  const promptDeleteOrderItem = () => {
    props.queueOrderItemRemoval();
  };

  const handleChangeItemQuantity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === '0') return;
    setItemQuantity(Number(event.target.value));
  };

  const hasProtectionPlan = (): boolean => {
    const myPlan = props?.orderItem?.protectionPlan ?? '';
    if (myPlan === NO_PROTECTION_PLAN) return false;
    return !!myPlan;
  };

  const getTitleMarkup = (titlePart: string) => {
    if (titlePart.length > ORDER_ITEM_TITLE_MAX_LENGTH) {
      const abridgedLength = ORDER_ITEM_TITLE_MAX_LENGTH - 3;
      const abridgedTitlePart = `${titlePart.substring(0, abridgedLength)}...`;
      return (
        <Tooltip title={titlePart} placement="top" arrow>
          <Typography>{abridgedTitlePart}</Typography>
        </Tooltip>
      );
    }

    return <Typography>{titlePart}</Typography>;
  };

  React.useEffect(() => {
    setInsuranceRequested(() => getDefaultInsuranceRequested());
  }, [props?.orderItem?.insuranceRequested]);

  React.useEffect(() => {
    if (
      !props?.orderItem?.id ||
      props?.orderItem?.quantity === debouncedItemQuantity
    )
      return;
    updateOrderItem(props?.orderItem?.id, { quantity: debouncedItemQuantity });
  }, [props?.orderItem?.id, debouncedItemQuantity, props?.orderItem?.quantity]);

  const toRetrieveToDepot =
    props.order.orderType.name === OrderType.RETRIEVAL ||
    props.order.orderType.name === OrderType.DEVICE_UNLOCK ||
    props.order.orderType.name === OrderType.BULK_RETRIEVAL;

  const shieldTitleDiff = hasProtectionPlan() ? 'a' : 'No';

  const isProcess = props.order.orderType.name === OrderType.PROCESS;

  return (
    <Card
      sx={styles.gerOrderItemCardSx(loadingOrderItemUpdate)}
      data-order-item-id={props.orderItem.id}
      key={props.orderItem.id}
    >
      <Tooltip
        title={`Order item has ${shieldTitleDiff} protection plan`}
        placement="bottom"
      >
        <Box sx={styles.getOrderItemCardProtectionSx(hasProtectionPlan())}>
          {hasProtectionPlan() ? <CheckShieldIcon /> : <XShieldIcon />}
        </Box>
      </Tooltip>
      <CardHeader
        sx={styles.OrderItemCardHeaderSx}
        title={
          <Stack direction="row" spacing={0.5}>
            <Typography>{`Order Item #${
              props?.orderItem?.orderItemNumber ?? 'N/A'
            }`}</Typography>
            <CopyButton
              copyValue={getOrderItemCopyValue(
                itemQuantity,
                props?.orderItem?.productVariant
              )}
              buttonSx={styles.CopyButtonSx}
            />
          </Stack>
        }
      />
      <CardContent sx={styles.OrderItemCardContentSx}>
        <Grid container justifyContent="left" rowGap="1rem">
          {toRetrieveToDepot ? (
            <Grid item xs={4}>
              <Stack direction="column">
                <Typography className="order-details-card-title">
                  ASSET TYPE
                </Typography>
                <Typography>
                  {props?.orderItem?.asset?.assetType?.name}
                </Typography>
              </Stack>
            </Grid>
          ) : null}
          <Grid item xs={4}>
            <Stack direction="column">
              <Typography className="order-details-card-title">
                TITLE
              </Typography>
              {getOrderItemTitle(props?.order, props?.orderItem).map(
                getTitleMarkup
              )}
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row">
              {props?.orderItem?.productVariant ? (
                <Stack direction="column" sx={{ mr: '16px' }}>
                  <Typography className="order-details-card-title">
                    SKU
                  </Typography>
                  <Typography>
                    {props?.orderItem?.productVariant?.sku}
                  </Typography>
                </Stack>
              ) : null}
              {props?.orderItem?.asset ? (
                <Stack direction="column" sx={{ mr: '16px' }}>
                  <Typography className="order-details-card-title">
                    ASSET NUMBER
                  </Typography>
                  <Typography>
                    {props?.orderItem?.asset?.assetNumber}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
          </Grid>
          {toRetrieveToDepot ? (
            <>
              <Grid item xs={4}>
                <Stack direction="column">
                  <Typography className="order-details-card-title">
                    PROCESS/HOLD
                  </Typography>
                  <Typography>
                    {props?.orderItem?.processDevice ? 'True' : 'False'}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="column">
                  <Typography className="order-details-card-title">
                    MDM Pin (Deprecated)
                  </Typography>
                  <Typography>{props?.orderItem?.mdmPin ?? 'N/A'}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="column">
                  <Typography className="order-details-card-title">
                    KNOWN ISSUES
                  </Typography>
                  <Typography>
                    {props?.orderItem?.knownIssues ? 'True' : 'False'}
                  </Typography>
                </Stack>
              </Grid>
            </>
          ) : null}
          {isProcess ? <PinsAndKeys {...props} /> : null}
          <Grid item xs={props?.orderItem?.productVariant ? 6 : 12}>
            <Stack
              direction="column"
              sx={{ pr: props?.orderItem?.productVariant ? 5 : 0 }}
            >
              <Typography className="order-details-card-title">
                INSURANCE COVERAGE AMOUNT
              </Typography>
              <FormControl sx={styles.OrderItemCardSingleSelectInputSx}>
                <Select
                  variant="standard"
                  defaultValue={getDefaultInsuranceRequested()}
                  value={insuranceRequested}
                  onChange={getHandleInsuranceChange(props.orderItem.id)}
                >
                  {INSURANCE_OPTIONS.map((option) => (
                    <MenuItem value={option.value.insuranceRequested}>
                      {option.optionText}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          {props?.orderItem?.productVariant ? (
            <Grid item xs={4}>
              <TextField
                label="Quantity"
                variant="outlined"
                type="number"
                sx={styles.OrderItemCardQuantityFieldSx}
                value={itemQuantity}
                onChange={handleChangeItemQuantity}
              />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Stack direction="column" width="50%">
              <Typography className="order-details-card-title">
                SERIAL #
              </Typography>
              <FormControl sx={styles.OrderItemCardTextFieldSx}>
                <TextField
                  variant="standard"
                  defaultValue={getDefaultSerialNumber()}
                  value={serialNumber}
                  onChange={(e) => setSerialNumber(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={getHandleSerialNumberClick(
                            props.orderItem.id
                          )}
                          disabled={serialNumber === getDefaultSerialNumber()}
                        >
                          <SaveIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </FormControl>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TextIconButton
              text="Delete order item"
              ariaLabel={`Delete order item with ${props?.orderItem?.orderItemNumber}`}
              onClick={promptDeleteOrderItem}
              icon={<DeleteIcon />}
              buttonStyles={styles.OrderItemCardDeleteButtonSx}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrderItemCard;
