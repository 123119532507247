import { createAsyncThunk } from '@reduxjs/toolkit';
import * as jamfConnectionAPI from 'api/rest/integrations/jamf/connection';
import { Connection } from 'api/rest/integrations/jamf/connection/types';

export const getJamfConnection = createAsyncThunk<
  Connection,
  { orgId: string }
>('integrations/jamf/getJamfConnection', async (params: { orgId: string }) => {
  const { orgId } = params;
  return jamfConnectionAPI.getJamfConnection(orgId);
});

export const postJamfConnection = createAsyncThunk<
  Connection,
  { orgId: string; data: Connection }
>(
  'integrations/jamf/postJamfConnection',
  async (params: { orgId: string; data: Connection }) => {
    const { orgId, data } = params;
    return jamfConnectionAPI.postJamfConnection(orgId, data);
  }
);

export const putJamfConnection = createAsyncThunk<
  Connection,
  { orgId: string; data: Connection }
>(
  'integrations/jamf/putJamfConnection',
  async (params: { orgId: string; data: Connection }) => {
    const { orgId, data } = params;
    return jamfConnectionAPI.putJamfConnection(orgId, data);
  }
);
