export const ContainerSx = {
  mt: '30px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export const FormInputFieldContainerSx = {
  width: '100%',
  borderRadius: '0px',
  backgroundColor: 'white',
  pl: 0,
  '& .MuiInputBase-root': {
    borderRadius: '8',
  },
};
