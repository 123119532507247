import { IntegrationsListState } from './types';
import jamf from 'assets/jamf.png';
import kandji from 'assets/kandji.png';
import microsoftIntune from 'assets/microsoft.png';
import jumpcloud from 'assets/jump-cloud.png';
import ticketing from 'assets/ticketing.png';
import merge from 'assets/merge.png';

export const INITIAL_STATE: () => IntegrationsListState = () => {
  return {
    enabled: [
      {
        key: 'jamf',
        name: 'Jamf Pro',
        description: '',
        image: jamf,
        disabled: true,
        connected: false,
        loading: false,
        copy: 'Connect',
        connectPath: '/integrations/jamf/connect',
        settingsPath: '/integrations/jamf/settings',
        requestAccessPath:
          'https://share.hsforms.com/2lHF83oy_RaGX2za5b_Ww7gczkpi',
      },
      {
        key: 'merge',
        name: 'HR, Payroll and SCIM Directory',
        description: '',
        image: merge,
        disabled: true,
        connected: false,
        loading: false,
        copy: 'Connect',
        connectPath: '/integrations/merge/connect',
        settingsPath: '/integrations/merge/settings',
      },
    ],
    disabled: [
      {
        key: 'kandji',
        name: 'Kandji',
        description: '',
        image: kandji,
        disabled: true,
        connected: false,
        copy: '',
        connectPath: '/integrations/kandji/connect',
        settingsPath: '/integrations/kandji/settings',
      },
      {
        key: 'microsoft-intune',
        name: 'Microsoft Intune',
        description: '',
        image: microsoftIntune,
        disabled: true,
        connected: false,
        copy: '',
        connectPath: '/integrations/microsoft-intune/connect',
        settingsPath: '/integrations/microsoft-intune/settings',
      },
      {
        key: 'jumpcloud',
        name: 'Jumpcloud',
        description: '',
        image: jumpcloud,
        disabled: true,
        connected: false,
        copy: '',
        connectPath: '/integrations/jumpcloud/connect',
        settingsPath: '/integrations/jumpcloud/settings',
      },
      {
        key: 'ticketing',
        name: 'Ticketing',
        description:
          'We look forward to supporting Jira, Servicenow, Fresh Service, and more',
        image: ticketing,
        disabled: true,
        connected: false,
        copy: '',
        connectPath: '/integrations/ticketing/connect',
        settingsPath: '/integrations/ticketing/settings',
      },
    ],
  };
};
