import { Collaborator, Organization } from 'types';

export const getShouldShowAlertBanner = (
  user: Collaborator | undefined,
  organization: Organization | undefined,
  loadingState: 'idle' | 'pending' | 'fulfilled' | 'rejected',
  userCanConfigureIntegrations: boolean
) => {
  return (
    !userCanConfigureIntegrations &&
    loadingState !== 'idle' &&
    loadingState !== 'pending' &&
    Boolean(user) &&
    Boolean(organization)
  );
};
