import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { selectMergeConnection } from 'store/slices/integrations/merge/connect/selectors';
import { selectGlobalGodModeOrganization } from 'store/slices/global/selectors';

export const useGetLastSyncStatus = () => {
  const organization = useSelector(selectGlobalGodModeOrganization);
  let hasAccountToken = false;
  let prettyDate = null;
  let integration = null;
  const connection = useSelector(selectMergeConnection);
  if (organization?.hrisAccountToken) {
    hasAccountToken = true;
    const hrisEmployeeDataSyncedAt = organization?.hrisEmployeeDataSyncedAt;
    prettyDate = moment
      .utc(hrisEmployeeDataSyncedAt)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format('LLL z');
    if (prettyDate === 'Invalid date') {
      prettyDate = '(In Progress)';
    }
    integration = connection.integration;
  }

  return { connection, hasAccountToken, prettyDate, integration };
};
