export enum CategoryEnum {
  Hris = 'hris',
  Ats = 'ats',
  Accounting = 'accounting',
  Ticketing = 'ticketing',
  Crm = 'crm',
  Mktg = 'mktg',
  Filestorage = 'filestorage',
}

export interface GetMergeConnectionResponse {
  id?: string;
  integration?: string;
  integrationSlug?: string;
  category?: CategoryEnum;
  endUserOriginId?: string;
  endUserOrganizationName?: string;
  endUserEmailAddress?: string;
  status?: 'COMPLETE' | 'INCOMPLETE' | 'RELINK_NEEDED';
  webhookListenerUrl?: string;
  isDuplicate?: boolean;
  accountType?: string;
}

export interface PostMergeCreateLinkTokenResponse {
  linkToken: string;
  magicLinkUrl: string;
}

export interface PostMergeCreateLinkTokenBody {
  email: string;
}

export interface PostMergeSwapAccountTokenResponse {
  category: string;
  email: string;
  integration: string;
  status: 'COMPLETE' | 'INCOMPLETE' | 'RELINK_NEEDED';
}

export interface PostMergeSwapAccountTokenBody {
  publicToken: string;
}
