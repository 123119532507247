import { AxiosResponse } from 'axios';
import { Connection } from './types';
import { MonoService } from 'api/rest/config';

/*
// TODO: temporary for local testing, will remove once all testing is done
const LocalService = axios.create({
  baseURL: 'http://localhost:3000',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

// TODO: remove
LocalService.interceptors.request.use(
  (config) => {
    const token = getLocalStorageValue('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// TODO: remove and update base axios interceptor
LocalService.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export const getJamfConnection = (orgId: string) => {
  return LocalService.get<Connection, AxiosResponse<Connection>>(
    `/v1/connection/${orgId}`
  ).then((response) => response.data);
};

export const postJamfConnection = (orgId: string, data: Connection) => {
  return LocalService.post<Connection, AxiosResponse<Connection>>(
    `/v1/connection/${orgId}`,
    data
  ).then((response) => response.data);
};

export const putJamfConnection = (orgId: string, data: Connection) => {
  return LocalService.put<Connection, AxiosResponse<Connection>>(
    `/v1/connection/${orgId}`,
    data
  ).then((response) => response.data);
};
*/

export const getJamfConnection = (orgId: string) => {
  return MonoService.get<Connection, AxiosResponse<Connection>>(
    `/v1/connection/${orgId}`
  ).then((response) => response.data);
};

export const postJamfConnection = (orgId: string, data: Connection) => {
  return MonoService.post<Connection, AxiosResponse<Connection>>(
    `/v1/connection/${orgId}`,
    data
  ).then((response) => {
    return response.data;
  });
};

export const putJamfConnection = (orgId: string, data: Connection) => {
  return MonoService.put<Connection, AxiosResponse<Connection>>(
    `/v1/connection/${orgId}`,
    data
  ).then((response) => response.data);
};
