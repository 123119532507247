export const PROCESS_DEVICE_TITLE = 'Process/Unlock Device';

export const PROCESS_DEVICE_SUBTITLE =
  'Your device will undergo testing, sanitization, data erasure and reinstallation of the Operating System. Once processed your device will be ready for deployment.';

export const SAVE_BUTTON_COPY = 'Submit order';

export const WIPE_CONFIRMATION_CHECKBOX_LABEL = 'I confirm to wipe this device';

export const AUTOCOMPLETE_HELPER_TEXT = 'Press ↵Enter to add';
export const AUTOCOMPLETE_HELPER_TEXT_MAX =
  'Limit of 3 keys/PINs reached. Remove one to add a new entry.';
