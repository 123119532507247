export const getBreadcrumbLinks = (pathname: string) => {
  const parts = pathname.split('/').filter(Boolean);
  const accum: string[] = [];
  let path = '';
  parts.forEach((part: string, index: number) => {
    path = `${path}/${part}`;
    accum.push(path);
  });
  return accum;
};
