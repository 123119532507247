import axios from 'axios';
import { getLocalStorageValue } from '../storage';

const {
  REACT_APP_FILE_DOWNLOAD_SERVICE_API_BASE_URL,
  REACT_APP_ALLWHERE_WEBSITE,
  REACT_APP_FILE_UPLOAD_SERVICE_API_BASE_URL,
  REACT_APP_API_BASE_URL,
  REACT_APP_MERGE_API_BASE_URL,
} = process.env;

const MonoService = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

MonoService.interceptors.request.use(
  (config) => {
    const token = getLocalStorageValue('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

MonoService.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

const MergeService = axios.create({
  baseURL: REACT_APP_MERGE_API_BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

MergeService.interceptors.request.use(
  (config) => {
    const token = getLocalStorageValue('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

MergeService.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

const FileDownloadService = axios.create({
  baseURL: `${REACT_APP_FILE_DOWNLOAD_SERVICE_API_BASE_URL}/v1`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

FileDownloadService.interceptors.request.use(
  (config) => {
    const token = getLocalStorageValue('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AllWhereWebsiteService = axios.create({
  baseURL: REACT_APP_ALLWHERE_WEBSITE,
});

const FileDownloadServiceDirect = axios.create({
  baseURL: `${REACT_APP_FILE_DOWNLOAD_SERVICE_API_BASE_URL}/v1`,
  responseType: 'text',
  headers: {
    'Content-Type': 'text/csv',
  },
});

FileDownloadServiceDirect.interceptors.request.use(
  (config) => {
    const token = getLocalStorageValue('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const FileUploadService = axios.create({
  baseURL: `${REACT_APP_FILE_UPLOAD_SERVICE_API_BASE_URL}/v1/file-upload/uploads`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

FileUploadService.interceptors.request.use(
  (config) => {
    const token = getLocalStorageValue('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export {
  MonoService,
  MergeService,
  FileDownloadService,
  FileDownloadServiceDirect,
  FileUploadService,
  AllWhereWebsiteService,
};
