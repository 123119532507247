import {
  ProcurementFlowEmailType,
  ProcurementServiceActions,
} from './constants';
import { Action, InitialState } from './types';
import {
  changeQuantityOfProductVariantIdAndReturnCart,
  findCollaboratorToUpdate,
  removeProductVariantFromCart,
} from './utils';

export const reducer = (state: InitialState, action: Action) => {
  switch (action.type) {
    case ProcurementServiceActions.ADD_PRODUCT_VARIANT_TO_CART: {
      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...(action.payload.insuranceAmount && {
              insuranceAmount: action.payload.insuranceAmount,
            }),
            ...(action.payload.insuranceCharge && {
              insuranceCharge: action.payload.insuranceCharge,
            }),
            ...(action.payload.protectionPlan && {
              protectionPlan: action.payload.protectionPlan,
            }),
            productVariantId: action.payload.productVariantId,
            productVariantPrice: action.payload.productVariantPrice,
            productName: action.payload.productName,
            productCategoryId: action.payload.productCategoryId,
            variantValues: action.payload.variantValues,
            destinationAddress: action.payload.destinationAddress,
            quantity: action.payload.quantity,
          },
        ],
      };
    }
    case ProcurementServiceActions.UPDATE_PRODUCT_VARIANT_QUANTITY_IN_CART: {
      const cartWithProductVariantQuantityUpdated =
        changeQuantityOfProductVariantIdAndReturnCart(
          state.cart,
          action.payload.productVariantId,
          action.payload.operand,
          action.payload.operation
        );
      return {
        ...state,
        cart: cartWithProductVariantQuantityUpdated,
      };
    }
    case ProcurementServiceActions.ADD_SELECTED_COLLABORATOR:
      return {
        ...state,
        selectedCollaborators: [...state.selectedCollaborators, action.payload],
        selectedCollaboratorRecipientEmailMap: {
          ...state.selectedCollaboratorRecipientEmailMap,
          [action.payload.id]:
            action.payload.personalEmail || action.payload.email,
        },
        collaboratorsEmailType: ProcurementFlowEmailType.NONE,
      };
    case ProcurementServiceActions.UPDATE_SELECTED_COLLABORATOR_RECIPIENT_EMAIL:
      return {
        ...state,
        selectedCollaboratorRecipientEmailMap: {
          ...state.selectedCollaboratorRecipientEmailMap,
          [action.payload.collaboratorId]: action.payload.email,
        },
        collaboratorsEmailType: ProcurementFlowEmailType.NONE,
      };
    case ProcurementServiceActions.UPDATE_EMAIL_TYPE_FOR_ALL_COLLABORATORS:
      return {
        ...state,
        collaboratorsEmailType: action.payload,
      };
    case ProcurementServiceActions.UPDATE_SELECTED_COLLABORATOR_RECIPIENT_ADDRESS: {
      const collaboratorToUpdate = findCollaboratorToUpdate(
        state.selectedCollaborators,
        action.payload.id
      );

      if (!collaboratorToUpdate) return state;

      return {
        ...state,
        selectedCollaborators: [
          ...state.selectedCollaborators.slice(0, collaboratorToUpdate.index),
          {
            ...collaboratorToUpdate.collaborator,
            selectedAddress: action.payload.address,
          },
          ...state.selectedCollaborators.slice(collaboratorToUpdate.index + 1),
        ],
      };
    }
    case ProcurementServiceActions.REMOVE_SELECTED_COLLABORATOR_RECIPIENT_ADDRESS: {
      const collaboratorToUpdate = findCollaboratorToUpdate(
        state.selectedCollaborators,
        action.payload.id
      );

      if (!collaboratorToUpdate) return state;

      return {
        ...state,
        selectedCollaborators: [
          ...state.selectedCollaborators.slice(0, collaboratorToUpdate.index),
          {
            ...collaboratorToUpdate.collaborator,
            selectedAddress: null,
          },
          ...state.selectedCollaborators.slice(collaboratorToUpdate.index + 1),
        ],
      };
    }
    case ProcurementServiceActions.ADD_SELECTED_COLLABORATOR_ADDRESSES: {
      const collaboratorToUpdate = findCollaboratorToUpdate(
        state.selectedCollaborators,
        action.payload.id
      );

      if (!collaboratorToUpdate) return state;

      return {
        ...state,
        selectedCollaborators: [
          ...state.selectedCollaborators.slice(0, collaboratorToUpdate.index),
          {
            ...collaboratorToUpdate.collaborator,
            homeAddresses: action.payload.homeAddresses,
            workAddresses: action.payload.workAddresses,
          },
          ...state.selectedCollaborators.slice(collaboratorToUpdate.index + 1),
        ],
      };
    }
    case ProcurementServiceActions.ADD_SELECTED_COLLABORATOR_SELECTABLE_ADDRESSES: {
      const collaboratorToUpdate = findCollaboratorToUpdate(
        state.selectedCollaborators,
        action.payload.id
      );

      if (!collaboratorToUpdate) return state;

      return {
        ...state,
        selectedCollaborators: [
          ...state.selectedCollaborators.slice(0, collaboratorToUpdate.index),
          {
            ...collaboratorToUpdate.collaborator,
            selectableAddresses: action.payload.selectableAddresses,
          },
          ...state.selectedCollaborators.slice(collaboratorToUpdate.index + 1),
        ],
      };
    }
    case ProcurementServiceActions.DESELECT_ALL_COLLABORATORS:
      return {
        ...state,
        selectedCollaborators: action.payload,
        selectedCollaboratorRecipientEmailMap: {},
        collaboratorsEmailType: ProcurementFlowEmailType.NONE,
      };
    case ProcurementServiceActions.DISMISS_CURRENT_MODAL:
      return { ...state, currentModal: action.payload };
    case ProcurementServiceActions.REMOVE_PRODUCT_VARIANT_FROM_CART: {
      const newCart = removeProductVariantFromCart(state.cart, action.payload);
      return {
        ...state,
        cart: newCart,
      };
    }
    case ProcurementServiceActions.REMOVE_SELECTED_COLLABORATOR:
      return {
        ...state,
        selectedCollaborators: state.selectedCollaborators.filter(
          (collaborator) => collaborator.email !== action.payload.email
        ),
        selectedCollaboratorRecipientEmailMap: {
          ...(delete state.selectedCollaboratorRecipientEmailMap[
            action.payload.id
          ] && state.selectedCollaboratorRecipientEmailMap),
        },
      };
    case ProcurementServiceActions.SET_CHECKOUT_NOTES:
      return { ...state, checkoutNotes: action.payload.checkoutNotes };
    case ProcurementServiceActions.SET_CURRENT_MODAL:
      return { ...state, currentModal: action.payload };
    case ProcurementServiceActions.UPDATE_EXPERIENCE_STEP:
      return { ...state, flowStep: action.payload };
    case ProcurementServiceActions.CHECKOUT_ORDER:
      return {
        ...state,
        ...action.payload,
      };
    case ProcurementServiceActions.UPDATE_QUOTE_REQUESTED:
      return {
        ...state,
        quoteRequested: action.payload,
      };
    case ProcurementServiceActions.RESET: {
      return { ...action.payload };
    }
    case ProcurementServiceActions.ADD_SELECTED_DEPOT:
      return { ...state, selectedDepotLocation: action.payload };
    case ProcurementServiceActions.REMOVE_SELECTED_DEPOT:
      return { ...state, selectedDepotLocation: null };
    case ProcurementServiceActions.UPDATE_SHIPPING_TYPE_ID:
      return { ...state, shippingTypeId: action.payload };
    case ProcurementServiceActions.CLEAR_SHIPPING_TYPE_ID:
      return { ...state, shippingTypeId: null };
    case ProcurementServiceActions.SET_DEVICE_COLOR_SWAP_APPROVED:
      return { ...state, deviceColorSwapApproved: action.payload };
    case ProcurementServiceActions.CLEAR_DEVICE_COLOR_SWAP_APPROVED:
      return { ...state, deviceColorSwapApproved: null };
    case ProcurementServiceActions.SET_FLEXIBLE_LEAD_TIME_APPROVED:
      return { ...state, flexibleLeadTimeApproved: action.payload };
    case ProcurementServiceActions.CLEAR_FLEXIBLE_LEAD_TIME_APPROVED:
      return { ...state, flexibleLeadTimeApproved: null };
    default:
      throw new Error(`Invalid action: ${action.type}`);
  }
};
