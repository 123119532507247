import { RootState } from 'store/index';

export const selectColumnsOpen = (state: RootState) => {
  return state.orderManagement.columns.popover.open;
};

export const selectColumnsChanged = (state: RootState) => {
  return state.orderManagement.columns.popover.changed;
};

export const selectColumns = (state: RootState) => {
  return state.orderManagement.columns.columns;
};

export const selectSearchInput = (state: RootState) => {
  return state.orderManagement.columns.popover.searchInput;
};

export const selectInitialized = (state: RootState) => {
  return state.orderManagement.columns.initialized;
};
