import { createSlice } from '@reduxjs/toolkit';

import { setViewReducer } from './reducers';

import { INITIAL_STATE } from './constants';

export const jamfSettingsSlice = createSlice({
  name: 'jamfSettingsSlice',
  initialState: INITIAL_STATE(),
  reducers: {
    setViewReducer,
    resetState: () => INITIAL_STATE(),
  },
});

const { setViewReducer: setView, resetState } = jamfSettingsSlice.actions;

export { setView, resetState };
