import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from 'state';
import { useCollaboratorControllerRetrieve } from 'services/openapi/apiComponents';
import { setGlobalGodModeUser } from 'store/slices/global';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';

export const useGetGlobalPurchaser = () => {
  const { user } = useAuth0();
  const dispatch = useDispatch<AppDispatch>();

  const [globalPurchaser, setGlobalPurchaser] =
    useGlobalState('globalPurchaser');

  //TODO Refactor userOrganization to be globalOrganization in another PR because it is more fitting
  const [globalOrganization] = useGlobalState('userOrganization');

  useCollaboratorControllerRetrieve(
    {
      pathParams: {
        id: user?.collaboratorId,
      },
    },
    {
      enabled: !!user?.collaboratorId && !!globalOrganization?.id,
      onSuccess: (data) => {
        // global purchaser must be in the global organization
        if (data.organizationId === globalOrganization?.id) {
          setGlobalPurchaser(data);
          dispatch(setGlobalGodModeUser(data));
        }
      },
    }
  );

  return globalPurchaser;
};

export default useGetGlobalPurchaser;
