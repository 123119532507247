import { gql } from '@apollo/client';

export const CREATE_PROCESS_DEVICE_ORDER = gql`
  mutation createProcessOrder(
    $purchaserId: ID!
    $assetId: ID!
    $mdmPins: [String!]
    $activationKeys: [String!]
    $recoveryCodes: [String!]
  ) {
    createProcessOrder(
      processOrderCreateInput: {
        purchaserId: $purchaserId
        assetId: $assetId
        mdmPins: $mdmPins
        activationKeys: $activationKeys
        recoveryCodes: $recoveryCodes
      }
    ) {
      id
    }
  }
`;
