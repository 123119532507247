import React from 'react';
import * as storage from 'api/storage';
import { OnDragEndResponder } from '@hello-pangea/dnd';
import {
  setColumnsOpen,
  setSearchInput,
  setColumns,
  swapColumns,
  setAllColumns,
  setAllColumnsActive,
  setColumnsChanged,
  resetState,
  setInitialized,
} from 'store/slices/order_management/columns';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectColumnsOpen,
  selectColumns,
  selectSearchInput,
  selectColumnsChanged,
  selectInitialized,
} from 'store/slices/order_management/columns/selectors';
import { ColumnValue } from 'store/slices/shared/columns/types';

import { COLUMN_LOCALSTORAGE_KEY } from './constants';
import { OrderManagementColumnKey } from 'store/slices/order_management/columns/types';
import { INITIAL_STATE } from 'store/slices/order_management/columns/constants';

export const useGetOnPopoverOpen = () => {
  const dispatch = useDispatch();
  return React.useCallback((_: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setColumnsOpen(true));
  }, []);
};

export const useGetOnPopoverClose = () => {
  const dispatch = useDispatch();
  return React.useCallback((_: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setColumnsOpen(false));
  }, []);
};

export const useGetOnHandleDragEnd = () => {
  const dispatch = useDispatch();

  return React.useCallback<OnDragEndResponder>((dropResult) => {
    const destinationIndex = dropResult.destination?.index;
    const sourceIndex = dropResult.source.index;
    if (
      destinationIndex !== undefined &&
      destinationIndex >= 0 &&
      sourceIndex >= 0
    ) {
      dispatch(swapColumns({ sourceIndex, destinationIndex }));
      dispatch(setColumnsChanged(true));
    }
  }, []);
};

export const useGetOnShowAllClick = () => {
  const dispatch = useDispatch();
  return React.useCallback((_: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setAllColumnsActive({ excludedColumns: [], active: true }));
    dispatch(setColumnsChanged(true));
  }, []);
};

export const useGetOnHideAllClick = () => {
  const dispatch = useDispatch();
  return React.useCallback((_: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setAllColumnsActive({ excludedColumns: [], active: false }));
    dispatch(setColumnsChanged(true));
  }, []);
};

export const useGetOnSearchInput = () => {
  const dispatch = useDispatch();
  return React.useCallback((searchInput: string) => {
    dispatch(setSearchInput(searchInput));
  }, []);
};

export const useGetOnColumnToggle = () => {
  const dispatch = useDispatch();
  return React.useCallback(
    (column: ColumnValue<OrderManagementColumnKey>) =>
      (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        dispatch(
          setColumns({
            id: column.id,
            active: checked,
          })
        );
        dispatch(setColumnsChanged(true));
      },
    []
  );
};

export const useGetOnRestoreColumnDefaults = () => {
  const dispatch = useDispatch();
  return React.useCallback(() => {
    dispatch(
      resetState({
        initialState: INITIAL_STATE,
        overrides: { 'popover.open': true, initialized: true },
      })
    );
  }, []);
};

export const useGetConfig = () => {
  const open = useSelector(selectColumnsOpen);
  const searchInput = useSelector(selectSearchInput);
  const changed = useSelector(selectColumnsChanged);
  const columns = useSelector(selectColumns);
  const onPopoverOpen = useGetOnPopoverOpen();
  const onPopoverClose = useGetOnPopoverClose();
  const onHandleDragEnd = useGetOnHandleDragEnd();
  const onShowAllClick = useGetOnShowAllClick();
  const onHideAllClick = useGetOnHideAllClick();
  const onSearchInput = useGetOnSearchInput();
  const onColumnToggle = useGetOnColumnToggle();
  const onRestoreColumnDefaults = useGetOnRestoreColumnDefaults();

  return {
    columns,
    onShowAllClick,
    onHideAllClick,
    onPopoverClose,
    onPopoverOpen,
    onHandleDragEnd,
    onColumnToggle,
    onSearchInput,
    onRestoreColumnDefaults,
    open,
    searchInput,
    changed,
  };
};

export const useUpdateColumnsLocalStorage = () => {
  const columns = useSelector(selectColumns);
  const changed = useSelector(selectColumnsChanged);
  const initialized = useSelector(selectInitialized);

  React.useEffect(() => {
    if (initialized) {
      const key = `${COLUMN_LOCALSTORAGE_KEY}`;
      storage.setLocalStorageValue(key, {
        changed,
        columns,
      });
    }
  }, [columns, changed, initialized]);
};

export const useLoadInitialStateFromLocalStorage = () => {
  const dispatch = useDispatch();
  return React.useEffect(() => {
    const key = `${COLUMN_LOCALSTORAGE_KEY}`;
    const columnsData = storage.getLocalStorageValue(key) as {
      changed: boolean;
      columns: ColumnValue<OrderManagementColumnKey>[];
    };
    const columns = columnsData.columns || [];
    if (columnsData.columns) {
      dispatch(setAllColumns(columns));
      dispatch(setColumnsChanged(columnsData.changed));
    }
    dispatch(setInitialized(true));
  }, []);
};
