import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { MergeCredentialsFormFields } from '../forms/MergeCredentialsForm/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMergeConnectionGetErrorMessage,
  selectMergeConnection,
  selectMergeLinkToken,
} from 'store/slices/integrations/merge/connect/selectors';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import {
  useGetMergeConnection,
  useCanUserConfigureIntegrations,
} from 'pages/integrations/Integrations/hooks';
import { notificationSlice } from 'store/slices';
import { AppDispatch } from 'store';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  deleteMergeConnection,
  postMergeCreateLinkToken,
} from 'store/slices/integrations/merge/connect/actions';
import {
  selectGlobalGodModeOrganization,
  selectGlobalGodModeUser,
} from 'store/slices/global/selectors';
import {
  setDeleteIntegrationModalOpen,
  resetState as resetMergeSettingsState,
} from 'store/slices/integrations/merge/settings';
import { selectDeleteIntegrationModalOpen } from 'store/slices/integrations/merge/settings/selectors';
import { getGodModeOrganization } from 'store/slices/global/actions';

export const useConfigureMergeSettingsPage = (
  form: UseFormReturn<MergeCredentialsFormFields>
) => {
  const dispatch = useDispatch<AppDispatch>();
  const connection = useSelector(selectMergeConnection);
  const user = useGetGlobalPurchaser();
  const organization = useGetUserOrganization();
  useGetMergeConnection();
  useSetFormValues(form);
  useHandleErrorNotifications();
  useGetCreateLinkToken();
  const handleGetCreateLinkToken = useHandleGetCreateLinkToken();
  const userCanConfigureIntegrations = useCanUserConfigureIntegrations();
  const showAlertBanner = !userCanConfigureIntegrations;
  const deleteIntegrationModalOpen = useSelector(
    selectDeleteIntegrationModalOpen
  );
  const linkToken = useSelector(selectMergeLinkToken);
  const handleDeleteConnection = useHandleDeleteConnection();
  const handleOnSecondaryButtonClick = useHandleOnSecondaryButtonClick();
  const handleCloseDeleteIntegrationModal =
    useHandleCloseDeleteIntegrationModal();
  React.useEffect(() => {
    return () => {
      dispatch(resetMergeSettingsState());
    };
  }, [connection.endUserEmailAddress]);
  const onExit = React.useCallback(() => {
    handleGetCreateLinkToken();
  }, [organization?.id, user?.id]);

  const onValidationError = React.useCallback(() => {
    handleGetCreateLinkToken();
  }, [organization?.id, user?.id]);

  const onSuccess = React.useCallback(() => {}, [organization?.id, user?.id]);
  return {
    onExit,
    onValidationError,
    onSuccess,
    showAlertBanner,
    deleteIntegrationModalOpen,
    linkToken,
    handleDeleteConnection,
    handleOnSecondaryButtonClick,
    handleCloseDeleteIntegrationModal,
    handleGetCreateLinkToken,
    connection,
    organization,
    user,
  };
};

export const useSetFormValues = (
  form: UseFormReturn<MergeCredentialsFormFields>
) => {
  const location = useLocation();
  const connection = useSelector(selectMergeConnection);
  const user = useGetGlobalPurchaser();
  const organization = useGetUserOrganization();
  useGetMergeConnection();

  React.useEffect(() => {
    const { endUserEmailAddress, integration, category, status } = connection;

    if (endUserEmailAddress && form && user?.id && organization?.id) {
      form.setValue('endUserEmailAddress', endUserEmailAddress);
      form.setValue('integration', integration ?? '');
      form.setValue('category', category ?? '');
      form.setValue('status', status ?? '');
    }
  }, [form, connection, user?.id, organization?.id, location.pathname]);
};

export const useHandleErrorNotifications = () => {
  const dispatch = useDispatch<AppDispatch>();
  const getErrorMessage = useSelector(selectMergeConnectionGetErrorMessage);
  React.useEffect(() => {
    if (getErrorMessage) {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: getErrorMessage,
        })
      );
    }
  }, [dispatch, getErrorMessage]);
};

export const useGetCreateLinkToken = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useSelector(selectGlobalGodModeOrganization);
  const user = useSelector(selectGlobalGodModeUser);
  const connection = useSelector(selectMergeConnection);

  return React.useEffect(() => {
    if (organization?.id && user?.id && connection.endUserEmailAddress) {
      dispatch(
        postMergeCreateLinkToken({
          orgId: organization.id,
          data: {
            email: connection.endUserEmailAddress,
          },
        })
      );
    }
  }, [organization?.id, user?.id, connection.endUserEmailAddress]);
};

export const useHandleGetCreateLinkToken = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useSelector(selectGlobalGodModeOrganization);
  const user = useSelector(selectGlobalGodModeUser);
  const connection = useSelector(selectMergeConnection);

  return React.useCallback(() => {
    if (organization?.id && user?.id && connection.endUserEmailAddress) {
      dispatch(
        postMergeCreateLinkToken({
          orgId: organization.id,
          data: {
            email: connection.endUserEmailAddress,
          },
        })
      );
    }
  }, [organization?.id, user?.id, connection.endUserEmailAddress]);
};

export const useHandleDeleteConnection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const organization = useSelector(selectGlobalGodModeOrganization);
  const user = useSelector(selectGlobalGodModeUser);
  const connection = useSelector(selectMergeConnection);

  return React.useCallback(async () => {
    if (organization?.id && user?.id && connection.endUserEmailAddress) {
      const response = await dispatch(
        deleteMergeConnection({ orgId: organization.id })
      );
      if (response.meta.requestStatus === 'fulfilled') {
        await dispatch(
          getGodModeOrganization({ organizationId: organization?.id })
        );
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: 'Connection deleted successfully',
          })
        );
        navigate('/integrations');
      }
    }
  }, [organization?.id, user?.id, connection]);
};

export const useHandleOnSecondaryButtonClick = () => {
  const dispatch = useDispatch<AppDispatch>();
  return React.useCallback(() => {
    dispatch(setDeleteIntegrationModalOpen(true));
  }, [dispatch]);
};

export const useHandleCloseDeleteIntegrationModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  return React.useCallback(() => {
    dispatch(setDeleteIntegrationModalOpen(false));
  }, [dispatch]);
};
