import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/immer';

import { Integration, IntegrationsListState } from './types';

export const setIntegrationsValueReducer = (
  state: WritableDraft<IntegrationsListState>,
  action: PayloadAction<
    {
      type: keyof IntegrationsListState;
      id: string;
      key: keyof Integration;
      value: string;
    },
    string
  >
) => {
  const integration = state[action.payload.type].find(
    (integration) => integration.key === action.payload.id
  );
  if (integration) {
    // @ts-ignore
    integration[action.payload.key] = action.payload.value;
  }
};

export const setIntegrationsReducer = (
  state: WritableDraft<IntegrationsListState>,
  action: PayloadAction<
    {
      type: keyof IntegrationsListState;
      key: string;
      value: Partial<Integration>;
    },
    string
  >
) => {
  state[action.payload.type].forEach((integration, index) => {
    if (integration.key === action.payload.key) {
      state[action.payload.type][index] = {
        ...integration,
        ...action.payload.value,
      } as Integration;
    }
  });
};
