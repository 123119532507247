import React from 'react';
import Alert from '@mui/material/Alert';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';

import IntegrationConfigurationLayout from 'pages/integrations/IntegrationConfigurationLayout';
import MergeCredentialsForm from '../forms/MergeCredentialsForm';

import {
  useHandleOnConnectButtonClick,
  useConfigureMergeSettingsPage,
} from './hooks';

import { AlertContainerSx } from 'pages/integrations/Integrations/styles';
import { ContainerSx } from './styles';
import { MergeCredentialsFormFields } from '../forms/MergeCredentialsForm/types';

const MergeConnect = () => {
  const form = useForm<MergeCredentialsFormFields>();
  const {
    onSuccess,
    onExit,
    onValidationError,
    showAlertBanner,
    linkToken,
    organization,
    user,
    postSwapAccountTokenLoading,
    isFormValid,
  } = useConfigureMergeSettingsPage(form);

  const config = React.useMemo(
    () => ({
      onSuccess,
      onExit,
      onValidationError,
      linkToken,
    }),
    [linkToken, postSwapAccountTokenLoading]
  );
  const { open, isReady } = useMergeLink(config);

  const handleOnConnectButtonClick = useHandleOnConnectButtonClick(
    form,
    isReady,
    open
  );

  return (
    <Stack sx={ContainerSx}>
      <IntegrationConfigurationLayout
        type="connect"
        title="Connect HR, Payroll and SCIM Directory"
        handleOnPrimaryButtonClick={handleOnConnectButtonClick}
        primaryButtonCopy="Connect HRIS"
        primaryButtonDisabled={
          !isFormValid || !organization || !user || showAlertBanner || !isReady
        }
      >
        {showAlertBanner ? (
          <Stack sx={AlertContainerSx}>
            <Alert severity="error">
              You do not have permission to configure integrations
            </Alert>
          </Stack>
        ) : null}
        <MergeCredentialsForm form={form} type="connect" />
      </IntegrationConfigurationLayout>
    </Stack>
  );
};

export default MergeConnect;
