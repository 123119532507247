export const ContainerSx = {
  fontWeight: 'bold',
};

export const LinkSx = {
  cursor: 'pointer',
  userSelect: 'none',
};

export const ActivePageSx = {
  color: 'lightgray',
  userSelect: 'none',
};

export const IconSx = {
  fontSize: '12px',
};
