import { SxProps } from '@mui/material';
import { DRAWER_WIDTH } from 'global-styles';

export const MainEmployeesContainerSx: SxProps = {
  boxSizing: 'border-box',
  paddingTop: '2rem',
  margin: 'auto',
  position: 'relative',
  '@media (max-width: 1199px)': {
    left: '0',
  },
  '@media (min-width: 1200px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    width: '80%',
  },
  '@media (min-width: 1536px)': {
    left: `${DRAWER_WIDTH - DRAWER_WIDTH / 2}px`,
    maxWidth: '100%',
    width: '85%',
  },
};

export const EmployeeHeaderStackSx: SxProps = {
  flexWrap: 'nowrap',
  paddingTop: '1rem',
  marginTop: '3.25rem',
  position: 'relative',
};

export const EmployeeHeaderTextSx: SxProps = {
  fontSize: '2rem',
  fontWeight: '500',
  lineHeight: '41.04px',
};

export const EmployeeSubHeaderTextSx: SxProps = {
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '20px',
};

export const SyncSubHeaderTextSx: SxProps = {
  fontSize: '14px',
  color: 'gray',
  fontWeight: '300',
  '& a': {
    color: 'gray',
    textDecoration: 'underline',
  },
};

export const NewEmployeeButtonSx: SxProps = {
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '20px',
};

export const DataGridBoxSx: SxProps = {
  height: 'calc(100vh - 240px)',
};
