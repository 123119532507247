import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { ExternalLinksListProps } from './types';
import {
  ExternalLinksListSx,
  ExternalLinkItemSx,
  ExternalLinkTextSx,
  ExternalLinkItemIconButtonSx,
} from './styles';
import { getHostname } from '../../utils';

const ExternalLinksList: React.FC<ExternalLinksListProps> = ({
  externalLinks,
  showAddLinkButton,
  onDeleteLink,
  onAddLink,
}) => {
  return (
    <List sx={ExternalLinksListSx}>
      {externalLinks.map((link, index) => (
        <ListItem key={index} sx={ExternalLinkItemSx(false)} disablePadding>
          <Link
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            color="secondary"
            sx={ExternalLinkTextSx}
            title={link}
          >
            {getHostname(link)}
          </Link>
          <IconButton
            edge="end"
            aria-label="delete"
            color="secondary"
            onClick={() => onDeleteLink(link)}
            size="small"
            sx={ExternalLinkItemIconButtonSx}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </ListItem>
      ))}
      {showAddLinkButton && (
        <ListItem
          key={externalLinks.length}
          sx={ExternalLinkItemSx(true)}
          disablePadding
        >
          <IconButton
            color="secondary"
            aria-label="add"
            onClick={onAddLink}
            sx={ExternalLinkItemIconButtonSx}
          >
            <AddIcon />
          </IconButton>
        </ListItem>
      )}
    </List>
  );
};

export default ExternalLinksList;
