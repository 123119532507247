import { SxProps } from '@mui/material';
import theme from 'themes/theme';

export const ExternalLinksInputContainerSx: SxProps = {
  gap: 0,
  height: '30px',
  width: '380px',
};

export const ExternalLinksTextFieldSx: SxProps = {
  p: '0px',
  width: '100%',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  backgroundColor: theme.palette.colors.white,
  height: 'calc(100% + 12px)',
  border: '1px solid #E0E0E0',
  borderRadius: '10px',
  boxSizing: 'border-box',

  '& .MuiFormLabel-root': {
    left: '5px',
    '&.Mui-focused': {
      color: 'inherit',
      opacity: '.7',
    },
  },

  '& .MuiInputBase-input': {
    color: theme.palette.colors.gray60,
  },

  '& .MuiInputBase-root': {
    height: '100%',
    '&:hover': {
      backgroundColor: theme.palette.colors.white,
    },

    '&.Mui-focused': {
      backgroundColor: theme.palette.colors.white,
    },
  },

  '& .MuiFormHelperText-root': {
    marginTop: '0px',
  },
};

export const ButtonBaseSx = {
  height: '100%',
  backgroundColor: theme.palette.colors.white,
  border: 'none',
  px: '0',

  '&.MuiButtonBase-root': {
    fontSize: '.875rem',
    width: '4rem',
    minWidth: '2rem',
    borderRadius: '10px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

export const SubmitButtonSx = {
  ...ButtonBaseSx,
  marginTop: '8px',
  color: theme.palette.colors.gray60,
  '&.MuiLoadingButton-root': {
    backgroundColor: theme.palette.colors.white,
  },
};

export const CancelButtonSx: SxProps = {
  ...ButtonBaseSx,
  marginTop: '8px',
  color: theme.palette.colors.gray60,
};
