/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import {
  GridPaginationModel,
  GridRowParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  DEFAULT_PAGINATION_MODEL,
  GENERIC_PAGE_SIZE_OPTIONS,
} from 'global-constants';
import DataGrid from 'components/DataGrid';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { useGetOrderManagementColumns } from 'pages/OrderManagement/hooks/useGetOrderManagementColumns';
import { selectOMTabView } from 'store/slices/order_management/tabs/selectors';
import { selectOMSearchState } from 'store/slices/order_management/search/selectors';
import { Order } from 'types';
import { UPDATE_ORDER_MANAGER_ON_ORDER } from 'pages/OrderManagement/mutations';
import { selectAllChosenEntries } from 'store/shared/selectors';
import { selectOMDetailsSelectedOrder } from 'store/slices/order_management/details/selectors';
import { OrderView } from 'pages/OrderManagement/enum';
import { FILTER_STATE_KEYS } from 'store/constants';
import {
  getOMWhereClauseFromFilterEntries,
  getOMWhereClauseFromComboSearch,
  getOMTabsWhereClauses,
} from './utils';
import {
  useClearSelfAssignSwitch,
  useClearOMOrderStatusEffect,
  useClearOMOrderTypeEffect,
  useDisableOrderStatus,
  useEnableOrderStatus,
  useEnableOrderTypeFilter,
  useDisableOrderTypeFilter,
  useResetComboSearch,
  useGetOMOrderTableSort,
  useGetTimelySelectedOrderInfo,
  useHandleColumnHeaderDragAndDrop,
  useColumnVisibilityModel,
} from './hooks';
import { getV2FindAndCountOrders } from 'api/apollo/queries/v2';
import { V2_FIND_AND_COUNT_ORDERS_OUTPUT } from 'pages/OrderManagement/filters/shared/constants';
import * as OMOrdersTableStyles from './styles';
import OMOrdersTableProps from './types';
import useGetSearchOrderManagers from 'pages/OrderManagement/hooks/useGetSearchOrderMangers';

const OMOrdersTable = (props: OMOrdersTableProps) => {
  const styles = OMOrdersTableStyles;
  const { managersByRole, loadingManagers } = useGetSearchOrderManagers();

  const apiRef = useGridApiRef();

  const getColumnVisibilityModel = useColumnVisibilityModel();
  const columnVisibilityModel = getColumnVisibilityModel();

  useHandleColumnHeaderDragAndDrop(apiRef);

  const selectedOrder = useSelector(selectOMDetailsSelectedOrder);
  const currentTab = useSelector(selectOMTabView);
  const selectedFilterEntries = useSelector(
    selectAllChosenEntries(FILTER_STATE_KEYS.ORDER_MANAGEMENT)
  );

  const { orderSortModel, handleSortModelChange } = useGetOMOrderTableSort();

  const {
    orderManagerFilter,
    lookAheadOptionType,
    lookAheadOption,
    isLoadingOrderManagers,
  } = useSelector(selectOMSearchState);

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);

  const { getSelectedOrders } = useGetTimelySelectedOrderInfo();

  const openOrderDetails = (chosenOrder: Order) => {
    getSelectedOrders(chosenOrder.id);
  };

  const handleOnRowClick = (a: GridRowParams) => {
    const {
      orderType: { name },
    } = a.row as Order;
    openOrderDetails(a.row as Order);
  };

  const handleOnPaginationModelChange = (model: GridPaginationModel) => {
    if (model.pageSize !== paginationModel.pageSize) {
      setPaginationModel({ ...model, page: 0 });
    } else {
      setPaginationModel(model);
    }
  };

  const whereClauseOptions = {
    orderManagerFilter,
    orderTypeLookup: props.orderTypeLookup,
    tab: currentTab,
  };

  const whereClausesFromTab = getOMTabsWhereClauses(
    props.orderTypeLookup,
    currentTab
  );

  const complexWhereClause = getOMWhereClauseFromFilterEntries(
    selectedFilterEntries,
    whereClauseOptions
  );

  const lookAheadWhereClause = getOMWhereClauseFromComboSearch(
    lookAheadOptionType,
    lookAheadOption
  );

  const composedWhereClause = [
    ...whereClausesFromTab,
    ...complexWhereClause,
    lookAheadWhereClause,
  ];

  const tabShouldDisableOrderStatus =
    currentTab === OrderView.ALL_NEW_ORDERS ||
    currentTab === OrderView.CANCELLED ||
    currentTab === OrderView.EXPIRED;

  const tabShouldDisableOrderType =
    currentTab === OrderView.PROCUREMENT ||
    currentTab === OrderView.RETRIEVALS ||
    currentTab === OrderView.REDEPLOYMENT;

  const filterOMDashV2Variables = {
    where: { AND: composedWhereClause },
    orderBy: orderSortModel,
    take: paginationModel.pageSize,
    skip: paginationModel.page * paginationModel.pageSize,
  };

  const { data: newQueryData, loading: searchNewOrdersLoading } = useSafeQuery(
    getV2FindAndCountOrders(V2_FIND_AND_COUNT_ORDERS_OUTPUT),
    {
      variables: filterOMDashV2Variables,
      onCompleted(data) {
        const getPathOrders =
          data?.v2FindAndCountOrders?.orders ?? ([] as Order[]);

        if (selectedOrder) {
          getPathOrders.forEach((order: Order) => {
            if (order.id === selectedOrder.id) {
              openOrderDetails(order);
            }
          });
        }
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    }
  );

  const [mutate, { loading: updateOrderLoading }] = useSafeMutation(
    UPDATE_ORDER_MANAGER_ON_ORDER
  );

  const rows = newQueryData?.v2FindAndCountOrders?.orders ?? ([] as Order[]);

  const rowCount = newQueryData?.v2FindAndCountOrders?.count ?? 0;

  const columns = useGetOrderManagementColumns(mutate, managersByRole);

  useClearSelfAssignSwitch();
  useClearOMOrderStatusEffect(tabShouldDisableOrderStatus);
  useDisableOrderStatus(tabShouldDisableOrderStatus);
  useEnableOrderStatus(tabShouldDisableOrderStatus);
  useClearOMOrderTypeEffect(tabShouldDisableOrderType);
  useEnableOrderTypeFilter(tabShouldDisableOrderType);
  useDisableOrderTypeFilter(tabShouldDisableOrderType);
  useResetComboSearch();

  React.useEffect(() => {
    setPaginationModel({ ...paginationModel, page: 0 });
  }, [currentTab]);

  const showTableLoadingIndicator =
    loadingManagers ||
    updateOrderLoading ||
    isLoadingOrderManagers ||
    searchNewOrdersLoading;

  return (
    <Box sx={styles.DataGridBoxSx}>
      <DataGrid
        apiRef={apiRef}
        data-component-name="OMOrdersTable"
        rows={rows}
        rowCount={rowCount}
        columns={columns}
        autoHeight={false}
        rowSelection={false}
        pagination
        paginationMode="server"
        sortingMode="server"
        disableColumnSelector
        paginationModel={paginationModel}
        onPaginationModelChange={handleOnPaginationModelChange}
        onSortModelChange={handleSortModelChange}
        loading={showTableLoadingIndicator}
        pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
        onRowClick={handleOnRowClick}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          columns: {
            columnVisibilityModel,
          },
        }}
      />
    </Box>
  );
};

export default OMOrdersTable;
