import { createSlice } from '@reduxjs/toolkit';
import { GlobalState } from './types';
import {
  setAuthTokenReducer,
  setAuthErrorCodeReducer,
  setHasSegmentIdentifiedReducer,
  setGlobalNotificationBannerReducer,
  setGlobalAvatarPopoverReducer,
  setGlobalAuth0CollaboratorReducer,
  setGlobalLocationReducer,
  getCollaboratorFulfilledReducer,
  getCollaboratorPendingReducer,
  getCollaboratorRejectedReducer,
  getAuth0OrganizationFulfilledReducer,
  getAuth0OrganizationPendingReducer,
  getAuth0OrganizationRejectedReducer,
  setGlobalGodModeOrganizationReducer,
  setGlobalGodModeUserReducer,
  getGodModeOrganizationPendingReducer,
  getGodModeOrganizationFulfilledReducer,
  getGodModeOrganizationRejectedReducer,
} from './reducers';
import { setLocalStorageValue } from 'api/storage';
import {
  getCredentialedCollaborator,
  getAuth0Organization,
  getGodModeOrganization,
} from './actions';

const initialState: GlobalState = {
  authToken: 'initial',
  authErrorCode: 0,
  globalLocation: {
    pathname: '/',
    search: '',
  },
  globalNotificationBanner: {
    show: false,
    content: null,
  },
  globalAvatarPopover: {
    show: false,
  },
  globalAuth0Collaborator: undefined,
  globalGodModeOrganization: undefined,
  hasSegmentIdentified: false,
};

export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState: () => {
    setLocalStorageValue('authToken', 'initial');
    return initialState;
  },
  reducers: {
    setAuthToken: setAuthTokenReducer,
    setAuthErrorCode: setAuthErrorCodeReducer,
    setGlobalAuth0Collaborator: setGlobalAuth0CollaboratorReducer,
    setGlobalGodModeOrganization: setGlobalGodModeOrganizationReducer,
    setGlobalGodModeUser: setGlobalGodModeUserReducer,
    setGlobalNotificationBanner: setGlobalNotificationBannerReducer,
    setGlobalAvatarPopover: setGlobalAvatarPopoverReducer,
    setGlobalLocation: setGlobalLocationReducer,
    setHasSegmentIdentified: setHasSegmentIdentifiedReducer,
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCredentialedCollaborator.pending,
      getCollaboratorPendingReducer
    );
    builder.addCase(
      getCredentialedCollaborator.fulfilled,
      getCollaboratorFulfilledReducer
    );
    builder.addCase(
      getCredentialedCollaborator.rejected,
      getCollaboratorRejectedReducer
    );

    builder.addCase(
      getAuth0Organization.pending,
      getAuth0OrganizationPendingReducer
    );
    builder.addCase(
      getAuth0Organization.fulfilled,
      getAuth0OrganizationFulfilledReducer
    );
    builder.addCase(
      getAuth0Organization.rejected,
      getAuth0OrganizationRejectedReducer
    );

    builder.addCase(
      getGodModeOrganization.pending,
      getGodModeOrganizationPendingReducer
    );
    builder.addCase(
      getGodModeOrganization.fulfilled,
      getGodModeOrganizationFulfilledReducer
    );
    builder.addCase(
      getGodModeOrganization.rejected,
      getGodModeOrganizationRejectedReducer
    );
  },
});

export const {
  setAuthToken,
  setAuthErrorCode,
  setGlobalNotificationBanner,
  setGlobalAvatarPopover,
  setGlobalAuth0Collaborator,
  setHasSegmentIdentified,
  setGlobalLocation,
  setGlobalGodModeOrganization,
  setGlobalGodModeUser,
} = globalSlice.actions;
