import React from 'react';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Typography,
} from '@mui/material';
import { PreferencesRadioGroupProps } from './types';
import { radioGroupBoxSx } from './styles';

const PreferencesRadioGroup = (props: PreferencesRadioGroupProps) => {
  const {
    value,
    handleChange,
    overrideSx,
    radioColorOverride,
    trueLabelOverride,
    falseLabelOverride,
    prompt,
  } = props;
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.value) {
      case 'true':
        handleChange(true);
        break;
      case 'false':
        handleChange(false);
        break;
      default:
        handleChange(null);
    }
  };

  const radioColor = radioColorOverride || 'default';

  return (
    <Box sx={radioGroupBoxSx(overrideSx)}>
      <Typography variant="body2" id="preferences-radio-group">
        {prompt}
      </Typography>
      <RadioGroup
        row
        value={value}
        onChange={handleRadioChange}
        aria-labelledby="preferences-radio-group"
      >
        <FormControlLabel
          value="true"
          control={<Radio color={radioColor} />}
          label={trueLabelOverride || 'Yes'}
        />
        <FormControlLabel
          value="false"
          control={<Radio color={radioColor} />}
          label={falseLabelOverride || 'No'}
        />
      </RadioGroup>
    </Box>
  );
};

export default PreferencesRadioGroup;
