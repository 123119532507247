import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  PRIMARY_ADMIN_PERMISSIONS,
  GOD_MODE_PERMISSIONS,
} from 'globals/constants/permissions';

import { getJamfConnection } from 'store/slices/integrations/jamf/connect/actions';
import { AppDispatch } from 'store';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import { selectJamfConnection } from 'store/slices/integrations/jamf/connect/selectors';
import { setIntegrations } from 'store/slices/integrations/list';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { UserPermissions } from 'global-constants';
import { getShouldShowAlertBanner } from './utils';
import { resetState as resetJamfConnectionState } from 'store/slices/integrations/jamf/connect';
import { selectMergeConnection } from 'store/slices/integrations/merge/connect/selectors';
import { getMergeConnection } from 'store/slices/integrations/merge/connect/actions';
import { resetState as resetMergeConnectionState } from 'store/slices/integrations/merge/connect';
import { selectGlobalGodModeOrganization , selectGlobalGodModeUser } from 'store/slices/global/selectors';

export const useInitializeJamfIntegration = () => {
  const dispatch = useDispatch<AppDispatch>();
  const connection = useSelector(selectJamfConnection);
  const organization = useGetUserOrganization();
  const userCanConfigureIntegrations = useCanUserConfigureIntegrations();
  const user = useGetGlobalPurchaser();
  const userPermissions = useGetUserPermissions();

  React.useEffect(() => {
    if (!user || !organization) {
      dispatch(resetJamfConnectionState());
    }
  }, [user, organization]);

  React.useEffect(() => {
    const integrationsVisible = userPermissions.includes(
      UserPermissions.INTEGRATIONS_BETA
    );
    let copy = 'Connect';
    if (connection.clientId) {
      copy = 'Manage';
    }
    if (!integrationsVisible) {
      copy = 'Request Access';
    }
    if (user && organization && connection) {
      const showAlertBanner = getShouldShowAlertBanner(
        user,
        organization,
        connection.getLoading,
        userCanConfigureIntegrations
      );
      dispatch(
        setIntegrations({
          type: 'enabled',
          key: 'jamf',
          value: {
            disabled:
              showAlertBanner ||
              connection.getLoading === 'pending' ||
              !user?.id ||
              !organization?.id,
            connected: !!connection.clientId,
            copy,
            loading: connection.getLoading === 'pending',
          },
        })
      );
    } else {
      dispatch(
        setIntegrations({
          type: 'enabled',
          key: 'jamf',
          value: {
            disabled: true,
            connected: false,
            copy: 'Connect',
            loading: false,
          },
        })
      );
    }
  }, [connection, organization, user, userPermissions]);
};

export const useInitializeMergeIntegration = () => {
  const dispatch = useDispatch<AppDispatch>();
  const connection = useSelector(selectMergeConnection);
  const organization = useGetUserOrganization();
  const userCanConfigureIntegrations = useCanUserConfigureIntegrations();
  const user = useGetGlobalPurchaser();

  React.useEffect(() => {
    if (!user || !organization) {
      dispatch(resetMergeConnectionState());
    }
  }, [user, organization]);

  React.useEffect(() => {
    if (user && organization && connection) {
      const showAlertBanner = getShouldShowAlertBanner(
        user,
        organization,
        connection.getConnectionLoading,
        userCanConfigureIntegrations
      );
      dispatch(
        setIntegrations({
          type: 'enabled',
          key: 'merge',
          value: {
            disabled:
              showAlertBanner ||
              connection.getConnectionLoading === 'pending' ||
              !user?.id ||
              !organization?.id,
            connected: !!connection.endUserEmailAddress,
            copy: connection.endUserEmailAddress ? 'Manage' : 'Connect',
            loading: connection.getConnectionLoading === 'pending',
          },
        })
      );
    } else {
      dispatch(
        setIntegrations({
          type: 'enabled',
          key: 'merge',
          value: {
            disabled: true,
            connected: false,
            copy: 'Connect',
            loading: false,
          },
        })
      );
    }
  }, [connection, organization, user]);
};

export const useInitializeIntegrations = () => {
  useInitializeJamfIntegration();
  useInitializeMergeIntegration();
};

export const useGetJamfConnection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();
  const user = useGetGlobalPurchaser();

  React.useEffect(() => {
    if (organization?.id && user?.id) {
      dispatch(getJamfConnection({ orgId: organization?.id }));
    }
  }, [organization?.id, user?.id]);
};

export const useGetMergeConnection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useSelector(selectGlobalGodModeOrganization);
  const user = useSelector(selectGlobalGodModeUser);

  React.useEffect(() => {
    if (organization?.id && user?.id) {
      dispatch(getMergeConnection({ orgId: organization?.id }));
    }
  }, [organization?.id, user?.id]);
};

export const useCanUserConfigureIntegrations = () => {
  let canViewIntegrations = true;
  const userPermissions = useGetUserPermissions();
  const isPrimary = PRIMARY_ADMIN_PERMISSIONS?.every((permission) =>
    userPermissions?.includes(permission as UserPermissions)
  );
  const isGodMode = GOD_MODE_PERMISSIONS?.every((permission) =>
    userPermissions?.includes(permission as UserPermissions)
  );
  if (isPrimary || isGodMode) {
    canViewIntegrations = true;
  } else if (userPermissions?.[0] === 'not.loaded') {
    canViewIntegrations = true;
  } else if (!isPrimary && !isGodMode) {
    canViewIntegrations = false;
  }
  return canViewIntegrations;
};
