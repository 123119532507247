import { createSlice } from '@reduxjs/toolkit';

import {
  setColumnsOpenReducer,
  setColumnsChangedReducer,
  setColumnsReducer,
  setAllColumnsReducer,
  swapColumnsReducer,
  setAllColumnsActiveReducer,
  setSearchInputReducer,
  setInitializedReducer,
  resetStateReducer,
} from './reducers';

import { ColumnState } from './types';

export const columnsSlice = <T extends string>(
  name: string,
  initialState: () => ColumnState<T>
) =>
  createSlice({
    name,
    initialState: initialState(),
    reducers: {
      setColumnsOpenReducer,
      setColumnsChangedReducer,
      setColumnsReducer,
      setAllColumnsReducer,
      swapColumnsReducer,
      setAllColumnsActiveReducer,
      setSearchInputReducer,
      setInitializedReducer,
      resetStateReducer,
    },
  });
