export const InfoSectionSubHeaderSx = {
  fontSize: '14px',
  color: '#666',
  display: 'inline-block',
  '& a': {
    color: 'gray',
    textDecoration: 'underline',
    '&:hover': {
      color: 'black',
    },
  },
};
