import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import NewModal from 'components/NewModal';
import Button from 'components/Button';

import LoadingButton from '@mui/lab/LoadingButton';

import {
  OuterStackPropsSx,
  PaperPropsSx,
  ContainerStackSx,
  SecondaryButtonSx,
  PrimaryButtonSx,
} from './styles';

import { ConfirmationModalProps } from './types';

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    open,
    showCloseButtonTopRight,
    handleClose,
    handleSubmit,
    title,
    subtitle,
    submitButtonCopy,
    submitButtonLoading,
    submitButtonDisabled,
    cancelButtonCopy,
    cancelButtonLoading,
    cancelButtonDisabled,
  } = props;
  return (
    <NewModal
      form
      open={open}
      title={title}
      subtitle={subtitle}
      handleClose={handleClose}
      hideCTAs
      onSubmit={handleSubmit}
      submitButtonCopy={submitButtonCopy}
      cancelButtonCopy={cancelButtonCopy}
      paperPropsSx={PaperPropsSx}
      outerStackPropsSx={OuterStackPropsSx}
      showCloseButtonTopRight={showCloseButtonTopRight}
    >
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
        sx={ContainerStackSx}
      >
        <Button
          gaContext={{
            navigates_to: 'N/A',
            textCopy: '',
            purpose: '',
          }}
          loading={cancelButtonLoading}
          disabled={cancelButtonDisabled}
          color="secondary"
          onClick={handleClose}
          sx={SecondaryButtonSx}
        >
          <Typography variant="button">{cancelButtonCopy}</Typography>
        </Button>
        <LoadingButton
          loading={submitButtonLoading}
          disabled={submitButtonDisabled}
          sx={PrimaryButtonSx}
          onClick={handleSubmit}
          variant="contained"
        >
          <Typography variant="button">{submitButtonCopy}</Typography>
        </LoadingButton>
      </Stack>
    </NewModal>
  );
};

export default ConfirmationModal;
