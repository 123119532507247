import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

import IntegrationConfigurationLayout from 'pages/integrations/IntegrationConfigurationLayout';
import JamfCredentialsForm from '../forms/JamfCredentialsForm';
import { JamfCredentialsFormFields } from '../forms/JamfCredentialsForm/types';
import {
  useGetJamfConnection,
  useCanUserConfigureIntegrations,
} from '../../Integrations/hooks';
import { selectCredentialsView } from 'store/slices/integrations/jamf/settings/selectors';
import { getShouldShowAlertBanner } from 'pages/integrations/Integrations/utils';
import { selectJamfConnection } from 'store/slices/integrations/jamf/connect/selectors';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import { AlertContainerSx } from 'pages/integrations/Integrations/styles';

import {
  useSetFormValues,
  useGetHandleOnButtonClick,
  useHandleErrorNotifications,
  useHandleSuccessNotifications,
} from './hooks';
import { ContainerSx } from './styles';

const JamfSettings = () => {
  useHandleErrorNotifications();
  useHandleSuccessNotifications();
  useGetJamfConnection();

  const form = useForm<JamfCredentialsFormFields>();
  const handleOnButtonClick = useGetHandleOnButtonClick(form);
  const credentialsView = useSelector(selectCredentialsView);
  useSetFormValues(form);
  const connection = useSelector(selectJamfConnection);
  const organization = useGetUserOrganization();
  const user = useGetGlobalPurchaser();

  const userCanConfigureIntegrations = useCanUserConfigureIntegrations();
  const showAlertBanner = getShouldShowAlertBanner(
    user,
    organization,
    connection.getLoading,
    userCanConfigureIntegrations
  );

  return (
    <Stack sx={ContainerSx} data-testid="jamf-settings-page">
      <IntegrationConfigurationLayout
        title="Jamf Settings"
        type="settings"
        handleOnPrimaryButtonClick={handleOnButtonClick}
        primaryButtonCopy="Update"
        primaryButtonDisabled={
          !form.formState.isValid || credentialsView === 'read'
        }
        data-testid="jamf-settings-layout"
      >
        {showAlertBanner ? (
          <Stack
            sx={AlertContainerSx}
            data-testid="jamf-settings-permission-alert"
          >
            <Alert severity="error">
              You do not have permission to configure integrations
            </Alert>
          </Stack>
        ) : null}
        <JamfCredentialsForm
          form={form}
          type="settings"
          data-testid="jamf-settings-credentials-form"
        />
      </IntegrationConfigurationLayout>
    </Stack>
  );
};

export default JamfSettings;
