import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CopyIcon from '@mui/icons-material/ContentCopy';
import TextIconButton from 'components/TextIconButton';
import CopyButtonProps from './types';

export const CopyButton = ({
  copyValue,
  buttonText,
  buttonSx,
  ...restOfProps
}: CopyButtonProps) => {
  const [isCopied, setIsCopied] = React.useState(false);

  const toggleCopied = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(copyValue).then(() => toggleCopied());
  };

  const tooltip = isCopied ? 'Copied!' : 'Copy';

  return !copyValue?.length ? null : (
    <Tooltip title={tooltip} placement="top" arrow>
      {buttonText ? (
        <TextIconButton
          text={buttonText}
          onClick={handleCopy}
          ariaLabel="copy-to-clipboard"
          icon={<CopyIcon />}
          buttonStyles={{ ...buttonSx }}
          {...restOfProps}
        />
      ) : (
        <IconButton size="small" onClick={handleCopy} sx={{ ...buttonSx }}>
          <CopyIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};

export default CopyButton;
