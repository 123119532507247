import { WritableDraft } from 'immer/dist/immer';
import { MergeConnectionState } from './types';
import {
  getMergeConnection,
  postMergeCreateLinkToken,
  postMergeSwapAccountToken,
  deleteMergeConnection,
} from './actions';
import { INITIAL_STATE } from './constants';

export const getMergeConnectionPendingReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof getMergeConnection.pending>
) => {
  state.getConnectionLoading = 'pending';
  state.getConnectionErrorMessage = undefined;
};

export const getMergeConnectionFulfilledReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof getMergeConnection.fulfilled>
) => {
  state.getConnectionLoading = 'fulfilled';
  state.getConnectionErrorMessage = undefined;
  state.category = action.payload.category;
  state.endUserEmailAddress = action.payload.endUserEmailAddress;
  state.integration = action.payload.integration;
  state.status = action.payload.status;
  state.id = action.payload.id;
  state.integrationSlug = action.payload.integrationSlug;
  state.endUserOriginId = action.payload.endUserOriginId;
  state.endUserOrganizationName = action.payload.endUserOrganizationName;
  state.webhookListenerUrl = action.payload.webhookListenerUrl;
  state.accountType = action.payload.accountType;
};

export const getMergeConnectionRejectedReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof getMergeConnection.rejected>
) => {
  return INITIAL_STATE({
    getConnectionLoading: 'rejected',
    getConnectionErrorMessage: action.error.message,
  });
};

export const postMergeCreateLinkTokenPendingReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof postMergeCreateLinkToken.pending>
) => {
  state.postCreateLinkTokenLoading = 'pending';
  state.postCreateLinkTokenErrorMessage = undefined;
};

export const postMergeCreateLinkTokenFulfilledReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof postMergeCreateLinkToken.fulfilled>
) => {
  state.postCreateLinkTokenLoading = 'fulfilled';
  state.postCreateLinkTokenErrorMessage = undefined;
  state.linkToken = action.payload.linkToken;
  state.magicLinkUrl = action.payload.magicLinkUrl;
};

export const postMergeCreateLinkTokenRejectedReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof postMergeCreateLinkToken.rejected>
) => {
  state.postCreateLinkTokenLoading = 'rejected';
  state.postCreateLinkTokenErrorMessage = action.error.message;
  state.linkToken = '';
  state.magicLinkUrl = '';
};

export const postMergeSwapAccountTokenPendingReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof postMergeSwapAccountToken.pending>
) => {
  state.postSwapAccountTokenLoading = 'pending';
  state.postSwapAccountTokenErrorMessage = undefined;
};

export const postMergeSwapAccountTokenFulfilledReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof postMergeSwapAccountToken.fulfilled>
) => {
  state.postSwapAccountTokenLoading = 'fulfilled';
  state.postSwapAccountTokenErrorMessage = undefined;
};

export const postMergeSwapAccountTokenRejectedReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof postMergeSwapAccountToken.rejected>
) => {
  state.postSwapAccountTokenLoading = 'rejected';
  state.postSwapAccountTokenErrorMessage = action.error.message;
};

export const deleteMergeConnectionPendingReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof deleteMergeConnection.pending>
) => {
  state.deleteConnectionLoading = 'pending';
  state.deleteConnectionErrorMessage = undefined;
};

export const deleteMergeConnectionFulfilledReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof deleteMergeConnection.fulfilled>
) => {
  return INITIAL_STATE();
};

export const deleteMergeConnectionRejectedReducer = (
  state: WritableDraft<MergeConnectionState>,
  action: ReturnType<typeof deleteMergeConnection.rejected>
) => {
  state.deleteConnectionLoading = 'rejected';
  state.deleteConnectionErrorMessage = action.error.message;
};
