import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { ExternalLinksInputProps } from './types';
import {
  CancelButtonSx,
  ExternalLinksInputContainerSx,
  ExternalLinksTextFieldSx,
  SubmitButtonSx,
} from './styles';
import { ExternalLinksStatus } from '../../constants';
import { IconButton } from '@mui/material';

const ExternalLinksInput: React.FC<ExternalLinksInputProps> = (props) => {
  const {
    onCancel,
    onChange,
    onBlur,
    addLink,
    externalLinksStatus,
    externalLink,
  } = props;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && getSubmitEnabled()) addLink();
  };

  const getSubmitDisabled = (): boolean =>
    externalLinksStatus !== ExternalLinksStatus.SUBMIT_ENABLED;

  const getSubmitEnabled = (): boolean =>
    externalLinksStatus === ExternalLinksStatus.SUBMIT_ENABLED;

  const getSubmitLoading = (): boolean =>
    externalLinksStatus === ExternalLinksStatus.LOADING;

  const getInputError = (): boolean =>
    externalLinksStatus === ExternalLinksStatus.SUBMIT_DISABLED_WITH_ERROR;

  return (
    <Stack direction="row" sx={ExternalLinksInputContainerSx}>
      <TextField
        autoFocus
        id="external-links-text-field"
        value={externalLink}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        placeholder="Enter URL"
        variant="filled"
        multiline={false}
        error={getInputError()}
        helperText={getInputError() ? 'Please enter a valid URL' : ''}
        sx={ExternalLinksTextFieldSx}
      />
      {externalLink ? (
        <LoadingButton
          variant="contained"
          onClick={addLink}
          disabled={getSubmitDisabled()}
          loading={getSubmitLoading()}
          sx={SubmitButtonSx}
        >
          <Typography variant="button">Enter</Typography>
        </LoadingButton>
      ) : (
        <IconButton
          aria-color
          edge="end"
          aria-label="close"
          onClick={onCancel}
          sx={CancelButtonSx}
        >
          Cancel
        </IconButton>
      )}
    </Stack>
  );
};

export default ExternalLinksInput;
