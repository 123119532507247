import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Divider,
  Grid,
  Stack,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { OrderSummaryProps } from './types';
import PeopleBar from '../PeopleBar';
import { Button, Bubble, LoadingIndicator } from 'components';
import {
  ProcurementFlowEmailType,
  ProcurementFlowModals,
  ProcurementFlowStep,
} from 'pages/Procurement/hooks/useProcurementService/constants';
import SelectedCollaboratorsModal from '../ProductCatalog/components/SelectedCollaboratorsModal';
import ProductLineRow from './components/ProductLineRow';
import OrderNotes from './components/OrderNotes';
import ProductTableHeader from './components/ProductTableHeader';
import { shouldShowInternalActions } from './utils';
import { setGlobalState } from 'state';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import InternalActions from './components/InternalActions';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { CheckoutInputDto } from 'services/openapi/apiSchemas';
import { DRAWER_WIDTH } from 'global-styles';
import {
  ShippingType as ObsoleteShippingType,
  OrderType as OrderTypeNames,
} from 'global-constants';
import { GlobalModals } from 'store/constants';
import { modalSlice, notificationSlice } from 'store/slices';
import * as OrderSummaryStyles from './styles';
import { useCreateProcurementToRecipientOrder } from 'pages/Procurement/hooks/useCreateProcurementToRecipientOrder';
import SummaryCard from './components/SummaryCard';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { CREATE_TO_PROCUREMENT_TO_DEPOT_ORDER } from './mutations';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import usePremiumShippingPerOrderType from 'hooks/usePremiumShippingPerOrderType';
import RecipientsDataGrid from './components/RecipientsDataGrid';
import {
  Collaborator,
  HomeAddress,
  Recipient,
  SelectableRecipientAddress,
} from 'types';
import { ShippingInformation } from './components/ShippingInformation';
import {
  ORDER_ITEMS_LABEL,
  PREFERENCES_COLOR_SWAP_PROMPT,
  PREFERENCES_HEADER,
  PREFERENCES_LEAD_TIME_FALSE_SELECTION_LABEL,
  PREFERENCES_LEAD_TIME_PROMPT,
  RECIPIENTS_LABEL,
  SHIPPING_LABEL,
} from './constants';
import AddRecipientAddressModal from 'components/AddRecipientAddressModal';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { isAddressIncomplete } from 'services';
import { DEPOT_FLAG_MAPPING } from 'pages/BuyAndHold/components/DepotView/components/DepotTable/constants';
import NoFlagSVGIcon from 'components/Icons/flags/NoFlagSVGIcon';
import PreferencesRadioGroup from './components/PreferencesRadioGroup';

const OrderSummary = (props: OrderSummaryProps) => {
  const styles = OrderSummaryStyles;
  const organization = useGetUserOrganization();

  const [obsoleteShippingType] = React.useState<ObsoleteShippingType>(
    ObsoleteShippingType.STANDARD
  );

  const [shippingTypeId, setShippingTypeId] = React.useState<string>(
    props.workflow.shippingTypeId || ''
  );

  const [sendRecipientAddressForm, setSendRecipientAddressForm] =
    React.useState(true);

  const [useRecipientsAvailableAddress, setUseRecipientsAvailableAddress] =
    React.useState(false);

  const [outboundCarrierService, setOutboundCarrierService] = React.useState<
    CheckoutInputDto['outboundCarrierService'] | null
  >(null);

  const [inboundCarrierService, setInboundCarrierService] = React.useState<
    CheckoutInputDto['inboundCarrierService'] | null
  >(null);

  const [stepperComplete, setStepperComplete] = React.useState(false);

  const [addAddressCollaboratorId, setAddAddressCollaboratorId] =
    React.useState<string>('');

  const [
    recipientAddressFormDataPrepopulate,
    setRecipientAddressFormDataPrepopulate,
  ] = React.useState<SelectableRecipientAddress>(
    {} as SelectableRecipientAddress
  );

  const [openAddressModal, setOpenAddressModal] = React.useState(false);

  const dispatch = useDispatch();

  const workFlowIs = (workFlow: string) => () =>
    props.workflow.orderTypeFlow === workFlow;
  const workFlowIsProcurement = workFlowIs('procurement');
  const workFlowIsBuyAndHold = workFlowIs('buy_and_hold');

  const {
    selectedDepotLocation,
    deviceColorSwapApproved,
    flexibleLeadTimeApproved,
    setDeviceColorSwapApproved,
    setFlexibleLeadTimeApproved,
  } = props.workflow;

  const depotId = selectedDepotLocation?.id || '';
  const depotName = selectedDepotLocation?.displayName || '';
  const FlagIcon =
    DEPOT_FLAG_MAPPING[selectedDepotLocation?.address?.country as string] ||
    NoFlagSVGIcon;

  const currentOrderTypeName = workFlowIsProcurement()
    ? OrderTypeNames.PROCUREMENT_TO_RECIPIENT
    : OrderTypeNames.PROCUREMENT_TO_DEPOT;

  const {
    shippingInfoTable,
    loadingPremiumShippingInfo,
    availableShippingTypeNames,
    hasShippingInfo,
  } = usePremiumShippingPerOrderType(currentOrderTypeName, {
    skip: false,
    debugConsole: false,
  });

  const {
    isFlagOn: isSelectRecipientEmailEnabled,
    isFlagOff: isSelectRecipientEmailDisabled,
    OnFlagFalseWrapper: SelectRecipientEmailDisabledDisplay,
  } = useFeatureFlagService(FeatureFlagNames.SELECT_RECIPIENT_EMAIL, {
    debugFlags: true,
  });

  const {
    isFlagOn: isSelectRecipientAddressEnabled,
    isFlagOff: isSelectRecipientAddressDisabled,
  } = useFeatureFlagService(FeatureFlagNames.SELECT_RECIPIENT_ADDRESS, {
    debugFlags: true,
  });

  const [
    createDepotOrder,
    { loading: isProcurementToDepotOrderCreationLoading },
  ] = useSafeMutation(CREATE_TO_PROCUREMENT_TO_DEPOT_ORDER);

  const userPermissions = useGetUserPermissions();

  const showInternalActions =
    shouldShowInternalActions(userPermissions) && workFlowIsProcurement();

  const containerRef = React.useRef(null);

  const purchaser = useGetGlobalPurchaser();

  const showSelectedCollaboratorsModal =
    props.workflow.currentModal ===
    ProcurementFlowModals.SELECTED_COLLABORATORS_EDIT;

  const numberOfSelectedCollaborators =
    props.workflow.selectedCollaborators.length;

  const handleCloseModal = () => {
    props.workflow.setCurrentModal(ProcurementFlowModals.NONE);
  };

  const onClickBack = () => {
    props.workflow.updateProcurementFlowStep(
      ProcurementFlowStep.PRODUCT_CATALOG
    );
  };

  const useProcurementToRecipientOrderCreation =
    useCreateProcurementToRecipientOrder();

  const submitProcurementToRecipientOrder = () => {
    let collaboratorIds: string[] = [];
    let recipients: Recipient[] = [];

    if (isSelectRecipientEmailEnabled()) {
      recipients = props.workflow.selectedCollaborators.map(
        ({ id, selectedAddress, homeAddresses, workAddresses }) =>
          generateSelectedRecipient(
            id,
            props.workflow.selectedCollaboratorRecipientEmailMap?.[`${id}`],
            selectedAddress,
            [...(homeAddresses || []), ...(workAddresses || [])]
          ) as unknown as Recipient
      );
    } else {
      collaboratorIds = props.workflow.selectedCollaborators.map(
        ({ id }) => id
      );
    }

    const mappedItems = props.workflow.cart.map((cartItem) => {
      const { productVariantId, quantity, protectionPlan } = cartItem;
      return {
        productVariantId,
        quantity,
        ...(protectionPlan && { protectionPlan }),
      };
    });

    useProcurementToRecipientOrderCreation.createOrder({
      variables: {
        purchaserId: purchaser?.id ?? '',
        isRush: obsoleteShippingType === ObsoleteShippingType.US_EXPEDITED,
        ...(props.workflow.checkoutNotes && {
          checkoutNotes: props.workflow.checkoutNotes,
        }),
        ...(collaboratorIds.length > 0 && { collaboratorIds }),
        ...(recipients.length > 0 && { recipients }),
        items: mappedItems,
        sendRecipientForm: sendRecipientAddressForm,
        ...(shippingTypeId ? { shippingTypeId } : {}),
        deviceColorSwapApproved,
        flexibleLeadTimeApproved,
      },
      onCompleted() {
        props.workflow.checkoutOrder();
        props.workflow.removeCheckoutNotes();
        dispatch(
          modalSlice.actions.setCurrentModal(
            GlobalModals.EQUIPMENT_SENT_CONFIRMATION
          )
        );
        props.workflow.reset();
      },
      onError() {
        setGlobalState('alert', {
          message: (
            <p>An error occurred submitting your order. Please try again</p>
          ),
          severity: 'error',
        });
      },
    });
  };

  const generateSelectedRecipient = (
    collaboratorId: string,
    selectedEmail: string,
    selectedAddress: SelectableRecipientAddress,
    existingAddresses: HomeAddress[]
  ) => {
    // TODO: remove feature flag condition when feature flag removed
    if (isSelectRecipientAddressDisabled() && isSelectRecipientEmailDisabled())
      return { id: collaboratorId };

    const address =
      isSelectRecipientAddressEnabled() && selectedAddress
        ? {
            streetAddress1: selectedAddress.streetAddress1,
            streetAddress2: selectedAddress.streetAddress2,
            city: selectedAddress.city,
            state: selectedAddress.state,
            principalRegion: selectedAddress.principalRegion,
            zipCode: selectedAddress.zipCode,
            country: selectedAddress.country,
            isHome: selectedAddress.isHome,
            isWork: selectedAddress.isWork,
          }
        : undefined;

    const addressId = isSelectRecipientAddressEnabled()
      ? existingAddresses.find((address) => address.id === selectedAddress?.id)
          ?.id
      : undefined;

    const emailValue = selectedEmail.split('@work');
    return {
      collaboratorId,
      email: emailValue ? emailValue[0] : selectedEmail,
      addressId,
      address,
      phoneNumber: selectedAddress?.phoneNumber,
      persistInfoToCollaborator: !organization?.hrisEmployeeDataSyncedAt,
    };
  };

  const submitProcurementToDepotOrder = (isQuoteRequested?: boolean) => {
    const mappedItems = props.workflow.cart.map((cartItem) => {
      const { productVariantId, quantity, protectionPlan } = cartItem;
      return {
        productVariantId,
        quantity,
        ...(protectionPlan && { protectionPlan }),
      };
    });

    props.workflow.setQuoteRequested(isQuoteRequested ?? false);

    createDepotOrder({
      variables: {
        depotId,
        purchaserId: purchaser?.id ?? '',
        items: mappedItems,
        isQuoteRequested,
        isRush: obsoleteShippingType === ObsoleteShippingType.US_EXPEDITED,
        ...(props.workflow.checkoutNotes && {
          checkoutNotes: props.workflow.checkoutNotes,
        }),
        ...(shippingTypeId ? { shippingTypeId } : {}),
        deviceColorSwapApproved,
        flexibleLeadTimeApproved,
      },
      onCompleted() {
        props.workflow.checkoutOrder(isQuoteRequested);
        props.workflow.removeCheckoutNotes();
      },
      onError() {
        setGlobalState('alert', {
          message: (
            <p>An error occurred submitting your order. Please try again</p>
          ),
          severity: 'error',
        });
      },
    });
  };

  const onSubmitOrderClick = (isQuoteRequested?: boolean) => {
    if (workFlowIsProcurement()) {
      submitProcurementToRecipientOrder();
    } else {
      submitProcurementToDepotOrder(isQuoteRequested);
    }
  };

  const disabledSubmitButton =
    !purchaser?.id ||
    !shippingTypeId ||
    (isSelectRecipientEmailEnabled() &&
      workFlowIsProcurement() &&
      !stepperComplete) ||
    (workFlowIsBuyAndHold() && !depotId) ||
    (deviceColorSwapApproved ?? null) === null ||
    (flexibleLeadTimeApproved ?? null) === null;

  const handleRadioButtonChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setShippingTypeId(value);
  };

  const initShippingTypeId = () => {
    if (!hasShippingInfo) return;
    const tempInfo = shippingInfoTable[availableShippingTypeNames[0]];
    setShippingTypeId(tempInfo.shippingTypeId);
  };

  React.useEffect(() => {
    initShippingTypeId();
  }, [hasShippingInfo]);

  React.useEffect(() => {
    if (!props.workflow.shippingTypeId) return;

    setShippingTypeId(props.workflow.shippingTypeId);
  }, [props.workflow.shippingTypeId]);

  React.useEffect(() => {
    if (shippingTypeId || props.workflow.shippingTypeId) return;

    initShippingTypeId();
  }, [shippingTypeId, props.workflow.shippingTypeId]);

  React.useEffect(() => {
    if (!shippingTypeId) return;
    props.workflow.updateShippingTypeId(shippingTypeId);
  }, [shippingTypeId]);

  const showLoadingIndicator =
    loadingPremiumShippingInfo ||
    isProcurementToDepotOrderCreationLoading ||
    useProcurementToRecipientOrderCreation.isCreateOrderLoading;

  const steps = [
    {
      label: ORDER_ITEMS_LABEL,
      index: 0,
      nextStep: 1,
      isStepComplete: () =>
        ((deviceColorSwapApproved ?? null) !== null) !== null &&
        (flexibleLeadTimeApproved ?? null) !== null,
    },
    {
      label: RECIPIENTS_LABEL,
      index: 1,
      nextStep: 2,
      isStepComplete: () => true,
    },
    {
      label: SHIPPING_LABEL,
      index: 2,
      nextStep: 3,
      isStepComplete: () => true,
    },
  ];
  const [activeStep, setActiveStep] = React.useState<number | undefined>(0);

  const handleAccordionExpand = (event: any) => {
    if (event.currentTarget?.getAttribute('aria-expanded') === 'true') {
      const stepToExpand = steps.find((step) =>
        event.currentTarget?.getAttribute('class').includes(step.label)
      )?.nextStep;
      setActiveStep(stepToExpand);
    } else {
      const expandedStep = steps.find((step) =>
        event.currentTarget?.getAttribute('class').includes(step.label)
      )?.index;
      if (expandedStep !== undefined) {
        setActiveStep(expandedStep);
      }
    }
  };

  const collaboratorsWithSelectedEmail =
    props.workflow.selectedCollaborators.map((collaborator: Collaborator) => ({
      ...collaborator,
      selectedEmail:
        props.workflow.selectedCollaboratorRecipientEmailMap?.[collaborator.id],
    }));

  const bulkEmailUpdateMessage = (updatedCount: number, total: number) => {
    return `Contact Email has been changed for ${updatedCount} of ${total} recipients`;
  };

  const updateAllSelectedCollaboratorEmails = (
    emailType: ProcurementFlowEmailType
  ): void => {
    const updatedCount = props.workflow.selectedCollaborators.filter(
      (collaborator: Collaborator) => {
        const defaultEmail = collaborator.personalEmail || collaborator.email;
        props.workflow.updateSelectedCollaboratorRecipientEmail({
          collaboratorId: collaborator.id,
          email:
            (emailType === ProcurementFlowEmailType.WORK
              ? collaborator.workEmail
              : collaborator.personalEmail) ?? defaultEmail,
        });
        return (
          (emailType && !!collaborator[`${emailType}`]) ||
          (emailType === ProcurementFlowEmailType.PERSONAL &&
            collaborator.workEmail !== collaborator.email)
        );
      }
    ).length;
    props.workflow.updateEmailTypeForAllCollaborators(emailType);
    if (emailType) {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          horizontalOrigin: 'center',
          noticeContent: bulkEmailUpdateMessage(
            updatedCount,
            props.workflow.selectedCollaborators.length
          ),
        })
      );
    }
  };

  const handleAddressChange = (
    collaboratorId: string,
    address: SelectableRecipientAddress
  ) => {
    if (!address.id) {
      props.workflow.removeSelectedCollaboratorRecipientAddress(collaboratorId);
      return;
    }
    props.workflow.updateSelectedCollaboratorRecipientAddress(
      collaboratorId,
      address
    );
  };

  const handleOpenAddressModal = (
    collaboratorId: string,
    address: SelectableRecipientAddress = {} as SelectableRecipientAddress
  ) => {
    setAddAddressCollaboratorId(collaboratorId);
    setRecipientAddressFormDataPrepopulate(address);
    setOpenAddressModal(true);
  };

  const closeAddressModal = () => {
    setOpenAddressModal(false);
  };

  const onAddRecipientAddress = (address: SelectableRecipientAddress) => {
    const currentEmployeeShippingAddresses =
      props.workflow.selectedCollaborators.find(
        (collaborator) => collaborator.id === addAddressCollaboratorId
      )?.selectableAddresses || [];
    let newShippingAddressOptions;
    const existing = currentEmployeeShippingAddresses.findIndex(
      (existingAddress) => {
        return existingAddress.id === address.id;
      }
    );
    if (existing > -1) {
      newShippingAddressOptions = [
        ...currentEmployeeShippingAddresses.slice(0, existing),
        address,
        ...currentEmployeeShippingAddresses.slice(existing + 1),
      ];
    } else {
      newShippingAddressOptions = [
        ...currentEmployeeShippingAddresses,
        address,
      ];
    }

    props.workflow.addSelectedCollaboratorSelectableAddresses(
      addAddressCollaboratorId,
      newShippingAddressOptions
    );

    props.workflow.updateSelectedCollaboratorRecipientAddress(
      addAddressCollaboratorId,
      address
    );
  };

  const onCancelAddRecipientAddress = () => {
    if (
      isAddressIncomplete(
        props.workflow.selectedCollaborators.find(
          (collaborator) => collaborator.id === addAddressCollaboratorId
        )?.selectedAddress
      )
    ) {
      props.workflow.removeSelectedCollaboratorRecipientAddress(
        addAddressCollaboratorId
      );
      setRecipientAddressFormDataPrepopulate({} as SelectableRecipientAddress);
    }
  };

  React.useEffect(() => {
    setStepperComplete(!!activeStep && activeStep >= steps.length - 1);
  }, [activeStep]);

  const orderitems = () => {
    return (
      <>
        {workFlowIsProcurement() && isSelectRecipientEmailEnabled() && (
          <Typography sx={styles.SectionHeaderSx}>
            Check your order Items
          </Typography>
        )}
        <ProductTableHeader
          isProcurementToRecipient={
            workFlowIsProcurement() && isSelectRecipientEmailEnabled()
          }
        />
        <TransitionGroup component={null} style={{ width: '100%' }}>
          {props.workflow.cart.map((cartItem, index) => (
            <ProductLineRow
              index={index}
              isProcurementToRecipient={
                workFlowIsProcurement() && isSelectRecipientEmailEnabled()
              }
              key={`${cartItem.productVariantId}_${cartItem.insuranceAmount}_${cartItem.protectionPlan}`}
              lineItem={cartItem}
              containerRef={containerRef}
              orderTypeFlow={props.workflow.orderTypeFlow}
              numberOfSelectedCollaborators={numberOfSelectedCollaborators}
              removeProductVariantFromCart={
                props.workflow.removeProductVariantFromCart
              }
              changeQuantityOfProductVariant={
                props.workflow.changeQuantityOfProductVariant
              }
            />
          ))}
        </TransitionGroup>
        {workFlowIsProcurement() && (
          <TransitionGroup component={null} style={{ width: '100%' }}>
            <Typography sx={{ ...styles.SectionHeaderSx, mt: '32px' }}>
              {PREFERENCES_HEADER}
            </Typography>
            <PreferencesRadioGroup
              prompt={PREFERENCES_COLOR_SWAP_PROMPT}
              value={deviceColorSwapApproved}
              handleChange={setDeviceColorSwapApproved}
              radioColorOverride="secondary"
            />
            <PreferencesRadioGroup
              prompt={PREFERENCES_LEAD_TIME_PROMPT}
              value={flexibleLeadTimeApproved}
              handleChange={setFlexibleLeadTimeApproved}
              radioColorOverride="secondary"
              falseLabelOverride={PREFERENCES_LEAD_TIME_FALSE_SELECTION_LABEL}
            />
          </TransitionGroup>
        )}
      </>
    );
  };

  return (
    <>
      <LoadingIndicator loading={showLoadingIndicator} size={125} />
      <Container sx={{ padding: '8px', paddingTop: '3.5rem' }} maxWidth="xl">
        <Stack
          marginTop="2rem"
          marginLeft={{ xs: 'none', lg: `${DRAWER_WIDTH}px` }}
          spacing={1.25}
          alignItems="flex-start"
        >
          <Button
            gaContext={{
              textCopy: 'Back',
              navigates_to: 'Depot View',
              purpose: 'Navigates Back to Depot',
            }}
            variant="text"
            color="secondary"
            startIcon={<ArrowBackIcon />}
            onClick={onClickBack}
            sx={styles.BackButtonSx}
          >
            BACK
          </Button>
          <Typography variant="h4">Review order</Typography>
          <Typography
            variant="body2"
            style={{ marginBottom: '10px' }}
            maxWidth={{ xs: '100%', md: '65%' }}
            flexWrap="wrap"
          >
            Please confirm if the information below is correct. After you submit
            your order, we will send you an update at each step of the way.
          </Typography>
          {workFlowIsProcurement() ? (
            <SelectRecipientEmailDisabledDisplay>
              <PeopleBar
                selectedCollaborators={props.workflow.selectedCollaborators}
                removeSelectedCollaborator={
                  props.workflow.removeSelectedCollaborator
                }
                setCurrentModal={props.workflow.setCurrentModal}
              />
            </SelectRecipientEmailDisabledDisplay>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing="16px"
            >
              <Bubble text="To" color="colors.woodSmoke" />
              <FlagIcon />
              <Typography variant="body2" className="depot-name-text">
                {depotName}
              </Typography>
            </Stack>
          )}
          <Grid container alignItems="flex-start" rowGap="30px">
            <Grid item xs={12} md={8.5} width="100%" pr="18px">
              {(!workFlowIsProcurement() ||
                isSelectRecipientEmailDisabled()) && (
                <>
                  <ShippingInformation
                    shippingTypeId={shippingTypeId}
                    orderType={currentOrderTypeName}
                    obsoleteShippingType={obsoleteShippingType}
                    hasShippingInfo={hasShippingInfo}
                    availableShippingTypeNames={availableShippingTypeNames}
                    shippingInfoTable={shippingInfoTable}
                    onShippingRadioButtonChange={handleRadioButtonChange}
                  />
                  {orderitems()}
                  <OrderNotes
                    checkoutNotes={props.workflow.checkoutNotes}
                    setCheckoutNotes={props.workflow.setCheckoutNotes}
                    isProcurement={false}
                  />
                  <Divider sx={styles.DividerSx} />
                  <TransitionGroup component={null} style={{ width: '100%' }}>
                    <Typography sx={{ ...styles.SectionHeaderSx, mt: '32px' }}>
                      Preferences
                    </Typography>
                    <PreferencesRadioGroup
                      prompt="I approve a color change for an equal specification and price if selected color is unavailable."
                      value={deviceColorSwapApproved}
                      handleChange={setDeviceColorSwapApproved}
                      radioColorOverride="secondary"
                    />
                    <PreferencesRadioGroup
                      prompt="I approve a lead time of up to 6 weeks for custom or backordered items."
                      value={flexibleLeadTimeApproved}
                      handleChange={setFlexibleLeadTimeApproved}
                      radioColorOverride="secondary"
                      falseLabelOverride="No, Please reach out to me with alternatives"
                    />
                  </TransitionGroup>
                </>
              )}
              {workFlowIsProcurement() && isSelectRecipientEmailEnabled() && (
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  sx={styles.StepperSx}
                >
                  {steps.map((step, index) => {
                    const stepActive = activeStep === step.index;
                    return (
                      <Step key={step.label} active={stepActive}>
                        <Accordion
                          square
                          className={`${step.label}-ordersummary-accordion`}
                          expanded={stepActive}
                          sx={styles.AccordionSx}
                        >
                          <AccordionSummary
                            className={`${step.label}-ordersummary-accordion-detail`}
                            disabled={stepActive && !step.isStepComplete()}
                            onClick={handleAccordionExpand}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <StepLabel StepIconComponent={StepIcon}>
                              <Typography
                                variant="h5"
                                sx={styles.StepperStepLabelSx}
                              >
                                {step.label}
                              </Typography>
                            </StepLabel>
                          </AccordionSummary>
                          <AccordionDetails sx={styles.AccordionDetailsSx}>
                            {(() => {
                              if (step.label === ORDER_ITEMS_LABEL) {
                                return orderitems();
                              }
                              if (step.label === RECIPIENTS_LABEL) {
                                return (
                                  <RecipientsDataGrid
                                    collaborators={
                                      collaboratorsWithSelectedEmail
                                    }
                                    orderLevelEmailType={
                                      props.workflow.collaboratorsEmailType
                                    }
                                    handleRemoveRecipient={
                                      props.workflow.removeSelectedCollaborator
                                    }
                                    handleSingleEmailChange={
                                      props.workflow
                                        .updateSelectedCollaboratorRecipientEmail
                                    }
                                    handleAllEmailChange={
                                      updateAllSelectedCollaboratorEmails
                                    }
                                    handleAddressChange={handleAddressChange}
                                    handleAddAddress={handleOpenAddressModal}
                                  />
                                );
                              }
                              return (
                                <ShippingInformation
                                  shippingTypeId={shippingTypeId}
                                  orderType={currentOrderTypeName}
                                  obsoleteShippingType={obsoleteShippingType}
                                  hasShippingInfo={hasShippingInfo}
                                  availableShippingTypeNames={
                                    availableShippingTypeNames
                                  }
                                  shippingInfoTable={shippingInfoTable}
                                  onShippingRadioButtonChange={
                                    handleRadioButtonChange
                                  }
                                />
                              );
                            })()}
                            {index < steps.length - 1 && (
                              <Stack
                                id="accordion-continue-button"
                                direction="row"
                                spacing={0}
                                justifyContent="flex-end"
                                sx={styles.AccordionContinueButtonStackSx}
                              >
                                <Button
                                  gaContext={{
                                    textCopy: 'Continue',
                                    navigates_to: 'Last Step',
                                    purpose: 'Proceeds To Next Step',
                                  }}
                                  id="continue-button"
                                  color="secondary"
                                  disabled={!step.isStepComplete()}
                                  onClick={() => setActiveStep(step.nextStep)}
                                  sx={styles.AccordionContinueButtonSx}
                                >
                                  <Typography variant="button">
                                    Continue
                                  </Typography>
                                </Button>
                              </Stack>
                            )}
                          </AccordionDetails>
                        </Accordion>
                        <Divider />
                      </Step>
                    );
                  })}
                </Stepper>
              )}
              {showInternalActions ? (
                <InternalActions
                  cart={props.workflow.cart}
                  userPermissions={userPermissions}
                  sendRecipientAddressForm={sendRecipientAddressForm}
                  setSendRecipientAddressForm={setSendRecipientAddressForm}
                  useRecipientsAvailableAddress={useRecipientsAvailableAddress}
                  setUseRecipientsAvailableAddress={
                    setUseRecipientsAvailableAddress
                  }
                  outboundCarrierService={outboundCarrierService}
                  setOutboundCarrierService={setOutboundCarrierService}
                  inboundCarrierService={inboundCarrierService}
                  setInboundCarrierService={setInboundCarrierService}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} md={3.5} pl="18px">
              <Stack rowGap="1rem">
                <Typography variant="h5" sx={styles.SummaryTextHeaderSx}>
                  Summary
                </Typography>
                <SummaryCard
                  isBuyAndHoldOrder={workFlowIsBuyAndHold()}
                  shippingType={obsoleteShippingType}
                  cart={props.workflow.cart}
                  numberOfSelectedCollaborators={numberOfSelectedCollaborators}
                />
                {workFlowIsProcurement() && isSelectRecipientEmailEnabled() && (
                  <OrderNotes
                    checkoutNotes={props.workflow.checkoutNotes}
                    setCheckoutNotes={props.workflow.setCheckoutNotes}
                    isProcurement={
                      workFlowIsProcurement() && isSelectRecipientEmailEnabled()
                    }
                  />
                )}
                <Button
                  gaContext={{
                    navigates_to: 'N/A',
                    textCopy: 'Submit Order',
                    purpose: 'Submits An Order',
                  }}
                  disabled={disabledSubmitButton}
                  variant="contained"
                  onClick={() => onSubmitOrderClick(false)}
                  data-testid="submit-order-button-d3FkZ7yJX8F"
                >
                  <Typography
                    variant="button"
                    sx={{
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '24px',
                    }}
                  >
                    Submit order
                  </Typography>
                </Button>
                {workFlowIsBuyAndHold() ? (
                  <>
                    <Divider />
                    <Stack direction="row" justifyContent="space-between">
                      <Stack>
                        <Typography variant="h6">
                          Prefer a quote first?
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '143%',
                            color: 'colors.gray60',
                          }}
                        >
                          Typical quote time for domestic orders is 48 hours.
                        </Typography>
                      </Stack>
                      <Button
                        gaContext={{
                          navigates_to: 'N/A',
                          textCopy: 'Request Quote',
                          purpose: 'Requests An Quote',
                        }}
                        id="request-quote-button"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        sx={{ padding: '6px 16px', height: 'fit-content' }}
                        disabled={disabledSubmitButton}
                        onClick={() => onSubmitOrderClick(true)}
                      >
                        <Typography
                          variant="button"
                          sx={{
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '24px',
                          }}
                        >
                          Request quote
                        </Typography>
                      </Button>
                    </Stack>
                  </>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Container>
      {/* Consider rendering all modals from top level Procurement component */}
      <SelectedCollaboratorsModal
        open={showSelectedCollaboratorsModal}
        handleClose={() => handleCloseModal()}
        removeSelectedCollaborator={props.workflow.removeSelectedCollaborator}
        selectedCollaborators={props.workflow.selectedCollaborators}
      />
      <AddRecipientAddressModal
        addAddress={onAddRecipientAddress}
        cancelAddAddress={onCancelAddRecipientAddress}
        open={openAddressModal}
        closeModal={closeAddressModal}
        collaboratorId={addAddressCollaboratorId}
        address={recipientAddressFormDataPrepopulate}
        addAddressToCollaborator={!organization?.hrisEmployeeDataSyncedAt}
      />
    </>
  );
};

export default OrderSummary;
