import { WritableDraft } from 'immer';
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  GlobalState,
  GlobalNotificationBanner,
  GlobalAvatarPopover,
} from './types';
import { setLocalStorageValue } from 'api/storage';
import {
  getCredentialedCollaborator,
  getAuth0Organization,
  getGodModeOrganization,
} from './actions';
import { CredentialedCollaboratorResponse } from 'api/rest/credentialedCollaborators/types';
import { Organization } from 'types';
import { CollaboratorResponse } from 'services/openapi/apiSchemas';

export const setAuthTokenReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<string>
) => {
  const authToken = action.payload;
  state.authToken = authToken;
  setLocalStorageValue('authToken', authToken);
};

export const setAuthErrorCodeReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<number>
) => {
  state.authErrorCode = action.payload;
};

export const setHasSegmentIdentifiedReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<boolean>
) => {
  state.hasSegmentIdentified = action.payload;
};

export const setGlobalNotificationBannerReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<GlobalNotificationBanner>
) => {
  state.globalNotificationBanner = action.payload;
};

export const setGlobalAvatarPopoverReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<GlobalAvatarPopover>
) => {
  state.globalAvatarPopover = action.payload;
};

export const setGlobalAuth0CollaboratorReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<CredentialedCollaboratorResponse>
) => {
  state.globalAuth0Collaborator = action.payload;
};

export const getCollaboratorPendingReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getCredentialedCollaborator.pending>
> = () => {};

export const getCollaboratorFulfilledReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getCredentialedCollaborator.fulfilled>
> = (state, action) => {
  state.globalAuth0Collaborator = action.payload;
};

export const getCollaboratorRejectedReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getCredentialedCollaborator.rejected>
> = () => {};

export const getAuth0OrganizationPendingReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getAuth0Organization.pending>
> = () => {};

export const getAuth0OrganizationFulfilledReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getAuth0Organization.fulfilled>
> = (state, action) => {
  state.globalAuth0Organization = action.payload;
};

export const getAuth0OrganizationRejectedReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getAuth0Organization.rejected>
> = () => {};

export const getGodModeOrganizationPendingReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getGodModeOrganization.pending>
> = () => {};

export const getGodModeOrganizationFulfilledReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getGodModeOrganization.fulfilled>
> = (state, action) => {
  state.globalGodModeOrganization = action.payload;
};

export const getGodModeOrganizationRejectedReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getGodModeOrganization.rejected>
> = () => {};

export const setGlobalLocationReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<{ pathname?: string; search?: string }>
) => {
  state.globalLocation = { ...state.globalLocation, ...action.payload };
};

export const setGlobalGodModeOrganizationReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<Organization>
) => {
  state.globalGodModeOrganization = action.payload;
};

export const setGlobalGodModeUserReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<CollaboratorResponse>
) => {
  state.globalGodModeUser = action.payload;
};
