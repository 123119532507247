import { AxiosResponse } from 'axios';
import {
  GetMergeConnectionResponse,
  PostMergeCreateLinkTokenBody,
  PostMergeCreateLinkTokenResponse,
  PostMergeSwapAccountTokenBody,
  PostMergeSwapAccountTokenResponse,
} from './types';
import { MergeService } from 'api/rest/config';

/*
// TODO: temporary for local testing, will remove once all testing is done
const LocalService = axios.create({
  baseURL: 'http://localhost:3003',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

// TODO: remove
LocalService.interceptors.request.use(
  (config) => {
    const token = getLocalStorageValue('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// TODO: remove and update base axios interceptor
LocalService.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error.response.data);
  }
);

export const getMergeConnection = (orgId: string) => {
  return LocalService.get<
    GetMergeConnectionResponse,
    AxiosResponse<GetMergeConnectionResponse>
  >(`/v1/connection/${orgId}`).then((response) => response.data);
};

export const postMergeCreateLinkToken = (
  orgId: string,
  data: PostMergeCreateLinkTokenBody
) => {
  return LocalService.post<
    PostMergeCreateLinkTokenBody,
    AxiosResponse<PostMergeCreateLinkTokenResponse>
  >(`/v1/connection/${orgId}/create-link-token`, data).then(
    (response) => response.data
  );
};

export const postMergeSwapAccountToken = (
  orgId: string,
  data: PostMergeSwapAccountTokenBody
) => {
  return LocalService.post<
    PostMergeSwapAccountTokenBody,
    AxiosResponse<PostMergeSwapAccountTokenResponse>
  >(`/v1/connection/${orgId}/account-token`, data).then(
    (response) => response.data
  );
};

export const deleteMergeConnection = (orgId: string) => {
  return LocalService.delete<void, AxiosResponse<void>>(
    `/v1/connection/${orgId}`
  ).then((response) => response.data);
};
*/

export const getMergeConnection = (orgId: string) => {
  return MergeService.get<
    GetMergeConnectionResponse,
    AxiosResponse<GetMergeConnectionResponse>
  >(`/v1/connection/${orgId}`).then((response) => response.data);
};

export const postMergeCreateLinkToken = (
  orgId: string,
  data: PostMergeCreateLinkTokenBody
) => {
  return MergeService.post<
    PostMergeCreateLinkTokenBody,
    AxiosResponse<PostMergeCreateLinkTokenResponse>
  >(`/v1/connection/${orgId}/create-link-token`, data).then(
    (response) => response.data
  );
};

export const postMergeSwapAccountToken = (
  orgId: string,
  data: PostMergeSwapAccountTokenBody
) => {
  return MergeService.post<
    PostMergeSwapAccountTokenBody,
    AxiosResponse<PostMergeSwapAccountTokenResponse>
  >(`/v1/connection/${orgId}/account-token`, data).then(
    (response) => response.data
  );
};

export const deleteMergeConnection = (orgId: string) => {
  return MergeService.delete<void, AxiosResponse<void>>(
    `/v1/connection/${orgId}`
  ).then((response) => response.data);
};
