import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { HeaderSx, ExternalLinksContainerSx } from './styles';
import { ExternalLinksStatus } from './constants';
import { ExternalLinksProps } from './types';
import { isValidUrl, formatUrl } from './utils';
import ExternalLinksList from './components/ExternalLinksList';
import ExternalLinksInput from './components/ExternalLinksInput';
import { useGetExternalLinksConfig } from './hooks';

const ExternalLinks = (props: ExternalLinksProps) => {
  const { orderId, externalLinks: initialExternalLinks } = props;

  const {
    newLink,
    setNewLink,
    showTextField,
    setShowTextField,
    externalLinks,
    externalLinksStatus,
    setExternalLinksStatus,
    handleAddLink,
    handleDeleteLink,
  } = useGetExternalLinksConfig(initialExternalLinks || [], orderId);

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewLink(event.target.value);
    if (event.target.value === '') {
      setExternalLinksStatus(ExternalLinksStatus.SUBMIT_DISABLED);
      return;
    }

    const formattedUrl = formatUrl(event.target.value ?? '');
    if (isValidUrl(formattedUrl))
      setExternalLinksStatus(ExternalLinksStatus.SUBMIT_ENABLED);
    else
      setExternalLinksStatus(
        externalLinksStatus === ExternalLinksStatus.SUBMIT_ENABLED
          ? ExternalLinksStatus.SUBMIT_DISABLED
          : externalLinksStatus
      );
  };

  const handleTextFieldBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewLink(event.target.value);
    if (event.target.value === '') return;

    if (externalLinksStatus !== ExternalLinksStatus.SUBMIT_ENABLED) {
      setExternalLinksStatus(ExternalLinksStatus.SUBMIT_DISABLED_WITH_ERROR);
    }
  };

  const handleCloseTextField = () => {
    setShowTextField(false);
    setNewLink('');
  };

  return (
    <Stack direction="column" sx={ExternalLinksContainerSx}>
      <Typography variant="subtitle2" sx={HeaderSx}>
        External Links
      </Typography>
      <ExternalLinksList
        externalLinks={externalLinks}
        showAddLinkButton={!showTextField}
        onDeleteLink={handleDeleteLink}
        onAddLink={() => setShowTextField(true)}
      />
      {showTextField && (
        <ExternalLinksInput
          externalLink={newLink}
          externalLinksStatus={externalLinksStatus}
          onChange={handleTextFieldChange}
          onBlur={handleTextFieldBlur}
          onCancel={handleCloseTextField}
          addLink={handleAddLink}
        />
      )}
    </Stack>
  );
};

export default ExternalLinks;
