import React from 'react';

import { UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'store';

import { JamfCredentialsFormFields } from '../forms/JamfCredentialsForm/types';
import { notificationSlice } from 'store/slices';
import { postJamfConnection } from 'store/slices/integrations/jamf/connect/actions';
import {
  selectJamfConnectionPostErrorMessage,
  selectJamfConnection,
} from 'store/slices/integrations/jamf/connect/selectors';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { useNavigate } from 'react-router-dom';

export const useGetHandleOnButtonClick = (
  form: UseFormReturn<JamfCredentialsFormFields>
) => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();

  return React.useCallback(
    form.handleSubmit(async (data) => {
      if (organization) {
        try {
          await dispatch(postJamfConnection({ orgId: organization.id, data }));
        } catch (error) {
          console.error(error);
        }
      }
    }),
    [form, dispatch, organization]
  );
};

export const useHandleErrorNotifications = () => {
  const dispatch = useDispatch<AppDispatch>();
  const postErrorMessage = useSelector(selectJamfConnectionPostErrorMessage);
  React.useEffect(() => {
    if (postErrorMessage) {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: postErrorMessage,
        })
      );
    }
  }, [dispatch, postErrorMessage]);
};

export const useHandleRedirects = () => {
  const dispatch = useDispatch<AppDispatch>();
  const connection = useSelector(selectJamfConnection);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (connection.postLoading === 'fulfilled') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: 'Connection successful',
        })
      );
      navigate('/integrations');
    }
  }, [connection.postLoading, navigate]);
};
