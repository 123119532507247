import React from 'react';
import Stack from '@mui/material/Stack';
import { InfoSectionSubHeaderSx } from './styles';
import { JAMF_FAQ_URL } from './constants';

const InfoSectionSubHeader: React.FC = () => {
  return (
    <Stack sx={InfoSectionSubHeaderSx}>
      How to setup your integration?{' '}
      <a target="_blank" href={JAMF_FAQ_URL}>
        Learn more
      </a>
    </Stack>
  );
};

export default InfoSectionSubHeader;
