import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectGlobalAuth0Collaborator,
  selectGlobalAuth0Organization,
  selectHasSegmentIdentified,
} from 'store/slices/global/selectors';
import useGetGlobalPurchaser from '../useGetGlobalPurchaser';
import useGetUserOrganization from '../useGetUserOrganization';
import { UserPermissions } from 'global-constants';
import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { setHasSegmentIdentified } from 'store/slices/global/index';

const useSetSegmentUser = () => {
  const hasSegmentIdentified = useSelector(selectHasSegmentIdentified);
  const dispatch = useDispatch();
  const godModeCollaborator = useGetGlobalPurchaser();
  const godModeOrganization = useGetUserOrganization();

  const globalAuth0Collaborator = useSelector(selectGlobalAuth0Collaborator);
  const globalAuth0Organization = useSelector(selectGlobalAuth0Organization);
  const permissions = useGetUserPermissions();
  const { user } = useAuth0();
  const orgName = globalAuth0Organization?.name;
  const userRole = globalAuth0Collaborator?.role;

  React.useEffect(() => {
    if (!user || hasSegmentIdentified) return;
    // @ts-ignore
    const userPublicId = user?.userPublicId;
    // @ts-ignore
    const orgPublicId = user?.organizationPublicId;

    const isGodModeUser = permissions.includes(
      UserPermissions.ORGANIZATION__TOGGLE
    );

    if (isGodModeUser) {
      if (
        !godModeCollaborator ||
        !godModeOrganization ||
        !orgPublicId ||
        !orgName ||
        !window.analytics
      )
        return;

      window.analytics.identify(userPublicId, {
        company: {
          id: orgPublicId,
          name: orgName,
        },
        impersonatedUserId: godModeCollaborator?.publicId,
        impersonatedOrgId: godModeOrganization?.publicId,
        impersonatedUserEmail: godModeCollaborator?.workEmail || '',
        userType: 'internal',
      });
      dispatch(setHasSegmentIdentified(true));
    } else {
      if (!orgPublicId || !orgName || !window.analytics) return;
      const mapping: { [index: string]: string } = {
        EXTERNAL_PRIMARY_ADMINISTRATOR: 'primary_admin',
        EXTERNAL_SECONDARY_ADMINISTRATOR: 'secondary_admin',
      };
      if (userRole) {
        window.analytics.identify(userPublicId, {
          company: {
            id: orgPublicId,
            name: orgName,
          },
          userType: mapping[userRole],
          email: user?.email || '',
        });
        dispatch(setHasSegmentIdentified(true));
      }
    }
  }, [
    user,
    godModeCollaborator,
    godModeOrganization,
    globalAuth0Collaborator,
    globalAuth0Organization,
    hasSegmentIdentified,
  ]);
};

export default useSetSegmentUser;
