import React from 'react';
import { UseFormWatch } from 'react-hook-form';
import { IRecipientAddressFormValues } from './types';
import { formatRecipientShippingInfo } from './utils';

export const useGetRecipientShippingInfoCopyValue = (
  isDirty: boolean,
  watch: UseFormWatch<IRecipientAddressFormValues>
) => {
  const recipientShippingInfo = watch();
  return React.useMemo(() => {
    if (!isDirty) {
      return formatRecipientShippingInfo(recipientShippingInfo);
    } else {
      return '';
    }
  }, [isDirty, recipientShippingInfo]);
};
