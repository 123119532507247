import React from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import truncate from 'lodash/truncate';
import { useSelector } from 'react-redux';

import CopyButton from 'components/copy_to_clipboard/CopyButton';
import InfoSectionSubHeader from '../InfoSectionSubHeader';

import { selectMergeConnection } from 'store/slices/integrations/merge/connect/selectors';
import { EMAIL_INFO_HELPER_TEXT, MERGE_FAQ_URL } from './constants';
import { EMAIL_FIELD_VALIDATION_REGEX } from 'globals/constants/regex';

import { Props } from './types';

import {
  ContainerSx,
  InfoSectionHeaderSx,
  InfoContainerSx,
  InfoSx,
  AlertSx,
  FormInputFieldContainerSx,
} from './styles';

const MergeCredentialsForm: React.FC<Props> = (props) => {
  const { type, form } = props;
  const { register } = form;
  const connection = useSelector(selectMergeConnection);
  const isConnect = type === 'connect';

  return (
    <Stack sx={ContainerSx}>
      <Stack sx={InfoSectionHeaderSx}>Account</Stack>
      {isConnect ? (
        <TextField
          sx={FormInputFieldContainerSx}
          {...register('endUserEmailAddress', {
            required: true,
            pattern: {
              value: EMAIL_FIELD_VALIDATION_REGEX,
              message: 'Invalid email address',
            },
          })}
          id="endUserEmailAddress"
          label="Admin Email"
          fullWidth
          helperText={EMAIL_INFO_HELPER_TEXT}
        />
      ) : null}

      {!isConnect ? (
        <>
          <Stack sx={InfoContainerSx}>
            Email: <Stack sx={InfoSx}>{connection?.endUserEmailAddress}</Stack>
          </Stack>
          <Stack sx={InfoContainerSx}>
            Integration: <Stack sx={InfoSx}>{connection?.integration}</Stack>
          </Stack>
          <Stack sx={InfoContainerSx}>
            Category:{' '}
            <Stack sx={InfoSx}>{connection?.category?.toUpperCase()}</Stack>
          </Stack>
          <Stack sx={InfoContainerSx}>
            Status: <Stack sx={InfoSx}>{connection?.status}</Stack>
          </Stack>
          <Stack sx={InfoContainerSx}>
            Magic Link URL:{' '}
            <Stack sx={InfoSx}>
              {truncate(connection?.magicLinkUrl, { length: 50 })}
              <CopyButton copyValue={connection?.magicLinkUrl} />
            </Stack>
          </Stack>
        </>
      ) : null}
      <Divider />
      <Stack sx={InfoSectionHeaderSx}>About HRIS Integration</Stack>
      <InfoSectionSubHeader />
      <Alert severity="info" sx={AlertSx}>
        <b>Have a question about your integration?</b>
        <br />
        Please visit our{' '}
        <a href={MERGE_FAQ_URL} target="_blank">
          FAQ
        </a>{' '}
        page.
      </Alert>
    </Stack>
  );
};

export default MergeCredentialsForm;
