import { combineSlices } from '@reduxjs/toolkit';
import { assetsCSVSlice } from './csv';
import assetFilterSlice from './filter';
import comboFilterSlice from './comboBoxFilter';
import { assetColumnsSlice } from './columns';
import { tableSlice } from './table';

export const assetsSlice = combineSlices({
  filter: assetFilterSlice.reducer,
  comboBoxFilter: comboFilterSlice.reducer,
  columns: assetColumnsSlice.reducer,
  csv: assetsCSVSlice.reducer,
  table: tableSlice.reducer,
});
