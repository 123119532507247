import { createAsyncThunk } from '@reduxjs/toolkit';
import * as restCredentialedCollaboratorsAPI from 'api/rest/credentialedCollaborators';
import * as restOrganizationAPI from 'api/rest/organizations';

export const getCredentialedCollaborator = createAsyncThunk(
  'global/getCredentialedCollaborator',
  async (params: { collaboratorId: string }) => {
    return restCredentialedCollaboratorsAPI.getCredentialedCollaborator({
      collaboratorId: params.collaboratorId,
    });
  }
);

export const getAuth0Organization = createAsyncThunk(
  'global/getAuth0Organization',
  async (params: { organizationId: string }) => {
    return restOrganizationAPI.getOrganization(params.organizationId);
  }
);

export const getGodModeOrganization = createAsyncThunk(
  'global/getGodModeOrganization',
  async (params: { organizationId: string }) => {
    return restOrganizationAPI.getOrganization(params.organizationId);
  }
);
