import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useLocation } from 'react-router-dom';

import Breadcrumbs from 'components/navigation/Breadcrumbs';

import { Props } from './types';

import {
  ContainerSx,
  HeaderContainerSx,
  TitleContainerSx,
  BreadcrumbsContainerSx,
  ContentContainerSx,
  FooterContainerSx,
  PrimaryButtonSx,
  SecondaryButtonSx,
  VerifiedContainerSx,
} from './styles';

const IntegrationConfigurationLayout: React.FC<Props> = (props) => {
  const {
    title,
    primaryButtonCopy,
    handleOnPrimaryButtonClick,
    primaryButtonDisabled,
    secondaryButtonCopy,
    handleOnSecondaryButtonClick,
    secondaryButtonDisabled,
    children,
    type,
    'data-testid': dataTestId,
  } = props;

  const location = useLocation();
  return (
    <Stack sx={ContainerSx} data-testid={dataTestId}>
      <Stack sx={HeaderContainerSx} data-testid={`${dataTestId}-header`}>
        <Stack
          sx={BreadcrumbsContainerSx}
          data-testid={`${dataTestId}-breadcrumbs-container`}
        >
          <Breadcrumbs
            pathname={location.pathname}
            ignore={['/integrations/jamf']}
            data-testid={`${dataTestId}-breadcrumbs`}
          />
        </Stack>
        <Stack sx={TitleContainerSx} data-testid={`${dataTestId}-title`}>
          {title}
          {type === 'settings' && (
            <Stack sx={VerifiedContainerSx}>
              {' '}
              <VerifiedIcon /> Connected
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack sx={ContentContainerSx} data-testid={`${dataTestId}-content`}>
        {children}
      </Stack>

      <Stack sx={FooterContainerSx} data-testid={`${dataTestId}-footer`}>
        {primaryButtonCopy && (
          <Button
            sx={PrimaryButtonSx}
            variant="contained"
            color="primary"
            onClick={handleOnPrimaryButtonClick}
            disabled={primaryButtonDisabled}
            data-testid={`${dataTestId}-button`}
          >
            {primaryButtonCopy}
          </Button>
        )}
        {secondaryButtonCopy && (
          <Button
            sx={SecondaryButtonSx}
            variant="contained"
            color="secondary"
            onClick={handleOnSecondaryButtonClick}
            disabled={secondaryButtonDisabled}
          >
            {secondaryButtonCopy}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default IntegrationConfigurationLayout;
