export const ContainerSx = {
  display: 'flex',
  flexDirection: 'row',
  width: '75%',
  border: '1px solid lightgray',
  minHeight: '100px',
  borderRadius: '10px',
  mb: '20px',
  padding: '0px',
};

export const ImageContainerSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100px',
  height: '100px',
  img: {
    width: '80%',
    height: '80%',
  },
};

export const DescriptionContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  color: 'gray',
};

export const LeftSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '10%',
  minWidth: '100px',
};

export const CenterSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '70%',
};

export const TitleContainerSx = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  fontWeight: 'bold',
};

export const TitleSx = {
  fontSize: '18px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontWeight: 'bold',
};

export const VerifiedContainerSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: '#7A8450',
  fontWeight: 'bold',
  height: '100%',
  pl: '10px',
};

export const RightSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  width: '20%',
  paddingRight: '15px',
};

export const ButtonContainerSx = {
  display: 'flex',
};

export const ButtonSx = {
  padding: '8px 16px',
  fontWeight: 'bold',
  height: '40px',
  minWidth: '90px',
  width: 'fit-content',
  whiteSpace: 'nowrap',
};
