import { COUNTRY_MATCHER, US_STATE_MATCHER } from 'global-constants';
import { ShippingTypeOption, FlatShippingInfo } from 'types';

/**
 * @param nowCallback - (): void => {} function to be executed immediately
 * @param waitCallback - (): void => {} function to be executed after the delay in milliseconds
 * @param delayInMS - delay in milliseconds between the nowCallback and waitCallback's invocations
 */
export const executeNowThenWait = (
  nowCallback: Function,
  waitCallback: Function,
  delayInMS: number
) => {
  nowCallback();
  const timer = setTimeout(() => {
    waitCallback();
    clearTimeout(timer);
  }, delayInMS);
};

export const getTableFromArray = (strings: string[]) =>
  strings.reduce((acc: Record<string, boolean>, word: string) => {
    // eslint-disable-next-line
    acc[word] = true;
    return acc;
  }, {});

export const nullAsUndefined = (input: any) =>
  input === null ? undefined : input;

export const getCapitalizedCountry = (country: string) => {
  const isMacroCountryName = /[A-Z_]+/.test(country);
  return isMacroCountryName ? COUNTRY_MATCHER[country] : country;
};

export const getFullNameState = (state: string | null) => {
  if (state == null) return state;
  const isStateAbbreviated = state.length === 2;
  return isStateAbbreviated ? state : US_STATE_MATCHER[state];
};

export const removeNullFields = (obj: Record<string, any>) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === null) delete newObj[key];
  });
  return newObj;
};

export const flattenShippingTypeOption = (
  shippingTypeOption: ShippingTypeOption
): FlatShippingInfo => {
  const { days = 0, shippingTypeId, shippingType } = shippingTypeOption;

  return {
    shippingTypeId,
    shippingTypeName: shippingType?.name ?? '',
    order: shippingType?.order ?? 0,
    description: shippingType?.description ?? '',
    days,
    price: shippingTypeOption?.price ?? '0.00',
  };
};
