import React from 'react';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

import IntegrationConfigurationLayout from 'pages/integrations/IntegrationConfigurationLayout';
import IntegrationItem from 'pages/integrations/IntegrationItem';

import {
  ContainerSx,
  IntegrationContainerSx,
  IntegrationItemContainerSx,
  InformationContainerSx,
  AlertContainerSx,
} from './styles';
import {
  useInitializeIntegrations,
  useGetJamfConnection,
  useCanUserConfigureIntegrations,
  useGetMergeConnection,
} from './hooks';
import { getShouldShowAlertBanner } from './utils';
import { useSelector } from 'react-redux';
import { selectIntegrationsList } from 'store/slices/integrations/list/selectors';
import { selectJamfConnection } from 'store/slices/integrations/jamf/connect/selectors';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';

const Integrations = () => {
  const integrations = useSelector(selectIntegrationsList);
  const connection = useSelector(selectJamfConnection);
  const organization = useGetUserOrganization();
  const user = useGetGlobalPurchaser();
  useInitializeIntegrations();
  useGetJamfConnection();
  useGetMergeConnection();
  const dataTestId = 'integrations';
  const userCanConfigureIntegrations = useCanUserConfigureIntegrations();
  const showAlertBanner = getShouldShowAlertBanner(
    user,
    organization,
    connection.getLoading,
    userCanConfigureIntegrations
  );

  return (
    <Stack sx={ContainerSx} data-testid={dataTestId}>
      <IntegrationConfigurationLayout
        title="Integrations"
        data-testid={`${dataTestId}-layout`}
      >
        <Stack
          sx={IntegrationContainerSx}
          data-testid={`${dataTestId}-container`}
        >
          {showAlertBanner ? (
            <Stack
              sx={AlertContainerSx}
              data-testid={`${dataTestId}-alert-container`}
            >
              <Alert severity="error" data-testid={`${dataTestId}-alert`}>
                You do not have permission to configure integrations
              </Alert>
            </Stack>
          ) : null}
          <Stack
            sx={IntegrationItemContainerSx}
            data-testid={`${dataTestId}-enabled-items-container`}
          >
            {integrations.enabled.map((integration) => (
              <IntegrationItem
                key={`enabled-${integration.key}`}
                integration={integration}
                data-testid={`${dataTestId}-enabled-item-${integration.key}`}
              />
            ))}
          </Stack>
          <Stack
            sx={InformationContainerSx}
            data-testid={`${dataTestId}-coming-soon`}
          >
            Coming Soon...
          </Stack>
          <Stack
            sx={IntegrationItemContainerSx}
            data-testid={`${dataTestId}-disabled-items-container`}
          >
            {integrations.disabled.map((integration) => (
              <IntegrationItem
                key={`disabled-${integration.key}`}
                integration={integration}
                data-testid={`${dataTestId}-disabled-item-${integration.key}`}
              />
            ))}
          </Stack>
        </Stack>
      </IntegrationConfigurationLayout>
    </Stack>
  );
};

export default Integrations;
