import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { JamfCredentialsFormFields } from '../forms/JamfCredentialsForm/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectJamfConnection,
  selectJamfConnectionGetErrorMessage,
  selectJamfConnectionPutErrorMessage,
} from 'store/slices/integrations/jamf/connect/selectors';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { useGetJamfConnection } from 'pages/integrations/Integrations/hooks';
import { notificationSlice } from 'store/slices';
import { AppDispatch } from 'store';
import { useLocation } from 'react-router-dom';
import { putJamfConnection } from 'store/slices/integrations/jamf/connect/actions';
import { setView } from 'store/slices/integrations/jamf/settings';

export const useSetFormValues = (
  form: UseFormReturn<JamfCredentialsFormFields>
) => {
  const location = useLocation();
  const connection = useSelector(selectJamfConnection);
  const user = useGetGlobalPurchaser();
  const organization = useGetUserOrganization();
  useGetJamfConnection();

  React.useEffect(() => {
    const { clientId, clientSecret, subdomain } = connection;

    if (connection.clientId && form && user?.id && organization?.id) {
      form.setValue('clientId', clientId);
      form.setValue('clientSecret', clientSecret);
      form.setValue('subdomain', subdomain);
    }
  }, [form, connection, user?.id, organization?.id, location.pathname]);
};

export const useHandleErrorNotifications = () => {
  const dispatch = useDispatch<AppDispatch>();
  const getErrorMessage = useSelector(selectJamfConnectionGetErrorMessage);
  const putErrorMessage = useSelector(selectJamfConnectionPutErrorMessage);

  React.useEffect(() => {
    if (getErrorMessage) {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: getErrorMessage,
        })
      );
    }
  }, [dispatch, getErrorMessage]);

  React.useEffect(() => {
    if (putErrorMessage) {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: putErrorMessage,
        })
      );
    }
  }, [dispatch, putErrorMessage]);
};

export const useHandleSuccessNotifications = () => {
  const dispatch = useDispatch<AppDispatch>();
  const connection = useSelector(selectJamfConnection);

  React.useEffect(() => {
    if (connection.putLoading === 'fulfilled') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: 'Settings updated successfully',
        })
      );
    }
  }, [dispatch, connection.putLoading]);
};

export const useGetHandleOnButtonClick = (
  form: UseFormReturn<JamfCredentialsFormFields>
) => {
  const dispatch = useDispatch<AppDispatch>();
  const organization = useGetUserOrganization();

  return React.useCallback(
    form.handleSubmit(async (data) => {
      if (organization) {
        try {
          await dispatch(putJamfConnection({ orgId: organization.id, data }));
          dispatch(setView('read'));
        } catch (error) {
          console.error(error);
        }
      }
    }),
    [form, dispatch, organization]
  );
};
