import { createTheme, ZIndex, Theme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import type {} from '@mui/lab/themeAugmentation';
import colors from './colors';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1536,
    },
  },
  zIndex: { underModal: 1299 },
  palette: {
    primary: {
      main: colors.maizeYO,
    },
    secondary: {
      main: colors.jungleG,
      contrastText: colors.white,
    },
    warning: {
      main: colors.burntSiennaR,
      contrastText: colors.white,
    },
    info: {
      contrastText: 'rgba(19, 20, 20, 0.04)',
      main: colors.woodSmoke,
    },
    success: {
      main: colors.goldFusion,
    },
    colors,
  },
  typography: {
    fontFamily: ['Inter', 'PPPangramSans'].join(','),
    h1: {
      fontFamily: ['PPPangramSans'].join(','),
    },
    h2: {
      fontFamily: ['PPPangramSans'].join(','),
      fontSize: '36px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
    },
    h3: {
      fontFamily: ['PPPangramSans'].join(','),
    },
    h4: {
      fontFamily: ['PPPangramSans'].join(','),
    },
    h5: {
      fontFamily: ['PPPangramSans'].join(','),
    },
    h6: {
      fontFamily: ['PPPangramSans'].join(','),
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    button: {
      fontFamily: ['PPPangramSans'].join(','),
      textTransform: 'none',
    },
    overline: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '19.92px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.woodSmoke,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&&:before': {
            borderBottom: 'none',
          },
          '&&:hover': {
            '&&:before': {
              borderBottom: 'none',
            },
          },
          '&&:after': {
            borderBottom: 'none',
          },
          '&.Mui-error': {
            '&&:after': {
              border: '1px solid error',
            },
          },
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: colors.alabasterN,
          borderRadius: '100px',
        },
        input: {
          color: colors.mineShaftWN,
          padding: '0 12px',
          '&::placeholder': {
            color: colors.boulderWN,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
          boxShadow: 'none',
          minWidth: '128px',
          padding: '12px 39px',
          textTransform: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&.Mui-border-toggled': {
            borderColor: colors.woodSmoke,
            borderWidth: '1px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.boulderWN,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: colors.alabasterN,
          borderRadius: '100px',
          padding: '0 12px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          ':focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
});

const defaultZIndexes = theme.zIndex;

export default theme;
