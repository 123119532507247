/**
 * Formats a URL string to ensure it has a protocol and hostname
 * @param value - The URL string to format
 * @returns string - The formatted URL, or empty string if the input is invalid
 */
export const formatUrl = (value: string): string => {
  if (!value || typeof value !== 'string') return '';

  const result =
    /^([Hh][Tt][Tt][Pp][Ss]?:\/\/)?(?:[Ww][Ww][Ww]\.)?([-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*))$/.exec(
      value
    );

  if (!result) return '';

  const prefix = result[1]?.toLowerCase() || 'https://';

  return `${prefix}${result[2]}`;
};

/**
 * Validates if a string is a valid URL
 * @param url - The string to validate as a URL
 * @returns boolean - True if the string is a valid URL, false otherwise
 */
export const isValidUrl = (url: string): boolean => {
  if (!url) return false;

  try {
    // Store the result of the URL constructor to avoid the 'new' for side effects linter error
    const urlObj = new URL(url);
    return !!urlObj;
  } catch (e) {
    return false;
  }
};

/**
 * Extracts the hostname from a URL
 * @param url - The URL to extract the hostname from
 * @returns string - The hostname of the URL, or the original URL if it's invalid
 */
export const getHostname = (url: string): string => {
  if (!isValidUrl(url)) return url;

  try {
    const urlObj = new URL(url);
    return urlObj.hostname;
  } catch (e) {
    return url;
  }
};
