import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useCollaboratorControllerSearch } from 'services/openapi/apiComponents';
import { CollaboratorList } from 'services/openapi/apiSchemas';
import { Update } from 'ts-toolbelt/out/Object/Update';
import { DEFAULT_PURCHASER_VALUE } from './constants';
import { Collaborator } from 'types';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import { useGlobalState } from 'state';
import { ToggleSx } from '../styles';

const PurchaserToggle = () => {
  const [globalPurchaser, setGlobalPurchaser] =
    useGlobalState('globalPurchaser');

  const userOrganization = useGetUserOrganization();

  type CollaboratorSearchResponse = Update<
    CollaboratorList,
    'items',
    Collaborator[]
  >;
  const { data } = useCollaboratorControllerSearch<CollaboratorSearchResponse>(
    {
      queryParams: {
        orderAsc: 'lastName',
        organizationId: userOrganization?.id,
        identityProviderId: 'auth', // this is how we know if a collaborator is an admin
      },
    },
    {
      enabled: !!userOrganization?.id,
    }
  );

  const purchaserOptions = data?.items || [];

  const onChangePurchaser = (
    _e: any,
    selectedPurchaser: Collaborator | null
  ) => {
    selectedPurchaser
      ? setGlobalPurchaser(selectedPurchaser)
      : setGlobalPurchaser(undefined);
  };

  const dataTestId = 'purchaser-toggle-input';

  return (
    <Autocomplete
      sx={ToggleSx}
      data-testid="purchaser-toggle"
      value={globalPurchaser || DEFAULT_PURCHASER_VALUE}
      options={purchaserOptions}
      renderOption={(props, option) => (
        <li {...props} data-testid={`${dataTestId}-option`}>
          {option.firstName} {option.lastName}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder="Purchaser"
          data-testid={`${dataTestId}-input`}
        />
      )}
      getOptionLabel={(option) =>
        option?.firstName ? `${option?.firstName} ${option?.lastName}` : ''
      }
      onChange={onChangePurchaser}
    />
  );
};

export default PurchaserToggle;
