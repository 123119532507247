/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { OrderItemCardProps } from '../../types';
import CopyButton from 'components/copy_to_clipboard/CopyButton';

const PinsAndKeys = (props: OrderItemCardProps) => {
  return (
    <>
      <Grid item xs={4}>
        <Stack direction="column">
          <Typography className="order-details-card-title">
            MDM Pin(s)
            <CopyButton
              copyValue={props?.orderItem?.mdmPins?.join(',') || ''}
            />
          </Typography>
          {!props?.orderItem?.mdmPins?.length && <Typography>N/A</Typography>}
          {props?.orderItem?.mdmPins?.map((code, i) => (
            <Typography key={`oic-pins-${i}`}>{code}</Typography>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction="column">
          <Typography className="order-details-card-title">
            Activation Key(s)
            <CopyButton
              copyValue={props?.orderItem?.activationKeys?.join(',') || ''}
            />
          </Typography>
          {!props?.orderItem?.activationKeys?.length && (
            <Typography>N/A</Typography>
          )}
          {props?.orderItem?.activationKeys?.map((code, i) => (
            <Typography key={`oic-ak-${i}`}>{code}</Typography>
          ))}
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction="column">
          <Typography className="order-details-card-title">
            Recovery Key(s)
            <CopyButton
              copyValue={props?.orderItem?.recoveryCodes?.join(',') || ''}
            />
          </Typography>
          {!props?.orderItem?.recoveryCodes?.length && (
            <Typography>N/A</Typography>
          )}
          {props?.orderItem?.recoveryCodes?.map((code, i) => (
            <Typography key={`oic-rc-${i}`}>{code}</Typography>
          ))}
        </Stack>
      </Grid>
    </>
  );
};

export default PinsAndKeys;
