import React from 'react';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';

import IntegrationConfigurationLayout from 'pages/integrations/IntegrationConfigurationLayout';
import JamfCredentialsForm from '../forms/JamfCredentialsForm';
import { JamfCredentialsFormFields } from '../forms/JamfCredentialsForm/types';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import { useCanUserConfigureIntegrations } from 'pages/integrations/Integrations/hooks';
import { AlertContainerSx } from 'pages/integrations/Integrations/styles';

import {
  useGetHandleOnButtonClick,
  useHandleErrorNotifications,
  useHandleRedirects,
} from './hooks';
import { ContainerSx } from './styles';

const JamfConnect = () => {
  useHandleErrorNotifications();
  const form = useForm<JamfCredentialsFormFields>();
  const isFormValid = form.formState.isValid;
  const handleOnButtonClick = useGetHandleOnButtonClick(form);
  const organization = useGetUserOrganization();
  const user = useGetGlobalPurchaser();
  const userCanConfigureIntegrations = useCanUserConfigureIntegrations();
  useHandleRedirects();
  const showAlertBanner = !userCanConfigureIntegrations;

  const dataTestId = 'jamf-connect-page';
  return (
    <Stack sx={ContainerSx} data-testid={dataTestId}>
      <IntegrationConfigurationLayout
        type="connect"
        title="Connect Jamf"
        handleOnPrimaryButtonClick={handleOnButtonClick}
        primaryButtonCopy="Connect account"
        primaryButtonDisabled={
          !isFormValid || !organization || !user || showAlertBanner
        }
        data-testid={`${dataTestId}-layout`}
      >
        {showAlertBanner ? (
          <Stack
            sx={AlertContainerSx}
            data-testid={`${dataTestId}-alert-container`}
          >
            <Alert severity="error" data-testid={`${dataTestId}-alert`}>
              You do not have permission to configure integrations
            </Alert>
          </Stack>
        ) : null}
        <JamfCredentialsForm
          form={form}
          type="connect"
          data-testid={`${dataTestId}-credentials`}
        />
      </IntegrationConfigurationLayout>
    </Stack>
  );
};

export default JamfConnect;
