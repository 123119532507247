import React from 'react';
import { ApolloError } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { MuiTelInput } from 'mui-tel-input';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import { FormModeContext } from 'global-constants';
import EmployeeAddressForm from 'pages/Employees/components/EmployeeAddressForm';
import { EditEmployeeAddressInputDTO } from 'pages/Employees/components/EmployeeAddressForm/types';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useSliceState from 'hooks/useSliceState';
import NewModal from '..';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { useSafeMutation } from 'hooks/useSafeMutation';
import {
  EMPLOYEE_BLANK_ADD_ADDRESS_TABLE,
  EMPTY_EMPLOYEE_FORM_INPUTS,
  PERSONAL_EMAIL_TESTID,
  WORK_EMAIL_TESTID,
  FIRST_NAME_TESTID,
  LAST_NAME_TESTID,
} from './constants';
import * as EditEmployeeModalStyles from './styles';
import {
  EmployeeFormModalInputs,
  EmployeeFormModalProps,
  AddressLikeObject,
} from './types';
import { RETRIEVE_EMPLOYEE_BY_ID } from './queries';
import {
  CREATE_DETAILED_COLLABORATOR,
  UPDATE_COLLABORATOR,
  CREATE_ADDRESS,
  CREATE_COLLABORATOR_ADDRESS,
  UPDATE_ADDRESS,
} from './mutations';
import {
  getEditEmployeeFormInitialValues,
  getAddressLikeObject,
  isAdmissibleAddress,
  getCreateCollaboratorPayload,
  getUpdateCollaboratorPayload,
  getCreateAddressPayload,
  getUpdateAddressPayload,
} from './utils';
import { HomeOutlined, WorkOutline } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { HomeAddress, WorkAddress } from 'types';

const EmployeeFormModal = (props: EmployeeFormModalProps) => {
  const styles = EditEmployeeModalStyles;
  const userOrganization = useGetUserOrganization();
  const dispatch = useDispatch();

  const {
    changedFieldsList: changedAddHomePlaceFieldsList,
    reducedFieldsTable: addHomeFieldsTable,
    setFieldValues: setAddHomeFieldValues,
  } = useSliceState(
    getAddressLikeObject(
      EMPLOYEE_BLANK_ADD_ADDRESS_TABLE as AddressLikeObject,
      {
        fixLocales: true,
        passRestOfAddressLikeObject: true,
      }
    ),
    'ADD_HOME_EMPLOYEE_ADDRESS',
    { nulls: false }
  );

  const {
    changedFieldsList: changedAddWorkPlaceFieldsList,
    reducedFieldsTable: addWorkFieldsTable,
    setFieldValues: setAddWorkFieldValues,
  } = useSliceState(
    getAddressLikeObject(
      EMPLOYEE_BLANK_ADD_ADDRESS_TABLE as AddressLikeObject,
      {
        fixLocales: true,
        passRestOfAddressLikeObject: true,
      }
    ),
    'ADD_WORK_EMPLOYEE_ADDRESS',
    { nulls: false }
  );

  const homeAddresses = props.employee?.homeAddresses ?? [];
  const workAddresses = props.employee?.workAddresses ?? [];
  const collaboratorAddresses = props.employee?.collaboratorAddresses ?? [];

  const homeAddressSliceStates = homeAddresses.map(
    (address: any, index: number) =>
      useSliceState(
        getAddressLikeObject(address, {
          fixLocales: true,
          passRestOfAddressLikeObject: true,
        }),
        `EDIT_HOME_EMPLOYEE_ADDRESS_INDEX_${index}`,
        { nulls: false }
      )
  );

  const workAddressSliceStates = workAddresses.map(
    (address: any, index: number) =>
      useSliceState(
        getAddressLikeObject(address, {
          fixLocales: true,
          passRestOfAddressLikeObject: true,
        }),
        `EDIT_HOME_EMPLOYEE_ADDRESS_INDEX_${index}`,
        { nulls: false }
      )
  );

  const [editEmployeeInputDTOs, setEditEmployeeInputDTOs] = React.useState<
    Record<string, EditEmployeeAddressInputDTO>
  >({});

  const memoCollaboratorAddressLookup = React.useMemo(() => {
    if (!collaboratorAddresses.length) return {};
    return collaboratorAddresses.reduce(
      (acc: Record<string, any>, next: any) => {
        const { addressId, ...rest } = next;
        acc[addressId] = rest;
        return acc;
      },
      {}
    );
  }, [collaboratorAddresses]);

  if (!props.employee && props.formMode === FormModeContext.EDIT) return null;

  const addEditEmployeeInputDTO = (
    addressId: string,
    inputDTO: EditEmployeeAddressInputDTO
  ) => {
    setEditEmployeeInputDTOs((prev) => ({ ...prev, [addressId]: inputDTO }));
  };

  const removeEditEmployeeInputDTO = (addressId: string) => {
    setEditEmployeeInputDTOs((prev) => {
      if (!prev) return prev;
      if (prev && addressId in prev) {
        delete prev[addressId];
      }
      return prev;
    });
  };

  const getFormTitle = () =>
    props.formMode === FormModeContext.EDIT ? 'Edit Employee' : 'Add Employee';

  const getInitialEmployeeFormModalInputs = () => {
    if (props.formMode === FormModeContext.EDIT && props.employee) {
      return getEditEmployeeFormInitialValues(
        props.employee
      ) as EmployeeFormModalInputs;
    }
    return EMPTY_EMPLOYEE_FORM_INPUTS;
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<EmployeeFormModalInputs>({
    values: { ...getInitialEmployeeFormModalInputs() },
  });

  const { firstName, lastName, email, workEmail, personalEmail } = watch();

  const getDerivedEditProps = (address: any) => {
    const { id } = address;
    return id in memoCollaboratorAddressLookup
      ? {
          collaboratorAddressId: memoCollaboratorAddressLookup[id].id,
          addressId: id,
          ...address,
        }
      : { ...address, addressId: id };
  };

  const canAddHomeAddress = () =>
    isAdmissibleAddress(addHomeFieldsTable as AddressLikeObject);

  const invalidAddHomeAddress = () =>
    !!changedAddHomePlaceFieldsList.length && !canAddHomeAddress();

  const canAddWorkAddress = () =>
    isAdmissibleAddress(addWorkFieldsTable as AddressLikeObject);

  const invalidAddWorkAddress = () =>
    !!changedAddWorkPlaceFieldsList.length && !canAddWorkAddress();

  const hasEditableAddresses = () =>
    !!Object.keys(editEmployeeInputDTOs ?? {}).length && isEditMode();

  const [
    createCollaborator,
    { loading: loadingCollaboratorCreate, error: createCollaboratorError },
  ] = useSafeMutation(CREATE_DETAILED_COLLABORATOR);

  const [
    updateCollaborator,
    { loading: loadingCollaboratorUpdate, error: updateCollaboratorError },
  ] = useSafeMutation(UPDATE_COLLABORATOR);

  const [createAddress, { loading: loadingAddAddress }] =
    useSafeMutation(CREATE_ADDRESS);

  const [
    createCollaboratorAddress,
    { loading: loadingCreateCollaboratorAddress },
  ] = useSafeMutation(CREATE_COLLABORATOR_ADDRESS);

  const [updateAddress, { loading: loadingUpdateAddress }] =
    useSafeMutation(UPDATE_ADDRESS);

  const addAddressByType = async (
    addressType: 'work' | 'home',
    employeeId: string
  ) => {
    const payloadInputs = {
      work: addWorkFieldsTable,
      home: addHomeFieldsTable,
    };
    const createAddressPayload = getCreateAddressPayload(
      payloadInputs[addressType]
    );

    const newAddress = await createAddress({
      variables: {
        ...createAddressPayload,
        organizationId: userOrganization?.id,
      },
    });

    const newAddressId = newAddress.data?.createAddress.id ?? '';

    await createCollaboratorAddress({
      variables: {
        collaboratorId: employeeId,
        addressId: newAddressId,
        ...(addressType === 'home' && { isHome: true }),
        ...(addressType === 'work' && { isWork: true }),
      },
    });

    console.debug(`Collaborator-address of type ${addressType} created.`);
  };

  const notifyOnSuccess = () => {
    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent: NotificationMessages.CHANGES_SAVED_SUCCESS,
      })
    );
  };

  const isAddMode = (optionalCondition: boolean = true) =>
    props.formMode === FormModeContext.ADD && optionalCondition;
  const isEditMode = (optionalCondition: boolean = true) =>
    props.formMode === FormModeContext.EDIT && optionalCondition;

  const notifyOnFailure = (
    errorPassed: ApolloError,
    query: any,
    variables: any
  ) => {
    const { graphQLErrors = [{ message: '' }] } = errorPassed;
    let detailedError = null;
    if (graphQLErrors[0]?.message.includes('email must be unique')) {
      detailedError =
        'Update error. Email(s) used must be unique to each employee.';
    } else if (graphQLErrors[0]?.message.match('409')) {
      detailedError =
        'The collaborator has credentials which do not allow the email to be changed. \n To update the work email address for this collaborator, please remove the administrator role first.';
    } else if (
      graphQLErrors[0]?.message.includes('must be a valid phone number')
    ) {
      detailedError = 'Update error. Invalid phone number provided.';
    }

    dispatch(
      notificationSlice.actions.setNotice({
        showNotice: true,
        noticeContent:
          detailedError || NotificationMessages.GENERIC_SOMETHING_WENT_WRONG,
      })
    );
  };

  const onSubmit = async (formData: EmployeeFormModalInputs) => {
    if (isEditMode(!!props.employee)) {
      if (invalidAddHomeAddress() || invalidAddWorkAddress()) return;
      await onEditEmployeeInfoSubmit(formData);
    }

    if (isAddMode()) {
      await onAddEmployeeSubmit(formData);
    }

    props.handleClose();
  };

  const updateAddresses = async () => {
    const postUpdateAddressPromises: Promise<any>[] = [];
    const addressIds = Object.keys(editEmployeeInputDTOs);
    addressIds.forEach((editableAddressId: string) => {
      const inputDTO = getUpdateAddressPayload(
        editEmployeeInputDTOs[editableAddressId]
      );

      postUpdateAddressPromises.push(
        updateAddress({
          variables: {
            ...inputDTO,
            id: editableAddressId,
            organizationId: userOrganization?.id,
          },
          onError: (apolloError: ApolloError) => {
            console.error(`Error updating address ${editableAddressId}.`);
            notifyOnFailure(apolloError, UPDATE_ADDRESS, {
              ...inputDTO,
              id: editableAddressId,
            });
          },
        })
      );
    });
    await Promise.all(postUpdateAddressPromises);

    console.debug("Updated employee's address(es) information.");
  };

  const onEditEmployeeInfoSubmit = async (
    formData: EmployeeFormModalInputs
  ) => {
    if (!props.employee) return;

    if (canAddHomeAddress()) {
      await addAddressByType('home', props.employee.id);
    }

    if (canAddWorkAddress()) {
      await addAddressByType('work', props.employee.id);
    }

    if (hasEditableAddresses()) {
      await updateAddresses();
    }

    if (isDirty) {
      const updateCollaboratorPayload = getUpdateCollaboratorPayload(
        props.employee.id,
        formData
      );

      if (!updateCollaboratorPayload.email)
        updateCollaboratorPayload.email = props.employee.email;

      await updateCollaborator({
        variables: {
          ...updateCollaboratorPayload,
        },
        onError: (apolloError: ApolloError) => {
          notifyOnFailure(
            apolloError,
            UPDATE_COLLABORATOR,
            updateCollaboratorPayload
          );
        },
        onCompleted() {
          props.refetchEmployees();
          notifyOnSuccess();
        },
      });
    }
  };

  const onAddEmployeeSubmit = async (formData: EmployeeFormModalInputs) => {
    if (!userOrganization?.id) return;

    const createCollaboratorPayload = getCreateCollaboratorPayload(
      formData,
      userOrganization?.id ?? ''
    );

    await createCollaborator({
      variables: { ...createCollaboratorPayload },
      onError: (apolloError: ApolloError) => {
        notifyOnFailure(
          apolloError,
          CREATE_DETAILED_COLLABORATOR,
          createCollaboratorPayload
        );
      },
      onCompleted() {
        props.refetchEmployees();
      },
    });
    console.debug('Submitted new employee information.');
  };

  const isLoading = () =>
    [loadingCollaboratorUpdate, loadingCreateCollaboratorAddress].some(Boolean);

  const hasRequiredAddEmployeeFields = () =>
    [
      firstName,
      lastName,
      workEmail || personalEmail,
      userOrganization?.id ?? '',
    ].every(Boolean);

  const canEditEmployee = () =>
    isEditMode() &&
    [
      isDirty,
      hasEditableAddresses(),
      canAddHomeAddress(),
      canAddWorkAddress(),
    ].some(Boolean);

  const isSaveDisabled = () =>
    !canEditEmployee() && !hasRequiredAddEmployeeFields();

  return (
    <NewModal
      form
      open={props.open}
      title={getFormTitle()}
      handleClose={props.handleClose}
      onSubmit={handleSubmit(onSubmit)}
      disableSaveButton={isSaveDisabled()}
      loading={isLoading()}
      dividerPlacement="both"
      formChildrenBoxSx={{
        maxHeight: '420px',
        overflowY: 'scroll',
      }}
      dataTestId="employee-form-modal-testid"
    >
      <Grid container pt="16px">
        <Grid container item xs={12} columnSpacing="25px">
          <Grid item xs={12} pb="12px">
            <Typography variant="body2">Personal Information</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  id="first-name-employee-edit-or-add-form"
                  label="First name"
                  aria-required
                  required
                  data-testid={FIRST_NAME_TESTID}
                  variant="outlined"
                  {...field}
                  {...register('firstName', { required: true })}
                  sx={styles.EmployeeFormModalTextInputBaseSx}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  id="last-name-employee-edit-or-add-form"
                  label="Last name"
                  aria-required
                  required
                  variant="outlined"
                  data-testid={LAST_NAME_TESTID}
                  {...field}
                  {...register('lastName', { required: true })}
                  sx={styles.EmployeeFormModalTextInputBaseSx}
                />
              )}
            />
          </Grid>
          {/* END of ROW */}
          <Grid item xs={12} pb="12px" pt="40px">
            <Typography variant="body2">Contact Information</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <>
              <Controller
                name="workEmail"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="work-email-employee-edit-or-add-form"
                    label="Work Email"
                    variant="outlined"
                    required={
                      Boolean(isEditMode() && props.employee?.workEmail) ||
                      !personalEmail
                    }
                    aria-required
                    data-testid={WORK_EMAIL_TESTID}
                    {...field}
                    {...register('workEmail', {
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
                        message: 'Please enter a valid email',
                      },
                    })}
                    error={!!errors.workEmail}
                    sx={styles.EmployeeFormModalTextInputBaseSx}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <WorkOutline />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {!!errors.workEmail && (
                <Typography variant="body2" color="red">
                  Please enter a valid email
                </Typography>
              )}
            </>
          </Grid>
          <Grid item xs={12} md={4} pl="20px">
            <>
              <Controller
                name="personalEmail"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="personal-email-employee-edit-or-add-form"
                    label="Personal Email"
                    variant="outlined"
                    aria-required
                    required={
                      Boolean(isEditMode() && props.employee?.personalEmail) ||
                      !workEmail
                    }
                    data-testid={PERSONAL_EMAIL_TESTID}
                    {...field}
                    {...register('personalEmail', {
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
                        message: 'Please enter a valid email',
                      },
                    })}
                    error={!!errors.personalEmail}
                    sx={styles.EmployeeFormModalTextInputBaseSx}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HomeOutlined />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {!!errors.workEmail && (
                <Typography variant="body2" color="red">
                  Please enter a valid email
                </Typography>
              )}
            </>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.EmployeeFormModalPhoneGridSx}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <MuiTelInput
                  id="mobile-phone-number-employee-edit-or-add-form"
                  label="Mobile phone number"
                  sx={styles.EmployeeFormModalPhoneInputSx}
                  {...field}
                  {...register('phoneNumber')}
                  onChange={(value, info) => field.onChange(value, info)}
                />
              )}
            />
          </Grid>
          {/* END of ROW */}
          {isEditMode() ? (
            <>
              <EmployeeAddressForm
                addressType="home"
                context={FormModeContext.ADD}
                {...(addHomeFieldsTable as AddressLikeObject)}
                setAddressFieldValues={setAddHomeFieldValues}
                addressAdmissible={isAdmissibleAddress(
                  addHomeFieldsTable as AddressLikeObject
                )}
                addressFieldsRequired={!!changedAddHomePlaceFieldsList.length}
                fieldsState={addHomeFieldsTable}
              />
              <EmployeeAddressForm
                addressType="work"
                context={FormModeContext.ADD}
                {...(addWorkFieldsTable as AddressLikeObject)}
                setAddressFieldValues={setAddWorkFieldValues}
                addressAdmissible={isAdmissibleAddress(
                  addWorkFieldsTable as AddressLikeObject
                )}
                addressFieldsRequired={!!changedAddWorkPlaceFieldsList.length}
                fieldsState={addWorkFieldsTable}
              />
            </>
          ) : (
            <></>
          )}
          {/* START of EDIT Home Addresses */}
          {homeAddresses.length ? (
            homeAddresses.map((address: HomeAddress, index: number) => (
              <EmployeeAddressForm
                key={address.id}
                addressId={address.id}
                defaultInitialState={
                  homeAddressSliceStates[index as number].initialState
                }
                addressType="home"
                context={FormModeContext.EDIT}
                {...getDerivedEditProps(address)}
                {...homeAddressSliceStates[index as number].reducedFieldsTable}
                setAddressFieldValues={
                  homeAddressSliceStates[index as number].setFieldValues
                }
                addressAdmissible={isAdmissibleAddress(
                  homeAddressSliceStates[index as number]
                    .reducedFieldsTable as AddressLikeObject
                )}
                changedFields={
                  homeAddressSliceStates[index as number].changedFieldsList
                }
                addEditEmployeeInputDTO={addEditEmployeeInputDTO}
                removeEditEmployeeInputDTO={removeEditEmployeeInputDTO}
                addressFieldsRequired={
                  !!homeAddressSliceStates[index as number].changedFieldsList
                    .length
                }
                fieldsState={
                  homeAddressSliceStates[index as number].reducedFieldsTable
                }
              />
            ))
          ) : (
            <></>
          )}
          {/* END of EDIT Home Addresses */}
          {/* START of EDIT Work Addresses */}

          {workAddresses.length ? (
            workAddresses.map((address: WorkAddress, index: number) => (
              <EmployeeAddressForm
                key={address.id}
                addressId={address.id}
                defaultInitialState={
                  workAddressSliceStates[index as number].initialState
                }
                addressType="work"
                context={FormModeContext.EDIT}
                {...getDerivedEditProps(address)}
                {...workAddressSliceStates[index as number].reducedFieldsTable}
                setAddressFieldValues={
                  workAddressSliceStates[index as number].setFieldValues
                }
                addressAdmissible={isAdmissibleAddress(
                  workAddressSliceStates[index as number]
                    .reducedFieldsTable as AddressLikeObject
                )}
                changedFields={
                  workAddressSliceStates[index as number].changedFieldsList
                }
                addEditEmployeeInputDTO={addEditEmployeeInputDTO}
                removeEditEmployeeInputDTO={removeEditEmployeeInputDTO}
                addressFieldsRequired={
                  !!workAddressSliceStates[index as number].changedFieldsList
                    .length
                }
                fieldsState={
                  workAddressSliceStates[index as number].reducedFieldsTable
                }
              />
            ))
          ) : (
            <></>
          )}
          {/* END of EDIT Work Addresses */}

          {/* START of BOTTOM FORM */}
          <Grid item xs={12} pt="40px" pb="12px">
            <Typography variant="body2">Employment Information</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="group"
              control={control}
              render={({ field }) => (
                <TextField
                  id="team-employee-edit-or-add-form"
                  label="Team(s)"
                  variant="outlined"
                  sx={styles.EmployeeFormModalTextInputBaseSx}
                  {...field}
                  {...register('group', { required: false })}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="hireDate"
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  id="hire-date-employee-edit-or-add-form"
                  label="Hire date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    placeholder: '',
                  }}
                  sx={styles.EmployeeFormModalTextInputBaseSx}
                  {...field}
                  {...register('hireDate', { required: false })}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  id="start-date-employee-edit-or-add-form"
                  label="Start date"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                    placeholder: '',
                  }}
                  sx={styles.EmployeeFormModalTextInputBaseSx}
                  {...field}
                  {...register('startDate', { required: false })}
                />
              )}
            />
          </Grid>
          {/* END of ROW */}
          {/* END of BOTTOM FORM */}
        </Grid>
      </Grid>
    </NewModal>
  );
};

const withOptionalRetrievedEmployee =
  (WrappedComponent: React.ComponentType<EmployeeFormModalProps>) =>
  (props: EmployeeFormModalProps) => {
    const { data: editableEmployee, loading: loadingEditableEmployee } =
      useSafeQuery(RETRIEVE_EMPLOYEE_BY_ID, {
        variables: { id: props.employee?.id },
        fetchPolicy: 'network-only',
        skip: !props.employee?.id || props.formMode === FormModeContext.ADD,
      });

    const editable = props.formMode === FormModeContext.EDIT;
    const employee = editableEmployee?.collaborator;

    if (editable && (!employee || loadingEditableEmployee)) return null;

    const myProps = { ...props, ...(employee && { employee }) };

    return <WrappedComponent {...myProps} />;
  };

const WrappedEditEmployeeModal =
  withOptionalRetrievedEmployee(EmployeeFormModal);

export default WrappedEditEmployeeModal;
