import React from 'react';
import { useNavigate } from 'react-router-dom';

export const useGoToLink = () => {
  const navigate = useNavigate();
  return React.useCallback((path: string, ignore?: string[]) => {
    return () => {
      if (ignore && ignore.includes(path)) {
        return;
      }
      navigate(path);
    };
  }, []);
};
