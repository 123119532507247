import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationControllerRetrieve } from 'services/openapi/apiComponents';
import { useGlobalState } from 'state';
import { Organization as OrganizationType } from 'types';
import { useDispatch } from 'react-redux';
import { setGlobalGodModeOrganization } from 'store/slices';

const useGetUserOrganization = () => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [globalUserOrganization, setGlobalUserOrganization] =
    useGlobalState('userOrganization');

  useOrganizationControllerRetrieve<OrganizationType>(
    {
      pathParams: {
        id: user?.organizationId,
      },
    },
    {
      enabled: !!user?.organizationId,
      onSuccess: (data) => {
        if (data?.id) {
          setGlobalUserOrganization(data);
          dispatch(setGlobalGodModeOrganization(data));
        }
      },
    }
  );

  return globalUserOrganization;
};

export default useGetUserOrganization;
