/* eslint-disable sort-exports/sort-exports */
import React, { useRef, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as OrderDetailsPanelStyles from '../../styles';
import * as PinsAndKeysStyles from './styles';
import { OrderDetailsPanelProps } from '../../types';
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { obfuscateKey } from './utils';
import CopyButton from 'components/copy_to_clipboard/CopyButton';

const PinsAndKeys = (props: OrderDetailsPanelProps) => {
  const [hidePins, setHidePins] = React.useState(true);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const orderStyles = OrderDetailsPanelStyles;
  const styles = PinsAndKeysStyles;

  const hasNoPins =
    !props.mdmPins?.length &&
    !props.activationKeys?.length &&
    !props.recoveryCodes?.length;

  const toggleHidePins = () => {
    setHidePins((prev) => {
      const newHidePins = !prev;
      if (!newHidePins) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          setHidePins(true);
        }, 300000);
      }
      return newHidePins;
    });
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <Typography
        variant="subtitle2"
        pt="20px"
        pb="10px"
        sx={styles.KeysTitleSx}
      >
        Keys/PINs
        {!hasNoPins ? (
          <Button
            disableRipple
            startIcon={
              hidePins ? <RemoveRedEyeOutlined /> : <VisibilityOffIcon />
            }
            onClick={toggleHidePins}
            sx={styles.ShowHideButtonSx}
          >
            <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
              {hidePins ? 'Show' : 'Hide'}
            </Typography>
          </Button>
        ) : null}
      </Typography>
      <Stack sx={orderStyles.OrderDetailsInfoStackSx} direction="row">
        {hasNoPins ? (
          <Grid
            container
            sx={orderStyles.OrderDetailsInfoGridSx}
            className="pin-grid"
          >
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="body1">
                No keys/PINs provided to process this device
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            sx={orderStyles.OrderDetailsInfoGridSx}
            className="pin-grid"
          >
            <Grid item xs={12} sm={12} md={12}>
              <Stack
                direction="row"
                display="flex"
                spacing="8px"
                alignItems="center"
                sx={styles.PinStackSx}
              >
                <Typography variant="overline" color="colors.gray60">
                  MDM PIN(S)
                </Typography>
                <CopyButton copyValue={props.mdmPins?.join(', ') || ''} />
                {props.mdmPins?.length > 0 ? (
                  <Typography variant="body1">
                    {props.mdmPins?.map((pin: string, index: number) => (
                      <Chip
                        key={`mdm-pin-${index}`}
                        size="small"
                        label={!hidePins ? pin : obfuscateKey(pin)}
                      />
                    ))}
                  </Typography>
                ) : (
                  <Typography variant="body1" color="colors.gray60">
                    No MDM PINs provided
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack
                direction="row"
                display="flex"
                spacing="8px"
                alignItems="center"
                sx={styles.PinStackSx}
              >
                <Typography variant="overline" color="colors.gray60">
                  ACTIVATION KEY(S)
                </Typography>
                <CopyButton
                  copyValue={props.activationKeys?.join(', ') || ''}
                />
                {props.activationKeys?.length > 0 ? (
                  <Typography variant="body1">
                    {props.activationKeys?.map((pin: string, index: number) => (
                      <Chip
                        key={`ac-pin-${index}`}
                        size="small"
                        label={!hidePins ? pin : obfuscateKey(pin)}
                      />
                    ))}
                  </Typography>
                ) : (
                  <Typography variant="body1" color="colors.gray60">
                    No Activation Keys provided
                  </Typography>
                )}
              </Stack>
            </Grid>{' '}
            <Grid item xs={12} sm={12} md={12}>
              <Stack
                direction="row"
                display="flex"
                spacing="8px"
                alignItems="center"
                sx={styles.PinStackSx}
              >
                <Typography variant="overline" color="colors.gray60">
                  RECOVERY KEY(S)
                </Typography>{' '}
                <CopyButton copyValue={props.recoveryCodes?.join(', ') || ''} />
                {props.recoveryCodes?.length > 0 ? (
                  <Typography variant="body1">
                    {props.recoveryCodes?.map((pin: string, index: number) => (
                      <Chip
                        key={`rc-pin-${index}`}
                        size="small"
                        label={!hidePins ? pin : obfuscateKey(pin)}
                      />
                    ))}
                  </Typography>
                ) : (
                  <Typography variant="body1" color="colors.gray60">
                    No Recovery Keys provided
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
        )}
      </Stack>
    </>
  );
};

export default PinsAndKeys;
