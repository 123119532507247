import { ColumnState, ColumnValue } from 'store/slices/shared/columns/types';
import { AssetColumnKey } from './types';

export const INITIAL_STATE: () => ColumnState<AssetColumnKey> = () => {
  const columns: ColumnValue<AssetColumnKey>[] = [
    { id: '', displayName: 'Asset #', key: 'asset-number', active: true },
    { id: '', displayName: 'Type', key: 'type', active: true },
    { id: '', displayName: 'Make', key: 'make', active: true },
    { id: '', displayName: 'Model', key: 'model', active: true },
    { id: '', displayName: 'Condition', key: 'condition', active: true },
    {
      id: '',
      displayName: 'Keyboard config',
      key: 'keyboard-configuration',
      active: true,
    },
    {
      id: '',
      displayName: 'Depot location',
      key: 'depot-location',
      active: true,
    },
    {
      id: '',
      displayName: 'Purchase date',
      key: 'purchase-date',
      active: true,
    },
    {
      id: '',
      displayName: 'Last updated at',
      key: 'updated-at',
      active: true,
    },
    { id: '', displayName: 'Status', key: 'status', active: true },
    { id: '', displayName: 'Assignee', key: 'assignee', active: false },
    { id: '', displayName: 'Color', key: 'color', active: false },
    {
      id: '',
      displayName: 'Date of last check-in',
      key: 'date-of-last-check-in',
      active: false,
    },
    {
      id: '',
      displayName: 'Date of last check-out',
      key: 'date-of-last-check-out',
      active: false,
    },
    {
      id: '',
      displayName: 'Device issue',
      key: 'device-issue',
      active: false,
    },
    {
      id: '',
      displayName: 'Display size',
      key: 'display-size',
      active: false,
    },
    { id: '', displayName: 'Memory', key: 'memory', active: false },
    {
      id: '',
      displayName: 'Personal email',
      key: 'personal-email',
      active: false,
    },
    { id: '', displayName: 'Processor', key: 'processor', active: false },
    {
      id: '',
      displayName: 'Serial number',
      key: 'serial-number',
      active: false,
    },
    { id: '', displayName: 'Storage', key: 'storage', active: false },
    { id: '', displayName: 'Work email', key: 'work-email', active: false },
    {
      id: '',
      displayName: 'Notes',
      key: 'notes',
      active: true,
      hidden: true,
    },
  ];
  columns.forEach((column, index) => {
    column.id = (index + 1).toString();
  });
  return {
    popover: {
      open: false,
      changed: false,
      searchInput: '',
    },
    initialized: true,
    columns,
  };
};
