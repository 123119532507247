/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/system';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Pill } from 'components';
import OrderStatusPill from 'components/OrderStatusPill';
import { OrderManager } from 'types';
import { formatDate } from 'services/date';
import { useMediaQuery } from '@mui/material';
import { MANAGERS_ROLE, OM_SORT_MODELS } from 'pages/OrderManagement/constants';
import useGetManagersColumn from 'pages/OrderManagement/hooks/useGetManagersColumn';
import { OM_COLUMNS_KEYS } from 'store/slices/order_management/columns/constants';
import { selectColumns } from 'store/slices/order_management/columns/selectors';
import { OrderManagementColumnKey } from 'store/slices/order_management/columns/types';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useGetOrderManagementColumns = (
  mutate: any,
  managersByRole: Record<string, OrderManager[]>
): GridColDef[] => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const orderManagerColumn = useGetManagersColumn(
    mutate,
    managersByRole,
    MANAGERS_ROLE.ORDER_MANAGEMENT
  );

  const procurementManagerColumn = useGetManagersColumn(
    mutate,
    managersByRole,
    MANAGERS_ROLE.PROCUREMENT
  );

  const assetManagerColumn = useGetManagersColumn(
    mutate,
    managersByRole,
    MANAGERS_ROLE.ASSET_MANAGEMENT
  );

  const otherManagerColumn = useGetManagersColumn(
    mutate,
    managersByRole,
    MANAGERS_ROLE.OTHER
  );

  const config: Record<OrderManagementColumnKey, GridColDef> = {
    ORDER_MANAGEMENT: orderManagerColumn,
    PROCUREMENT: procurementManagerColumn,
    ASSET_MANAGEMENT: assetManagerColumn,
    OTHER: otherManagerColumn,
    orderNumber: {
      field: OM_COLUMNS_KEYS.ORDER_NUMBER,
      headerName: 'Order number',
      flex: isLargeScreen ? 1.5 : 1,
      minWidth: 120,
      sortable: !!OM_SORT_MODELS[OM_COLUMNS_KEYS.ORDER_NUMBER],
      filterable: false,
      valueGetter(params) {
        return params?.row?.orderNumber;
      },
    },
    orderType: {
      field: OM_COLUMNS_KEYS.ORDER_TYPE,
      headerName: 'Order type',
      flex: isLargeScreen ? 2 : 1,
      sortable: !!OM_SORT_MODELS[OM_COLUMNS_KEYS.ORDER_TYPE],
      filterable: false,
      renderCell(params) {
        return (
          <Pill text={params.row?.orderType?.name} color="colors.mercury" />
        );
      },
    },
    status: {
      field: OM_COLUMNS_KEYS.STATUS,
      headerName: 'Order Status',
      flex: isLargeScreen ? 1.5 : 1,
      sortable: !!OM_SORT_MODELS[OM_COLUMNS_KEYS.STATUS],
      filterable: false,
      renderCell(params) {
        return <OrderStatusPill status={params.row?.status} />;
      },
    },
    shippingType: {
      field: OM_COLUMNS_KEYS.SHIPPING_TYPE,
      headerName: 'Shipping',
      flex: 1,
      sortable: !!OM_SORT_MODELS[OM_COLUMNS_KEYS.SHIPPING_TYPE],
      filterable: false,
      valueGetter(params) {
        return params.row?.shippingType?.name ?? '';
      },
    },
    dueDate: {
      field: OM_COLUMNS_KEYS.DUE_DATE,
      headerName: 'Due date',
      flex: isLargeScreen ? 1.25 : 1,
      sortable: !!OM_SORT_MODELS[OM_COLUMNS_KEYS.DUE_DATE],
      filterable: false,
      valueGetter(params) {
        if (!params.row?.dueDate) return '';
        return formatDate(params.row?.dueDate);
      },
    },
    recipientName: {
      field: OM_COLUMNS_KEYS.RECIPIENT_NAME,
      headerName: 'Recipient name',
      flex: isLargeScreen ? 1.5 : 1,
      sortable: !!OM_SORT_MODELS[OM_COLUMNS_KEYS.RECIPIENT_NAME],
      filterable: false,
      valueGetter(params) {
        if (params?.row?.dropRecipient) {
          return `${params?.row?.dropRecipient?.firstName} ${params?.row?.dropRecipient?.lastName}`;
        }
        if (params.row.recipient) {
          return `${params?.row?.recipient?.firstName} ${params?.row?.recipient?.lastName}`;
        }
        return '';
      },
    },
    recipientEmail: {
      field: OM_COLUMNS_KEYS.RECIPIENT_EMAIL,
      headerName: 'Recipient email',
      flex: isLargeScreen ? 2 : 1,
      sortable: !!OM_SORT_MODELS[OM_COLUMNS_KEYS.RECIPIENT_EMAIL],
      filterable: false,
      valueGetter(params) {
        if (params?.row?.dropRecipient) {
          params?.row?.dropRecipient?.email;
        }
        if (params?.row?.recipient) {
          return params?.row?.recipient?.email;
        }
        return '';
      },
    },
    account: {
      field: OM_COLUMNS_KEYS.ACCOUNT,
      headerName: 'Account',
      flex: isLargeScreen ? 1.25 : 1,
      sortable: !!OM_SORT_MODELS[OM_COLUMNS_KEYS.ACCOUNT],
      filterable: false,
      valueGetter(params) {
        return (
          (params.row?.organizationObject?.name ?? '') ||
          (params.row?.organization?.name ?? '')
        );
      },
    },
    purchaser: {
      field: OM_COLUMNS_KEYS.PURCHASER,
      headerName: 'Purchaser',
      flex: isLargeScreen ? 1.5 : 1,
      sortable: !!OM_SORT_MODELS[OM_COLUMNS_KEYS.PURCHASER],
      filterable: false,
      valueGetter(params) {
        return params.row?.purchaser
          ? `${params.row?.purchaser?.firstName} ${params.row?.purchaser?.lastName}`
          : '-';
      },
    },
    depot: {
      field: OM_COLUMNS_KEYS.DEPOT,
      headerName: 'Depot location',
      flex: isLargeScreen ? 2 : 1,
      sortable: !!OM_SORT_MODELS[OM_COLUMNS_KEYS.DEPOT],
      filterable: false,
      valueGetter(params) {
        return params.row?.depot?.name ?? '';
      },
    },
  };

  const columns = useSelector(selectColumns);

  const [columnsConfig, setColumnsConfig] = React.useState<GridColDef[]>([]);

  const mapping: OrderManagementColumnKey[] = columns.map(
    (column) => column.key
  );
  const atLeastOneColumnOn = columns.some((column) => column.active);

  React.useEffect(() => {
    const currentColumnsConfig: GridColDef[] = mapping.map((key) => {
      return config[key];
    });
    setColumnsConfig(currentColumnsConfig);
  }, [
    columns,
    atLeastOneColumnOn,
    orderManagerColumn,
    procurementManagerColumn,
    assetManagerColumn,
    otherManagerColumn,
  ]);
  return columnsConfig;
};
