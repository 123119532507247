import theme from 'themes/theme';

export const OrderDetailsNotesTextFieldSx = {
  borderBottom: '1px solid',
  borderRadius: 0,
  height: 'calc(100% - 70px)',
  overflowY: 'scroll',
  width: '100%',
  p: 0,
  '& .MuiFormLabel-root': {
    left: '5px',
    '&.Mui-focused': {
      color: 'inherit',
      opacity: '.7',
    },
  },

  '& .MuiInputBase-input': {
    color: theme.palette.colors.gray60,
  },
  '& .MuiInputBase-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
    },
  },
};

export const OrderDetailsNotesSubmitButtonSx = {
  mt: '16px',

  '&.Mui-disabled.MuiLoadingButton-root': {
    border: '1px solid rgba(249, 197, 77, 0.5)',
  },
  '.MuiLoadingButton-loadingIndicator': {
    color: theme.palette.colors.maizeYO,
  },

  '& .MuiTypography-root': {
    fontWeight: '600',
  },
};

export const HeaderSx = {
  mb: '12px',
  fontWeight: '600',
  fontSize: '14px',
  color: '131414',
};
