import { createSlice } from '@reduxjs/toolkit';

import {
  getJamfConnectionPendingReducer,
  getJamfConnectionFulfilledReducer,
  getJamfConnectionRejectedReducer,
  postJamfConnectionPendingReducer,
  postJamfConnectionFulfilledReducer,
  postJamfConnectionRejectedReducer,
  putJamfConnectionPendingReducer,
  putJamfConnectionFulfilledReducer,
  putJamfConnectionRejectedReducer,
} from './reducers';
import { INITIAL_STATE } from './constants';
import {
  getJamfConnection,
  postJamfConnection,
  putJamfConnection,
} from './actions';

export const jamfConnectionSlice = createSlice({
  name: 'jamfConnectionSlice',
  initialState: INITIAL_STATE(),
  reducers: {
    resetState: () => INITIAL_STATE(),
  },
  extraReducers: (builder) => {
    builder.addCase(getJamfConnection.pending, getJamfConnectionPendingReducer);
    builder.addCase(
      getJamfConnection.fulfilled,
      getJamfConnectionFulfilledReducer
    );
    builder.addCase(
      getJamfConnection.rejected,
      getJamfConnectionRejectedReducer
    );
    builder.addCase(
      postJamfConnection.pending,
      postJamfConnectionPendingReducer
    );
    builder.addCase(
      postJamfConnection.fulfilled,
      postJamfConnectionFulfilledReducer
    );
    builder.addCase(
      postJamfConnection.rejected,
      postJamfConnectionRejectedReducer
    );
    builder.addCase(putJamfConnection.pending, putJamfConnectionPendingReducer);
    builder.addCase(
      putJamfConnection.fulfilled,
      putJamfConnectionFulfilledReducer
    );
    builder.addCase(
      putJamfConnection.rejected,
      putJamfConnectionRejectedReducer
    );
  },
});
const { resetState } = jamfConnectionSlice.actions;

export { resetState };
