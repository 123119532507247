import { INITIAL_STATE } from './constants';
import { columnsSlice } from 'store/slices/shared/columns';

export const orderManagementColumnsSlice = columnsSlice(
  'orderManagementColumnsSlice',
  INITIAL_STATE
);

const {
  setColumnsOpenReducer: setColumnsOpen,
  setColumnsChangedReducer: setColumnsChanged,
  setColumnsReducer: setColumns,
  swapColumnsReducer: swapColumns,
  setAllColumnsReducer: setAllColumns,
  resetStateReducer: resetState,
  setSearchInputReducer: setSearchInput,
  setAllColumnsActiveReducer: setAllColumnsActive,
  setInitializedReducer: setInitialized,
} = orderManagementColumnsSlice.actions;

export {
  setColumnsOpen,
  setColumnsChanged,
  setColumns,
  setAllColumns,
  swapColumns,
  resetState,
  setSearchInput,
  setAllColumnsActive,
  setInitialized,
};
