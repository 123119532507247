const diff = '40px';

export const ContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  width: '60%',
  position: 'relative',
};

export const InfoSectionHeaderSx = {
  fontSize: '16px',
  fontWeight: 600,
  color: '#000',
  width: 'calc(100% - 10px)',
  display: 'flex',
  flexDirection: 'row',
  mt: '20px',
  mb: '10px',
};

export const InfoSectionHeaderTextSx = {
  fontSize: '16px',
  fontWeight: 600,
  color: '#000',
  flex: 1,
  display: 'flex',
  width: '50%',
};

export const DividerSx = {
  pt: diff,
  mt: '10px',
  mb: '10px',
  width: 'calc(100% - 12px)',
  position: 'absolute',
  bottom: '0',
};

export const ContentContainerSx = {
  width: '100%',
  height: '100%',
};

export const InfoSx = {
  fontSize: '14px',
  color: '#666',
  display: 'inline-block',
  fontWeight: 600,
  textDecoration: 'underline',
};

export const InfoContainerSx = {
  fontSize: '14px',
  color: '#666',
  display: 'inline-block',
  mb: '20px',
};

export const AlertSx = {
  mb: '20px',
  backgroundColor: 'rgba(77, 183, 198, 0.1)',
  '& svg': {
    color: '#4DB7C6',
  },
  '& a': {
    color: 'gray',
    textDecoration: 'underline',
    '&:hover': {
      color: 'black',
    },
  },
};

export const FormInputFieldContainerSx = {
  width: '100%',
  borderRadius: '0px',
  backgroundColor: 'white',
  mb: '10px',
  pl: 0,
  '& .MuiInputBase-root': {
    borderRadius: '8',
  },
};
