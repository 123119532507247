import React from 'react';
import {
  AUTOCOMPLETE_HELPER_TEXT,
  AUTOCOMPLETE_HELPER_TEXT_MAX,
} from './constants';

export const helperText = (
  key: string,
  list: string[],
  isFocused: string,
  setIsFocused: React.Dispatch<React.SetStateAction<string>>
) => {
  const isMax = list?.length >= 3;
  const helperProps = {
    helperText:
      isFocused === key
        ? `${AUTOCOMPLETE_HELPER_TEXT} ${key === 'mdmPins' ? 'PIN' : 'Key'}`
        : null,
    onFocus: () => setIsFocused(key),
    onBlur: () => setIsFocused(''),
    sx: {},
  };
  if (isMax) {
    helperProps.sx = {
      '& .MuiFormHelperText-root': {
        color: 'red',
      },
    };
    helperProps.helperText =
      isFocused === key ? AUTOCOMPLETE_HELPER_TEXT_MAX : null;
  }
  return helperProps;
};
