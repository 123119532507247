import { SxProps } from '@mui/material';

export const PinStackSx: SxProps = {
  '& .MuiIconButton-root': {
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  '& .MuiChip-root': {
    marginRight: '8px',
  },
  '> span': {
    width: '132px',
  },
};

export const ShowHideButtonSx: SxProps = {
  svg: {
    fill: 'rgba(0,0,0,0.54)',
  },
  '> p': {
    color: '#131414',
  },
};

export const KeysTitleSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
