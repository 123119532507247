import { NewOrderItem } from 'types';
import { ExtendedProductVariant } from './types';

export const getUserIdFullNameTuple = (users: Record<string, any>[]) =>
  users.map((user) => {
    const { id, firstName = '', lastName = '' } = user;
    const spacer = firstName && lastName ? ' ' : '';
    return [id, `${firstName}${spacer}${lastName}`.trimEnd()];
  });

export const sortUsersByLastName = (users: Record<string, any>[]) =>
  users
    .slice()
    .sort((a: Record<string, any>, b: Record<string, any>) =>
      (a?.firstName ?? '')
        .toLowerCase()
        .replace(' ', '')
        .localeCompare((b?.firstName ?? '').toLowerCase().replace(' ', ''))
    )
    .sort((c: Record<string, any>, d: Record<string, any>) =>
      (c?.lastName ?? '')
        .toLowerCase()
        .replace(' ', '')
        .localeCompare((d?.lastName ?? '').toLowerCase().replace(' ', ''))
    );

export const getOrderItemCopyValue = (
  quantity: number,
  productVariant?: ExtendedProductVariant
) => {
  if (!productVariant) return '';
  const { product, productVariantAttributes = [] } = productVariant;
  if (!product) return '';
  const attributesText =
    productVariantAttributes.length > 0
      ? `\n${productVariantAttributes.map((attr) => attr.value).join(', ')}`
      : '';

  return `${quantity}X\n${product.name}${attributesText}`;
};

export const getAllOrderItemsCopyValue = (
  orderItems: NewOrderItem[],
  shippingSpeed?: string
) => {
  const groupedItemsForCopy = orderItems.reduce(
    (
      acc: Record<
        string,
        { quantity: number; productVariant: ExtendedProductVariant }
      >,
      orderItem: NewOrderItem
    ) => {
      const { quantity, productVariant } = orderItem;
      if (!productVariant) return acc;

      const { id } = productVariant;
      if (!acc[id]) {
        acc[id] = { quantity: quantity ?? 0, productVariant };
      } else {
        acc[id].quantity += quantity ?? 0;
      }
      return acc;
    },
    {}
  );

  if (Object.keys(groupedItemsForCopy).length === 0) return '';

  const copyItems = Object.values(groupedItemsForCopy)
    .sort((obj1, obj2) => obj2.quantity - obj1.quantity)
    .map(({ quantity, productVariant }) => {
      return getOrderItemCopyValue(quantity, productVariant);
    })
    .join('\n\n');

  const shippingSpeedText = shippingSpeed
    ? `\n\nShipping Speed: ${shippingSpeed}`
    : '';
  return `${copyItems}\n\n[PROTECTION PLAN]\n\nEnrolled in ABM / With CSV. Hash Extraction${shippingSpeedText}`;
};
