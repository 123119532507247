export enum ProcurementFlowStep {
  COLLABORATOR_VIEW = 'COLLABORATOR_VIEW',
  PRODUCT_CATALOG = 'PRODUCT_CATALOG',
  ORDER_SUMMARY = 'ORDER_SUMMARY',
  ORDER_SUBMITTED = 'ORDER_SUBMITTED',
}

export enum ProcurementServiceActions {
  ADD_PRODUCT_VARIANT_TO_CART = 'ADD_PRODUCT_VARIANT_TO_CART',
  ADD_SELECTED_COLLABORATOR = 'ADD_SELECTED_COLLABORATOR',
  CHECKOUT_ORDER = 'CHECKOUT_ORDER',
  DESELECT_ALL_COLLABORATORS = 'DESELECT_ALL_COLLABORATORS',
  DISMISS_CURRENT_MODAL = 'DISMISS_CURRENT_MODAL',
  REMOVE_PRODUCT_VARIANT_FROM_CART = 'REMOVE_PRODUCT_VARIANT_FROM_CART',
  REMOVE_SELECTED_COLLABORATOR = 'REMOVE_SELECTED_COLLABORATOR',
  SET_CHECKOUT_NOTES = 'SET_CHECKOUT_NOTES',
  SET_CURRENT_MODAL = 'SET_CURRENT_MODAL',
  RESET = 'RESET',
  UPDATE_EXPERIENCE_STEP = 'UPDATE_EXPERIENCE_STEP',
  UPDATE_PRODUCT_VARIANT_QUANTITY_IN_CART = 'UPDATE_PRODUCT_VARIANT_QUANTITY_IN_CART',
  ADD_SELECTED_DEPOT = 'ADD_SELECTED_DEPOT',
  REMOVE_SELECTED_DEPOT = 'REMOVE_SELECTED_DEPOT',
  UPDATE_QUOTE_REQUESTED = 'UPDATE_QUOTE_REQUESTED',
  UPDATE_SHIPPING_TYPE_ID = 'UPDATE_SHIPPING_TYPE_ID',
  CLEAR_SHIPPING_TYPE_ID = 'CLEAR_SHIPPING_TYPE_ID',
  UPDATE_SELECTED_COLLABORATOR_RECIPIENT_EMAIL = 'UPDATE_SELECTED_COLLABORATOR_RECIPIENT_EMAIL',
  UPDATE_EMAIL_TYPE_FOR_ALL_COLLABORATORS = 'UPDATE_EMAIL_TYPE_FOR_ALL_COLLABORATORS',
  UPDATE_SELECTED_COLLABORATOR_RECIPIENT_ADDRESS = 'UPDATE_SELECTED_COLLABORATOR_RECIPIENT_ADDRESS',
  REMOVE_SELECTED_COLLABORATOR_RECIPIENT_ADDRESS = 'REMOVE_SELECTED_COLLABORATOR_RECIPIENT_ADDRESS',
  ADD_SELECTED_COLLABORATOR_ADDRESSES = 'ADD_SELECTED_COLLABORATOR_ADDRESSES',
  ADD_SELECTED_COLLABORATOR_SELECTABLE_ADDRESSES = 'ADD_SELECTED_COLLABORATOR_SELECTABLE_ADDRESSES',
  SET_DEVICE_COLOR_SWAP_APPROVED = 'SET_DEVICE_COLOR_SWAP_APPROVED',
  CLEAR_DEVICE_COLOR_SWAP_APPROVED = 'CLEAR_DEVICE_COLOR_SWAP_APPROVED',
  SET_FLEXIBLE_LEAD_TIME_APPROVED = 'SET_FLEXIBLE_LEAD_TIME_APPROVED',
  CLEAR_FLEXIBLE_LEAD_TIME_APPROVED = 'CLEAR_FLEXIBLE_LEAD_TIME_APPROVED',
}

// Consider moving ProcurementFlowModals to global state
// This will make rendering modals behave like a `singleton`
// in which only one modal can be rendered at a time
export enum ProcurementFlowModals {
  NONE = 'NONE',
  ADD_NEW_PERSON = 'ADD_NEW_PERSON',
  PRODUCT_DETAILS = 'PRODUCT_DETAILS',
  SELECTED_COLLABORATORS_EDIT = 'SELECTED_COLLABORATORS_EDIT',
}

export enum ProcurementFlowEmailType {
  PERSONAL = 'personalEmail',
  WORK = 'workEmail',
  NONE = '',
}
