import React from 'react';
import Stack from '@mui/material/Stack';
import { InfoSectionSubHeaderSx } from './styles';

interface Props {
  'data-testid'?: string;
}

const InfoSectionSubHeader: React.FC<Props> = ({
  'data-testid': dataTestId,
}) => {
  return (
    <Stack sx={InfoSectionSubHeaderSx} data-testid={dataTestId}>
      allwhere supports 60+ integrations with the leading HRIS, Payroll and SCIM
      Directories including Okta, HiBob, Justworks, Gusto, Workday, Paylocity,
      Sage, UKG, ADP, BambooHR, and many more
    </Stack>
  );
};

export default InfoSectionSubHeader;
