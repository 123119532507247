export const ContainerSx = {
  mt: '30px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export const FormInputFieldContainerSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  padding: '0px',
  color: 'gray',
  width: '100%',
};

export const FormInputFieldLabelSx = {
  color: 'black',
};

export const FormInputFieldValueSx = {
  color: 'gray',
  pl: '10px',
};
