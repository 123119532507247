import moment from 'moment/moment';

export const downloadFile = (url: string, name: string, extension: string) => {
  const now = moment().format('YYYY-MM-DD-HH-mm-ss');
  const link = document.createElement('a');
  link.href = url;
  link.download = `${name}-${now}.${extension}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const createCSVFile = (
  data: string,
  name: string,
  extension: string
) => {
  const now = moment().format('YYYY-MM-DD-HH-mm-ss');
  const filename = `${name}-${now}.${extension}`;
  const blob = new File([data], filename, { type: 'text/csv' });
  const a = document.createElement('a');
  const url = URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const showProcurementPreferencesSection = (
  deviceColorSwapApproved: boolean | null,
  flexibleLeadTimeApproved: boolean | null
) => {
  return (
    (deviceColorSwapApproved ?? null) !== null &&
    flexibleLeadTimeApproved !== null
  );
};
