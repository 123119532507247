import { SxProps } from '@mui/material';

export const ExternalLinksContainerSx: SxProps = {
  height: '100%',
  minHeight: '150px',
};

export const HeaderSx: SxProps = {
  marginBottom: '8px',
  fontWeight: '600',
};
