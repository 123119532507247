import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { notificationSlice } from 'store/slices';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import NewModal from 'components/NewModal';
import { useSafeMutation } from 'hooks/useSafeMutation';
import { CREATE_PROCESS_DEVICE_ORDER } from './mutations';
import AutoCompleteInput from './components/AutoCompleteInput'; // Reusable Component
import { ProcessDeviceModalProps } from './types';
import {
  PROCESS_DEVICE_TITLE,
  PROCESS_DEVICE_SUBTITLE,
  SAVE_BUTTON_COPY,
  WIPE_CONFIRMATION_CHECKBOX_LABEL,
} from './constants';
import { AssetStatus } from 'global-constants';
import GALoadingButton from 'components/buttons/google_analytics/GALoadingButton';
import {
  DeviceInfoBoxSx,
  OuterStackPropsSx,
  PaperPropsSx,
  TitleIconSx,
  ProcessBoxSx,
  CartBoxSx,
  ProcessSectionSx,
  PinBoxSx,
  CheckoutBoxSx,
} from './styles';
import AssetDetails from 'components/AssetDetails';
import { NewModalSubmitButtonSx } from 'components/NewModal/styles';

const ProcessDeviceModal = (props: ProcessDeviceModalProps) => {
  if (!props.device || !props.organizationId || !props.purchaserId)
    return <></>;

  const dispatch = useDispatch();
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      confirmedWipe: false,
      noKeysPins: false,
      mdmPins: [],
      activationKeys: [],
      recoveryCodes: [],
    },
  });

  const noKeysPins = watch('noKeysPins');
  const confirmedWipe = watch('confirmedWipe');
  const mdmPins = watch('mdmPins');
  const activationKeys = watch('activationKeys');
  const recoveryCodes = watch('recoveryCodes');

  const [mutate, { loading }] = useSafeMutation(CREATE_PROCESS_DEVICE_ORDER);

  const onSubmit = (data: any) => {
    mutate({
      variables: {
        purchaserId: props.purchaserId,
        assetId: props.device?.id,
        mdmPins: data.noKeysPins ? [] : data.mdmPins,
        activationKeys: data.noKeysPins ? [] : data.activationKeys,
        recoveryCodes: data.noKeysPins ? [] : data.recoveryCodes,
      },
      onError(error) {
        console.warn('Mutation error:', error);
      },
      onCompleted() {
        if (props.refetchSearchAssets) props.refetchSearchAssets();
        props.deviceActionsEnablement.enableActionsByDevice({
          ...props.device,
          status: AssetStatus.PROCESSING_DEVICE,
        });
        dispatch(
          notificationSlice.actions.setNotice({
            showNotice: true,
            noticeContent: NotificationMessages.PROCESS_REQUEST_SENT,
          })
        );
        props.handleClose();
      },
    });
  };

  const disabledSave =
    !confirmedWipe ||
    loading ||
    !(
      noKeysPins ||
      mdmPins.length ||
      activationKeys.length ||
      recoveryCodes.length
    );

  return (
    <NewModal
      form
      open={props.open}
      title={PROCESS_DEVICE_TITLE}
      handleClose={props.handleClose}
      onSubmit={handleSubmit(onSubmit)}
      submitButtonCopy={SAVE_BUTTON_COPY}
      disableSaveButton={disabledSave}
      hideCTAs
      loading={loading}
      paperPropsSx={PaperPropsSx}
      outerStackPropsSx={OuterStackPropsSx}
      dividerPlacement="top"
      TitleIcon={<SettingsOutlinedIcon sx={TitleIconSx} />}
    >
      <Box sx={ProcessBoxSx}>
        <Box sx={DeviceInfoBoxSx}>
          <Typography variant="body2">{PROCESS_DEVICE_SUBTITLE}</Typography>
          <Box sx={ProcessSectionSx}>
            <Typography variant="h6">Asset</Typography>
            <AssetDetails device={props.device} />
          </Box>

          <Box sx={ProcessSectionSx}>
            <Typography variant="h6">Wipe this device?*</Typography>
            <Typography variant="body2">
              All data on this device will be deleted, and the device will be
              reset to factory settings.
            </Typography>
            <FormGroup>
              <Controller
                name="confirmedWipe"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        {...field}
                        checked={field.value}
                      />
                    }
                    label={WIPE_CONFIRMATION_CHECKBOX_LABEL}
                  />
                )}
              />
            </FormGroup>
          </Box>

          <Box sx={ProcessSectionSx}>
            <Typography variant="h6">Enter keys/PINs*</Typography>
            <Typography variant="body2">
              Provide up to 3 keys/PINs in each field to unlock the device.
            </Typography>
            <FormGroup>
              <Controller
                name="noKeysPins"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        {...field}
                        checked={field.value}
                      />
                    }
                    label="No keys/PINs required to process this device"
                  />
                )}
              />
            </FormGroup>

            {!noKeysPins && (
              <Box sx={PinBoxSx}>
                <AutoCompleteInput
                  name="mdmPins"
                  label="MDM Pins"
                  control={control}
                />
                <AutoCompleteInput
                  name="activationKeys"
                  label="Activation Keys"
                  control={control}
                />
                <AutoCompleteInput
                  name="recoveryCodes"
                  label="Recovery Codes"
                  control={control}
                />
              </Box>
            )}
          </Box>
        </Box>

        {/* CART SECTION - KEPT INTACT */}
        <Box sx={CartBoxSx}>
          <Typography variant="h6">Summary</Typography>
          <Box sx={CheckoutBoxSx}>
            <Box className="cart-item">
              <Typography variant="body2">Subtotal (est)*</Typography>
              <Typography variant="body2">$10.00</Typography>
            </Box>
            <Box className="cart-item">
              <Typography variant="body2">Service fee (est)*</Typography>
              <Typography variant="body2">$0.00</Typography>
            </Box>
            <Box className="cart-item">
              <Typography variant="body2">Tax (est)**</Typography>
              <Typography variant="body2">$0.00</Typography>
            </Box>
            <Box className="cart-divider"></Box>
            <Box className="cart-item">
              <Typography variant="body2">
                <b>Total**</b>
              </Typography>
              <Typography variant="body2">
                <b>$10.00</b>
              </Typography>
            </Box>
          </Box>
          <GALoadingButton
            gaContext={{
              navigates_to: 'N/A',
              purpose: 'Submit order',
              textCopy: 'Submit order',
            }}
            disabled={disabledSave}
            variant="contained"
            type="submit"
            loading={loading}
            color="primary"
            sx={NewModalSubmitButtonSx}
            style={{ width: '100%' }}
          >
            <Typography variant="button">{SAVE_BUTTON_COPY}</Typography>
          </GALoadingButton>
        </Box>
      </Box>
    </NewModal>
  );
};

export default ProcessDeviceModal;
