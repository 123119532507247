import React from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useSelector } from 'react-redux';

import FormInputs from '../FormInputs';
import FormView from '../FormView';
import InfoSectionSubHeader from '../InfoSectionSubHeader';

import { selectCredentialsView } from 'store/slices/integrations/jamf/settings/selectors';
import { useOnHandleViewChange } from './hooks';
import { selectJamfConnection } from 'store/slices/integrations/jamf/connect/selectors';

import { Props } from './types';
import {
  ContainerSx,
  InfoSectionHeaderSx,
  InfoSectionHeaderTextSx,
  InfoSectionHeaderActionSx,
  DividerSx,
  ContentContainerSx,
} from './styles';

const JamfCredentialsForm: React.FC<Props> = (props) => {
  const { form, type, 'data-testid': dataTestId } = props;
  const view = useSelector(selectCredentialsView);
  const connection = useSelector(selectJamfConnection);
  const isConnect = type === 'connect';

  const handleOnViewChange = useOnHandleViewChange(form, connection);

  if (isConnect) {
    return (
      <Stack sx={ContainerSx} data-testid={dataTestId}>
        <Stack sx={InfoSectionHeaderSx} data-testid={`${dataTestId}-header`}>
          Account
        </Stack>
        <InfoSectionSubHeader />
        <FormInputs form={form} data-testid={`${dataTestId}-form-inputs`} />
      </Stack>
    );
  }
  return (
    <Stack sx={ContainerSx} data-testid={dataTestId}>
      <Stack sx={InfoSectionHeaderSx}>
        <Stack
          sx={InfoSectionHeaderTextSx}
          data-testid={`${dataTestId}-header-text`}
        >
          Account
        </Stack>
        {view === 'read' && (
          <Stack
            sx={InfoSectionHeaderActionSx}
            onClick={handleOnViewChange('write')}
            data-testid={`${dataTestId}-edit-button`}
          >
            Edit
          </Stack>
        )}
        {view === 'write' && (
          <Stack
            sx={InfoSectionHeaderActionSx}
            onClick={handleOnViewChange('read')}
            data-testid={`${dataTestId}-cancel-button`}
          >
            Cancel
          </Stack>
        )}
      </Stack>
      <Stack sx={ContentContainerSx} data-testid={`${dataTestId}-content`}>
        {view === 'write' ? <InfoSectionSubHeader /> : null}
        {view === 'read' ? (
          <FormView form={form} data-testid={`${dataTestId}-form-view`} />
        ) : (
          <FormInputs form={form} data-testid={`${dataTestId}-form-inputs`} />
        )}
        <Stack direction="row" spacing={2}></Stack>
        <Divider sx={DividerSx} />
      </Stack>
    </Stack>
  );
};

export default JamfCredentialsForm;
