import { gql } from '@apollo/client';

export const UPDATE_RECIPIENT_ADDRESS = gql`
  mutation updateAddress(
    $id: ID!
    $streetAddress1: String
    $streetAddress2: String
    $city: String
    $principalRegion: String
    $state: String
    $zipCode: String
    $country: String
  ) {
    updateAddress(
      addressUpdateInput: {
        id: $id
        streetAddress1: $streetAddress1
        streetAddress2: $streetAddress2
        city: $city
        principalRegion: $principalRegion
        state: $state
        zipCode: $zipCode
        country: $country
      }
    ) {
      id
      streetAddress1
      streetAddress2
      city
      principalRegion
      state
      zipCode
      country
    }
  }
`;

export const UPDATE_RECIPIENT_INFO = gql`
  mutation updateRecipient(
    $id: ID!
    $email: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $addressId: ID
  ) {
    updateRecipient(
      updateRecipientInput: {
        id: $id
        email: $email
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        addressId: $addressId
      }
    ) {
      id
      email
      firstName
      lastName
      phoneNumber
      addressId
    }
  }
`;

export const CREATE_RECIPIENT = gql`
  mutation createRecipient(
    $addressId: ID
    $collaboratorId: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $organizationId: String!
    $phoneNumber: String
  ) {
    createRecipient(
      recipientCreateInput: {
        addressId: $addressId
        collaboratorId: $collaboratorId
        email: $email
        firstName: $firstName
        lastName: $lastName
        organizationId: $organizationId
        phoneNumber: $phoneNumber
      }
    ) {
      id
      firstName
      lastName
      email
      addressId
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrder(
    $id: ID!
    $internalNotes: String
    $orderManagerId: ID
    $status: OrderStatusEnum
    $recipientId: ID
    $dropRecipientId: ID
    $depotId: ID
  ) {
    updateOrder(
      orderUpdateInput: {
        id: $id
        internalNotes: $internalNotes
        orderManagerId: $orderManagerId
        status: $status
        recipientId: $recipientId
        dropRecipientId: $dropRecipientId
        depotId: $depotId
      }
    ) {
      id
      recipient {
        id
        firstName
        lastName
        email
      }
      dropRecipient {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation createAddress(
    $streetAddress1: String
    $streetAddress2: String
    $city: String!
    $state: StateEnum
    $principalRegion: String
    $zipCode: String
    $phoneNumber: String
    $country: CountryEnum!
    $organizationId: String!
  ) {
    createAddress(
      createAddressInput: {
        streetAddress1: $streetAddress1
        streetAddress2: $streetAddress2
        city: $city
        state: $state
        principalRegion: $principalRegion
        zipCode: $zipCode
        phoneNumber: $phoneNumber
        country: $country
        organizationId: $organizationId
      }
    ) {
      id
      streetAddress1
      streetAddress2
      city
      state
      principalRegion
      zipCode
      phoneNumber
      country
      organizationId
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress(
    $id: ID!
    $streetAddress1: String
    $streetAddress2: String
    $city: String!
    $state: StateEnum
    $principalRegion: String
    $zipCode: String
    $country: CountryEnum!
  ) {
    updateAddress(
      addressUpdateInput: {
        id: $id
        streetAddress1: $streetAddress1
        streetAddress2: $streetAddress2
        city: $city
        state: $state
        principalRegion: $principalRegion
        zipCode: $zipCode
        country: $country
      }
    ) {
      id
      streetAddress1
      streetAddress2
      city
      state
      principalRegion
      zipCode
      country
      organizationId
    }
  }
`;
