import { SxProps } from '@mui/material';

export const TitleIconSx = {
  width: '30px',
  height: '30px',
  position: 'relative',
  top: '3px',
};

export const ProcessBoxSx: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '24px',
};

export const ProcessSectionSx: SxProps = {
  '& .process-many': {
    position: 'relative',
    '&.has-some': {
      '& .MuiAutocomplete-endAdornment': {
        right: '46px !important',
      },
      '& .MuiOutlinedInput-root': {
        paddingRight: '72px !important',
      },
    },
    '& .process-count': {
      position: 'absolute',
      right: '12px',
      top: '16px',
      color: '#60605D',
    },
  },
};
export const PinBoxSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '24px',
  '& .MuiFormControl-root': {
    background: 'transparent',
    padding: 0,
    '& fieldset': { borderColor: '#131414 !important' },
    '&:hover fieldset': { borderColor: '#131414 !important' },
    '&.Mui-focused fieldset': { borderColor: '#131414 !important' },

    '& .MuiInputLabel-formControl': {
      color: '#131414 !important',
    },
    '& .MuiOutlinedInput-notchedOutline legend span': {
      color: '#131414 !important',
    },
  },
};

export const DeviceInfoBoxSx: SxProps = {
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
};

export const CartBoxSx: SxProps = {
  width: '324px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
};

export const CheckoutBoxSx: SxProps = {
  display: 'flex',
  padding: '16px 24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  borderRadius: '10px',
  border: '1px solid var(--Light-Other-Divider, rgba(19, 20, 20, 0.12))',
  '& .cart-divider': {
    display: 'block',
    height: '1px',
    width: '100%',
    background: 'rgba(19, 20, 20, 0.12)',
    margin: '8px 0',
  },
  '& .cart-item': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& p': {
      flex: 1,
      whiteSpace: 'nowrap',
    },
    '& p:last-child': {
      textAlign: 'right',
    },
  },
};

export const PaperPropsSx: SxProps = {
  height: '100%',
  maxHeight: {
    xs: '100vh',
    sm: '100vh',
    md: '800px',
    lg: '800px',
    xl: '800px',
  },
  maxWidth: '1200px',
};

export const OuterStackPropsSx: SxProps = {};
