import { MergeConnectionState } from './types';
import { CategoryEnum } from 'api/rest/integrations/merge/connection/types';

export const INITIAL_STATE: (
  overrides?: Partial<MergeConnectionState>
) => MergeConnectionState = (overrides = {}) => ({
  category: CategoryEnum.Hris,
  integration: '',
  status: 'INCOMPLETE',
  linkToken: '',
  magicLinkUrl: '',
  id: '',
  integrationSlug: '',
  endUserOriginId: '',
  endUserOrganizationName: '',
  endUserEmailAddress: '',
  webhookListenerUrl: '',
  accountType: '',
  getConnectionLoading: 'idle',
  getConnectionErrorMessage: undefined,
  postCreateLinkTokenLoading: 'idle',
  postCreateLinkTokenErrorMessage: undefined,
  postSwapAccountTokenLoading: 'idle',
  postSwapAccountTokenErrorMessage: undefined,
  deleteConnectionLoading: 'idle',
  deleteConnectionErrorMessage: undefined,
  ...overrides,
});
