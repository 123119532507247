import React from 'react';
import capitalize from 'lodash/capitalize';
import Stack from '@mui/material/Stack';
import { getBreadcrumbLinks } from './utils';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useGoToLink } from './hooks';

import { Props } from './types';
import { UUID_REGEX } from './constants';
import { ContainerSx, LinkSx, ActivePageSx, IconSx } from './styles';

const Breadcrumbs = (props: Props) => {
  const { pathname, ignore, 'data-testid': dataTestId } = props;
  const links = getBreadcrumbLinks(pathname);
  const pages = pathname
    .split('/')
    .filter(Boolean)
    .filter((link) => !link.match(UUID_REGEX));
  const activePage = links.indexOf(pathname);

  const handleGoToLink = useGoToLink();

  if (links.length === 1) {
    return null;
  }

  return (
    <Stack
      sx={ContainerSx}
      direction="row"
      spacing={1}
      data-testid={dataTestId}
    >
      {pages.map((breadcrumb, index) => {
        const linkStyles = {
          ...LinkSx,
          ...(activePage === index ? ActivePageSx : {}),
        };
        return (
          <Stack
            key={index}
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            data-testid={`${dataTestId}-item-${index}`}
          >
            {index < breadcrumb.length - 1 ? (
              <>
                {'   '}
                <ArrowForwardIosIcon
                  sx={IconSx}
                  data-testid={`${dataTestId}-arrow-${index}`}
                />
                {'   '}
              </>
            ) : (
              ''
            )}
            <Stack
              sx={linkStyles}
              onClick={handleGoToLink(links[index], ignore)}
              data-testid={`${dataTestId}-link-${index}`}
            >
              {capitalize(breadcrumb)}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default Breadcrumbs;
