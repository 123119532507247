import React from 'react';
import Stack from '@mui/material/Stack';

import {
  ContainerSx,
  FormInputFieldContainerSx,
  FormInputFieldLabelSx,
  FormInputFieldValueSx,
} from './styles';

import { Props } from './types';

const FormView: React.FC<Props> = (props) => {
  const { form, 'data-testid': dataTestId } = props;
  return (
    <Stack spacing={1} sx={ContainerSx} data-testid={dataTestId}>
      <Stack
        sx={FormInputFieldContainerSx}
        data-testid={`${dataTestId}-client-id-field`}
      >
        <Stack
          sx={FormInputFieldLabelSx}
          data-testid={`${dataTestId}-client-id-label`}
        >
          Jamf Client ID:
        </Stack>
        <Stack
          sx={FormInputFieldValueSx}
          data-testid={`${dataTestId}-client-id-value`}
        >
          {form.watch('clientId')}
        </Stack>
      </Stack>
      <Stack
        sx={FormInputFieldContainerSx}
        data-testid={`${dataTestId}-client-secret-field`}
      >
        <Stack
          sx={FormInputFieldLabelSx}
          data-testid={`${dataTestId}-client-secret-label`}
        >
          Jamf Client Secret:
        </Stack>
        <Stack
          sx={FormInputFieldValueSx}
          data-testid={`${dataTestId}-client-secret-value`}
        >
          {form.watch('clientSecret')}
        </Stack>
      </Stack>
      <Stack
        sx={FormInputFieldContainerSx}
        data-testid={`${dataTestId}-subdomain-field`}
      >
        <Stack
          sx={FormInputFieldLabelSx}
          data-testid={`${dataTestId}-subdomain-label`}
        >
          Jamf Subdomain:
        </Stack>
        <Stack
          sx={FormInputFieldValueSx}
          data-testid={`${dataTestId}-subdomain-value`}
        >
          {form.watch('subdomain')}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FormView;
