import React from 'react';
import { useNavigate } from 'react-router-dom';

export const useHandleOnClick = (path?: string) => {
  const navigate = useNavigate();
  return React.useCallback(() => {
    if (path?.match(/https?:\/\//)) {
      window.open(path, '_blank');
    } else if (path) {
      navigate(path);
    }
  }, [navigate, path]);
};
