export const obfuscateKey = (key: string): string => {
  if (key?.length <= 2) return '**';
  let amount = -2;
  if (key?.length > 10) amount = -5;
  if (key?.length > 40) amount = -6;
  const core = key.slice(0, amount);
  const lastFour = key.slice(amount);
  const obfuscatedCore = core.replace(/[^-]/g, '*');
  return obfuscatedCore + lastFour;
};
