import { OM_COLUMNS_KEYS } from 'store/slices/order_management/columns/constants';
import { OrderManagementColumnKey } from 'store/slices/order_management/columns/types';

export const COLUMNS_DEFAULT_VISIBILITY: { [index: string]: boolean } = {
  ...Object.fromEntries(
    Object.values(OM_COLUMNS_KEYS).map((key: OrderManagementColumnKey) => [
      key,
      true,
    ])
  ),
};

export const ORGANIZATION_EXCLUSIONS = ['ACME', 'Flying River (Demo)'];
