import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import Stack from '@mui/material/Stack';
import BlockIcon from '@mui/icons-material/Block';

import { useHandleOnClick } from './hooks';

import {
  ContainerSx,
  ImageContainerSx,
  TitleContainerSx,
  DescriptionContainerSx,
  ButtonContainerSx,
  LeftSx,
  CenterSx,
  RightSx,
  ButtonSx,
  VerifiedContainerSx,
  TitleSx,
} from './styles';

import { Props } from './types';

const IntegrationItem: React.FC<Props> = ({
  integration,
  'data-testid': dataTestId,
}) => {
  let path;
  if (integration.copy === 'Request Access') {
    path = integration.requestAccessPath;
  } else if (integration.copy === 'Manage') {
    path = integration.settingsPath;
  } else {
    path = integration.connectPath;
  }
  const handleOnClick = useHandleOnClick(path);
  return (
    <Stack sx={ContainerSx} data-testid={dataTestId}>
      <Stack sx={LeftSx} data-testid={`${dataTestId}-left`}>
        <Stack
          sx={ImageContainerSx}
          data-testid={`${dataTestId}-image-container`}
        >
          <img
            src={integration.image}
            alt={integration.name}
            data-testid={`${dataTestId}-image`}
          />
        </Stack>
      </Stack>
      <Stack sx={CenterSx} data-testid={`${dataTestId}-center`}>
        <Stack
          sx={TitleContainerSx}
          data-testid={`${dataTestId}-title-container`}
        >
          <Stack sx={TitleSx} data-testid={`${dataTestId}-title`}>
            {integration.name}{' '}
          </Stack>
          {integration.connected && (
            <Stack
              sx={VerifiedContainerSx}
              data-testid={`${dataTestId}-verified-container`}
            >
              {' '}
              <VerifiedIcon data-testid={`${dataTestId}-verified-icon`} />{' '}
              Connected
            </Stack>
          )}
        </Stack>
        <Stack
          sx={DescriptionContainerSx}
          data-testid={`${dataTestId}-description-container`}
        >
          <div
            data-testid={`${dataTestId}-description`}
            dangerouslySetInnerHTML={{ __html: integration.description }}
          />
        </Stack>
      </Stack>
      {integration.copy ? (
        <Stack sx={RightSx} data-testid={`${dataTestId}-right`}>
          <Stack
            sx={ButtonContainerSx}
            data-testid={`${dataTestId}-button-container`}
          >
            <LoadingButton
              sx={ButtonSx}
              variant="contained"
              color="primary"
              disabled={integration.disabled}
              onClick={handleOnClick}
              loading={integration.loading}
              data-testid={`${dataTestId}-action-button`}
            >
              {integration.disabled ? <BlockIcon /> : integration.copy}
            </LoadingButton>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};

export default IntegrationItem;
