import React from 'react';
import { GET_SHIPPING_TYPE_OPTIONS_BY_ORDER_TYPE } from 'graphql/queries';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { OrderType as OrderTypeNames } from 'global-constants';
import { flattenShippingTypeOption } from 'services/misc';
import { getNDaysFromNow } from 'services/date';
import { FlatShippingInfo } from 'types';
import { Warning } from 'enums/warnings';

const usePremiumShippingPerOrderType = (
  orderTypeName: OrderTypeNames,
  options: {
    debugConsole?: boolean;
    skip?: boolean;
  } = {
    debugConsole: false,
    skip: false,
  }
) => {
  const [shippingInfoLookup, setShippingInfoLookup] = React.useState<
    Record<string, any>
  >({});

  const [availableShippingTypeNames, setAvailableShippingTypeNames] =
    React.useState<string[]>([]);

  const [hasShippingInfo, setHasShippingInfo] = React.useState<boolean>(false);
  const tempAvailableShippingTypeNames: string[] = [];

  const { loading: loadingPremiumShippingInfo } = useSafeQuery(
    GET_SHIPPING_TYPE_OPTIONS_BY_ORDER_TYPE,
    {
      skip: options.skip,
      nextFetchPolicy: 'network-only',
      variables: {
        orderTypeName,
      },
      onCompleted(data) {
        const shippingTypeOptions =
          data?.searchOrderTypes?.orderTypes?.[0]?.shippingTypeOptions ?? [];

        const flatShippingOptions = shippingTypeOptions.map(
          flattenShippingTypeOption
        );

        flatShippingOptions.sort(
          (a: FlatShippingInfo, b: FlatShippingInfo) => a.order - b.order
        );

        if (options.debugConsole) {
          console.log('shippingTypeOptions: \n', shippingTypeOptions);
          console.log('flatShippingOptions: \n', flatShippingOptions);
          console.dirxml(options);
          console.info(
            `\n\n${Warning.HARD_CODED_EXCEPTION_WARNING}:\n\nStandard Shipping gets an additional day added to incoming day value for shipping due to SLA\n\n`
          );
        }

        const tempShippingInfoLookup = flatShippingOptions.reduce(
          (acc: Record<string, any>, nextOption: FlatShippingInfo) => {
            const { days } = nextOption;

            const formattedDate = getNDaysFromNow(days).split(' ').join(', ');

            acc[nextOption.shippingTypeName] = { ...nextOption, formattedDate };
            tempAvailableShippingTypeNames.push(nextOption.shippingTypeName);
            acc[nextOption?.shippingTypeId ?? ''] = {
              ...nextOption,
              formattedDate,
            };
            return acc;
          },
          {}
        );

        setShippingInfoLookup(tempShippingInfoLookup);
        setAvailableShippingTypeNames(tempAvailableShippingTypeNames);
        setHasShippingInfo(Object.keys(tempShippingInfoLookup).length > 0);
      },
    }
  );

  return {
    shippingInfoTable: shippingInfoLookup,
    hasShippingInfo,
    loadingPremiumShippingInfo,
    availableShippingTypeNames,
  };
};

export default usePremiumShippingPerOrderType;
