import { RootState } from 'store/index';

export const selectMergeConnection = (state: RootState) => {
  return state.integrations.merge.connect;
};

export const selectMergeLinkToken = (state: RootState) => {
  return state.integrations.merge.connect.linkToken;
};

export const selectMergeMagicLinkUrl = (state: RootState) => {
  return state.integrations.merge.connect.magicLinkUrl;
};

export const selectMergeConnectionPostCreateLinkTokenErrorMessage = (
  state: RootState
) => {
  return state.integrations.merge.connect.postCreateLinkTokenErrorMessage;
};

export const selectMergeConnectionPostSwapAccountTokenLoading = (
  state: RootState
) => {
  return state.integrations.merge.connect.postSwapAccountTokenLoading;
};

export const selectMergeConnectionGetErrorMessage = (state: RootState) => {
  return state.integrations.merge.connect.getConnectionErrorMessage;
};

export const selectMergeConnectionPostSwapAccountTokenErrorMessage = (
  state: RootState
) => {
  return state.integrations.merge.connect.postSwapAccountTokenErrorMessage;
};
