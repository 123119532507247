import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

export const procurementPreference = (approval: boolean) => {
  const text = approval ? 'Approved' : 'Not Approved';

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {approval ? (
        <CheckCircleOutline sx={{ color: 'colors.goldFusion' }} />
      ) : (
        <ErrorOutline sx={{ color: 'colors.burntSiennaR' }} />
      )}
      <Typography variant="body1">{text}</Typography>
    </Stack>
  );
};
