import { createSlice } from '@reduxjs/toolkit';

import { INITIAL_STATE } from './constants';
import { setDeleteIntegrationModalOpenReducer } from './reducers';

export const mergeSettingsSlice = createSlice({
  name: 'mergeSettingsSlice',
  initialState: INITIAL_STATE(),
  reducers: {
    resetState: () => INITIAL_STATE(),
    setDeleteIntegrationModalOpen: setDeleteIntegrationModalOpenReducer,
  },
});

const { resetState, setDeleteIntegrationModalOpen } =
  mergeSettingsSlice.actions;

export { resetState, setDeleteIntegrationModalOpen };
