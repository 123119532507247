import { RootState } from 'store/index';

export const selectJamfConnection = (state: RootState) => {
  return state.integrations.jamf.connect;
};

export const selectJamfConnectionPostErrorMessage = (state: RootState) => {
  return state.integrations.jamf.connect.postErrorMessage;
};

export const selectJamfConnectionGetErrorMessage = (state: RootState) => {
  return state.integrations.jamf.connect.getErrorMessage;
};

export const selectJamfConnectionPutErrorMessage = (state: RootState) => {
  return state.integrations.jamf.connect.putErrorMessage;
};
