import { createAsyncThunk } from '@reduxjs/toolkit';
import * as mergeConnectionAPI from 'api/rest/integrations/merge/connection';
import {
  PostMergeCreateLinkTokenBody,
  PostMergeCreateLinkTokenResponse,
  PostMergeSwapAccountTokenBody,
  PostMergeSwapAccountTokenResponse,
  GetMergeConnectionResponse,
} from 'api/rest/integrations/merge/connection/types';

export const getMergeConnection = createAsyncThunk<
  GetMergeConnectionResponse,
  { orgId: string }
>('integrations/hris/getMergeConnection', async (params: { orgId: string }) => {
  const { orgId } = params;
  return mergeConnectionAPI.getMergeConnection(orgId);
});

export const postMergeCreateLinkToken = createAsyncThunk<
  PostMergeCreateLinkTokenResponse,
  { orgId: string; data: PostMergeCreateLinkTokenBody }
>(
  'integrations/hris/postMergeCreateLinkToken',
  async (params: { orgId: string; data: PostMergeCreateLinkTokenBody }) => {
    const { orgId, data } = params;
    return mergeConnectionAPI.postMergeCreateLinkToken(orgId, data);
  }
);

export const postMergeSwapAccountToken = createAsyncThunk<
  PostMergeSwapAccountTokenResponse,
  { orgId: string; data: PostMergeSwapAccountTokenBody }
>(
  'integrations/hris/postMergeSwapAccountToken',
  async (params: { orgId: string; data: PostMergeSwapAccountTokenBody }) => {
    const { orgId, data } = params;
    return mergeConnectionAPI.postMergeSwapAccountToken(orgId, data);
  }
);

export const deleteMergeConnection = createAsyncThunk<void, { orgId: string }>(
  'integrations/hris/deleteMergeConnection',
  async (params: { orgId: string }) => {
    const { orgId } = params;
    return mergeConnectionAPI.deleteMergeConnection(orgId);
  }
);
