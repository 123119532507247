import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/immer';

import { MergeSettingsState } from './types';
import { INITIAL_STATE } from './constants';

export const resetStateReducer = (
  state: WritableDraft<MergeSettingsState>,
  action: PayloadAction<void>
) => {
  state = INITIAL_STATE();
};

export const setDeleteIntegrationModalOpenReducer = (
  state: WritableDraft<MergeSettingsState>,
  action: PayloadAction<boolean>
) => {
  state.deleteIntegrationModalOpen = action.payload;
};
