const diff = '40px';

export const ContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  width: '60%',
  pb: diff,
  position: 'relative',
};

export const InfoSectionHeaderSx = {
  fontSize: '16px',
  fontWeight: 600,
  color: '#000',
  width: 'calc(100% - 10px)',
  display: 'flex',
  flexDirection: 'row',
};
export const InfoSectionHeaderTextSx = {
  fontSize: '16px',
  fontWeight: 600,
  color: '#000',
  flex: 1,
  display: 'flex',
  width: '50%',
};

export const InfoSectionHeaderActionSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  width: '50%',
  fontSize: '14px',
  fontWeight: 600,
  color: '#666',
  cursor: 'pointer',
  '&:hover': {
    color: '#000',
    textDecoration: 'underline',
  },
};

export const DividerSx = {
  pt: diff,
  mt: '10px',
  mb: '10px',
  width: 'calc(100% - 12px)',
  position: 'absolute',
  bottom: '0',
};

export const ContentContainerSx = {
  width: '100%',
  height: '100%',
};
