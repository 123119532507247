import { JamfConnectionState } from './types';

export const INITIAL_STATE: () => JamfConnectionState = () => {
  return {
    clientId: '',
    clientSecret: '',
    subdomain: '',
    getLoading: 'idle',
    getErrorMessage: undefined,
    postLoading: 'idle',
    postErrorMessage: undefined,
    putLoading: 'idle',
    putErrorMessage: undefined,
  };
};
