import { gql } from '@apollo/client';

export const GET_ORDER_ITEMS_BY_ORDER_ID = gql`
  query searchOrderItems($orderId: ID!) {
    searchOrderItems(searchOrderItemsInput: { orderId: $orderId }) {
      orderItems {
        id
        source
        serialNumber
        inboundOrderItemId
        shipmentStatus
        tracking {
          trackingNumber
          trackingLink
        }
        asset {
          assetNumber
        }
        productVariant {
          product {
            name
          }
        }
      }
    }
  }
`;

export const SEARCH_ORDERS_V2 = gql`
  query getPathOrders(
    $where: V2OrderWhereInput
    $orderBy: [V2OrderOrderByWithRelationInput!]
    $cursor: V2OrderWhereUniqueInput
    $take: Int
    $skip: Int
  ) {
    v2FindAndCountOrders(
      where: $where
      orderBy: $orderBy
      cursor: $cursor
      take: $take
      skip: $skip
    ) {
      orders {
        id
        organizationId
        rush
        orderNumber
        dueDate
        orderType {
          id
          name
        }
        recipient {
          id
          firstName
          lastName
          email
        }
        createdAt
        status
        finalDestination
        externalLinks
        shipments {
          status
          substatus
          substatusDescription
          shipmentItems {
            orderItem {
              id
              inboundOrderItemId
              serialNumber
              asset {
                assetNumber
              }
              productVariant {
                product {
                  name
                }
              }
            }
          }
          trackings {
            id
            trackingNumber
            trackingLink
          }
        }
        orderItems {
          id
          inboundOrderItemId
          serialNumber
          asset {
            assetNumber
          }
          productVariant {
            product {
              name
            }
          }
        }
        lastOrderStatusTransition {
          id
          orderId
          from
          to
          createdAt
        }
      }
      count
    }
  }
`;

export const GET_RETRIEVAL_ORDER_TYPES = gql`
  query {
    searchOrderTypes(searchOrderTypesInput: { name: "Retrieval" }) {
      orderTypes {
        id
        name
      }
    }
  }
`;
