import { useDispatch } from 'react-redux';
import { setView } from 'store/slices/integrations/jamf/settings';
import { UseFormReturn } from 'react-hook-form';
import { JamfCredentialsFormFields } from './types';
import { JamfConnectionState } from 'store/slices/integrations/jamf/connect/types';

export const useOnHandleViewChange = (
  form: UseFormReturn<JamfCredentialsFormFields>,
  connection: JamfConnectionState
) => {
  const dispatch = useDispatch();
  return (view: 'read' | 'write') => {
    return () => {
      // On cancel, set the form values to the original connection values
      if (view === 'read') {
        form.setValue('clientId', connection.clientId);
        form.setValue('clientSecret', connection.clientSecret);
        form.setValue('subdomain', connection.subdomain);
      }
      dispatch(setView(view));
    };
  };
};
