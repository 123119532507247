import { createSlice } from '@reduxjs/toolkit';

import {
  setIntegrationsValueReducer,
  setIntegrationsReducer,
} from './reducers';

import { INITIAL_STATE } from './constants';

export const integrationsListSlice = createSlice({
  name: 'integrationsListSlice',
  initialState: INITIAL_STATE(),
  reducers: {
    setIntegrationsValueReducer,
    setIntegrationsReducer,
    resetState: () => INITIAL_STATE(),
  },
});

export const {
  setIntegrationsValueReducer: setIntegrationsValue,
  setIntegrationsReducer: setIntegrations,
  resetState,
} = integrationsListSlice.actions;
