import { DRAWER_WIDTH } from 'global-styles';

const PADDING = '100px';
const HEADER_HEIGHT = '80px';

export const ContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'calc(100vh - 61px)',
  overflowY: 'auto',
  pl: PADDING,
  pr: PADDING,
  position: 'relative',
  fontSize: '14px',
  top: '61px',
  '@media (min-width: 1440px)': {
    left: `${DRAWER_WIDTH}px`,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
};

export const HeaderContainerSx = {
  mt: '50px',
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: HEADER_HEIGHT,
  width: '100%',
};

export const ContentContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  flexShrink: 0,
};

export const FooterContainerSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexGrow: 1,
  pb: PADDING,
  width: '100%',
};

export const BreadcrumbsContainerSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '20px',
};

export const TitleContainerSx = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '34px',
  width: '100%',
  flexDirection: 'row',
};

export const FormContainerSx = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 'calc(100vh - 140px)',
};

export const PrimaryButtonSx = {
  padding: '8px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  marginRight: '5px',
};

export const SecondaryButtonSx = {
  padding: '8px 20px',
  fontSize: '14px',
  fontWeight: 'bold',
  marginRight: '5px',
  backgroundColor: 'lightgray',
  color: 'black',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const VerifiedContainerSx = {
  display: 'flex',
  position: 'relative',
  top: '3px',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: '#7A8450',
  fontWeight: 'bold',
  height: '100%',
  pl: '10px',
  fontSize: '14px',
  '& svg': {
    fontSize: '24px',
  },
};
