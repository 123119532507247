import { gql } from '@apollo/client';

export const UPDATE_INVENTORY_RECEIPT = gql`
  mutation updateInventoryReceipt(
    $id: ID!
    $checkedInAt: DateTime
    $depotId: ID
    $outboundOrderItemNumber: String
    $lastTouchedAt: DateTime
    $checkedOutAt: DateTime
    $identifier: String
    $productStatus: ProductStatus
  ) {
    updateInventoryReceipt(
      inventoryReceiptUpdateInput: {
        id: $id
        checkedInAt: $checkedInAt
        depotId: $depotId
        outboundOrderItemNumber: $outboundOrderItemNumber
        lastTouchedAt: $lastTouchedAt
        checkedOutAt: $checkedOutAt
        identifier: $identifier
        productStatus: $productStatus
      }
    ) {
      id
      assetId
      checkedInAt
      checkedOutAt
      depotId
      inboundOrderItem {
        id
        orderItemNumber
      }
      outboundOrderItem {
        id
        orderItemNumber
      }
      depot {
        id
        name
      }
      identifier
      lastTouchedAt
      productStatus
    }
  }
`;

export const CREATE_INVENTORY_RECEIPT = gql`
  mutation createInventoryReceipt(
    $assetId: ID!
    $checkedInAt: DateTime!
    $depotId: ID!
    $inboundOrderItemNumber: String!
    $outboundOrderItemNumber: String
    $lastTouchedAt: DateTime
    $checkedOutAt: DateTime
    $identifier: String
    $organizationId: String!
    $productStatus: ProductStatus
  ) {
    createInventoryReceipt(
      inventoryReceiptCreateInput: {
        assetId: $assetId
        checkedInAt: $checkedInAt
        depotId: $depotId
        inboundOrderItemNumber: $inboundOrderItemNumber
        outboundOrderItemNumber: $outboundOrderItemNumber
        lastTouchedAt: $lastTouchedAt
        checkedOutAt: $checkedOutAt
        identifier: $identifier
        organizationId: $organizationId
        productStatus: $productStatus
      }
    ) {
      id
      assetId
      checkedInAt
      checkedOutAt
      depotId
      inboundOrderItem {
        id
        orderItemNumber
      }
      outboundOrderItem {
        id
        orderItemNumber
      }
      depot {
        id
        name
      }
      identifier
      lastTouchedAt
      productStatus
    }
  }
`;
