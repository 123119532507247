import { gql } from '@apollo/client';

export const OLD_ADMIN_GET_ASSETS = gql`
  query adminSearchAssets(
    $orderAsc: String
    $orderDesc: String
    $limit: Int = 25
    $offset: String
    $organizationId: ID
    $assetNumber: String
    $color: AssetColorsEnum
    $assigneeEmail: String
    $depotName: String
    $displaySize: String
    $keyboard: String
    $cosmeticCondition: AssetCosmeticCondition
    $make: String
    $memory: String
    $model: String
    $processor: String
    $serialNumber: String
    $status: AssetStatusEnum
    $deviceAssessment: String
    $storage: String
    $assetTypeName: String
    $assetUUID: String
  ) {
    adminSearchAssets(
      assetsAdminSearchInput: {
        orderAsc: $orderAsc
        orderDesc: $orderDesc
        limit: $limit
        offset: $offset
        organizationId: $organizationId
        assetNumber: $assetNumber
        color: $color
        assigneeEmail: $assigneeEmail
        depotName: $depotName
        displaySize: $displaySize
        keyboard: $keyboard
        cosmeticCondition: $cosmeticCondition
        make: $make
        memory: $memory
        model: $model
        processor: $processor
        serialNumber: $serialNumber
        status: $status
        deviceAssessment: $deviceAssessment
        storage: $storage
        assetTypeName: $assetTypeName
        assetUUID: $assetUUID
      }
    ) {
      count
      assets {
        id
        organization {
          name
        }
        thirdPartyAssetId
        serialNumber
        make
        model
        modelNumber
        dataStatus
        assetNumber
        status
        displaySize
        lastOrderItemNumber
        deviceAssessment
        memory
        storage
        assetTypeId
        color
        cosmeticCondition
        dataStatus
        keyboard
        processor
        technicalFunctionality
        # fields/values below EXCEPT for purchaseDate are NOT used in the table
        createdAt
        warrantyExpiration
        purchaseDate
        isWarrantyExpirationVerified
        isPurchaseDateVerified
        depot {
          id
          name
          addressId
        }
        assignee {
          id
          organizationId
          firstName
          lastName
          email
        }
        assetType {
          id
          name
        }
        inventoryLog {
          dateOfLastCheckIn
          dateOfLastCheckOut
        }
      }
    }
  }
`;

export const GET_COMPLEX_ASSET_SEARCH = gql`
  query getComplexAssetSearch(
    $organizationId: ID
    $orderAsc: String
    $orderDesc: String
    $limit: Int = 25
    $offset: Int = 0
    $searchEntries: [BaseComplexSearchFieldEntry!]! = []
  ) {
    getComplexAssetSearch(
      complexAssetSearchInput: {
        orderAsc: $orderAsc
        orderDesc: $orderDesc
        limit: $limit
        offset: $offset
        searchEntries: $searchEntries
        organizationId: $organizationId
      }
    ) {
      count
      assets {
        id
        organization {
          id
          name
        }
        thirdPartyAssetId
        serialNumber
        make
        model
        modelNumber
        dataStatus
        assetNumber
        status
        displaySize
        lastOrderItemNumber
        deviceAssessment
        memory
        storage
        assetTypeId
        color
        cosmeticCondition
        dataStatus
        keyboard
        processor
        technicalFunctionality
        # fields/values below EXCEPT for purchaseDate are NOT used in the table
        createdAt
        warrantyExpiration
        purchaseDate
        isWarrantyExpirationVerified
        isPurchaseDateVerified
        depot {
          id
          name
          addressId
        }
        assignee {
          id
          organizationId
          firstName
          lastName
          email
        }
        assetType {
          id
          name
        }
        inventoryLog {
          dateOfLastCheckIn
          dateOfLastCheckOut
        }
      }
    }
  }
`;
