import { WritableDraft } from 'immer/dist/immer';
import { JamfConnectionState } from './types';
import {
  getJamfConnection,
  postJamfConnection,
  putJamfConnection,
} from './actions';

export const getJamfConnectionPendingReducer = (
  state: WritableDraft<JamfConnectionState>,
  action: ReturnType<typeof getJamfConnection.pending>
) => {
  state.getLoading = 'pending';
  state.getErrorMessage = undefined;
};

export const getJamfConnectionFulfilledReducer = (
  state: WritableDraft<JamfConnectionState>,
  action: ReturnType<typeof getJamfConnection.fulfilled>
) => {
  state.getLoading = 'fulfilled';
  state.getErrorMessage = undefined;
  state.clientId = action.payload.clientId;
  state.clientSecret = action.payload.clientSecret;
  state.subdomain = action.payload.subdomain;
};

export const getJamfConnectionRejectedReducer = (
  state: WritableDraft<JamfConnectionState>,
  action: ReturnType<typeof getJamfConnection.rejected>
) => {
  state.getLoading = 'rejected';
  state.getErrorMessage = action.error.message;
  state.clientId = '';
  state.clientSecret = '';
  state.subdomain = '';
};

export const postJamfConnectionPendingReducer = (
  state: WritableDraft<JamfConnectionState>,
  action: ReturnType<typeof postJamfConnection.pending>
) => {
  state.postLoading = 'pending';
  state.postErrorMessage = undefined;
};

export const postJamfConnectionFulfilledReducer = (
  state: WritableDraft<JamfConnectionState>,
  action: ReturnType<typeof postJamfConnection.fulfilled>
) => {
  state.postLoading = 'fulfilled';
  state.postErrorMessage = undefined;
  state.clientId = action.payload.clientId;
  state.clientSecret = action.payload.clientSecret;
  state.subdomain = action.payload.subdomain;
};

export const postJamfConnectionRejectedReducer = (
  state: WritableDraft<JamfConnectionState>,
  action: ReturnType<typeof postJamfConnection.rejected>
) => {
  state.postLoading = 'rejected';
  state.postErrorMessage = action.error.message;
  state.clientId = '';
  state.clientSecret = '';
  state.subdomain = '';
};

export const putJamfConnectionPendingReducer = (
  state: WritableDraft<JamfConnectionState>,
  action: ReturnType<typeof putJamfConnection.pending>
) => {
  state.putLoading = 'pending';
  state.putErrorMessage = undefined;
};

export const putJamfConnectionFulfilledReducer = (
  state: WritableDraft<JamfConnectionState>,
  action: ReturnType<typeof putJamfConnection.fulfilled>
) => {
  state.putLoading = 'fulfilled';
  state.putErrorMessage = undefined;
  state.clientId = action.payload.clientId;
  state.clientSecret = action.payload.clientSecret;
  state.subdomain = action.payload.subdomain;
};

export const putJamfConnectionRejectedReducer = (
  state: WritableDraft<JamfConnectionState>,
  action: ReturnType<typeof putJamfConnection.rejected>
) => {
  state.putLoading = 'rejected';
  state.putErrorMessage = action.error.message;
  state.clientId = '';
  state.clientSecret = '';
  state.subdomain = '';
};
